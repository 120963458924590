import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { TiDelete } from "react-icons/ti";
import axios from "axios";
import { toast } from "react-toastify";

function ProjectMemberComponentUpdate({
  repeat,
  index,
  handleRemoveProjectRow,
  onChangeProject,
  error,
  projectRow,
}) {
  const [projectDepartment, setProjectDepartment] = useState("");
  const [idValue, setIdValue] = useState("");
  const [employeeValue, setEmployeeValue] = useState("");

  //----------------department-API--------------------------
  const fetchDepartment = async () => {
    try {
      const token = localStorage.getItem("demo-token");
      const response = await axios.get(
        `${process.env.REACT_APP_SECRET_KEY}/master-data-department`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.status === 200) {
        setProjectDepartment(response.data.data.departments);
      } else {
        toast.error(response.data.message);
      }
    } catch (err) {
      console.log("form", err);
    }
  };

  //---------------Fetch-Employees-API--------------------------
  const fetchEmployee = async (departmentId) => {
    try {
      const token = localStorage.getItem("demo-token");
      const response = await axios.get(
        `${process.env.REACT_APP_SECRET_KEY}/project-employee/${departmentId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.status === 200) {
        const empArray = projectRow[index].employee
          ? projectRow.slice(index + 1).map((data) => data.employee)
          : projectRow.map((data) => data.employee);

        const finalArray = response.data.data.projectEmployee.filter((data) => {
          // if(response.data.data.projectEmployee.)
          return !empArray.includes(data._id);
        });
        setEmployeeValue(finalArray);
        // setEmployeeValue(response.data.data.projectEmployee);
      } else {
        toast.error(response.data.message);
      }
    } catch (err) {
      console.log("form", err);
    }
  };

  useEffect(() => {
    fetchDepartment();
    // fetchEmployee()
  }, []);

  // Call fetchEmployee whenever idValue changes
  useEffect(() => {
    if (idValue || repeat.departments) {
      fetchEmployee(idValue || repeat.departments);
    }
  }, [idValue, repeat]);

  return (
    <React.Fragment key={repeat.pId}>
      {index !== 0 && (
        <div className="milestonRemoveButton mt-2">
          <TiDelete
            className="fs-3 color"
            onClick={() => handleRemoveProjectRow(repeat.pId, repeat._id)}
          />
        </div>
      )}
      {/* <div className="Small-Wrapper mt-2"> */}
      <div className="">
        <form className="needs-validation mb-2">
          <div className="row">
            <div className="col-3">
              <select
                className={`inputArea custm-form-control ${
                  !repeat.departments && error.departments && "is-invalid"
                }`}
                name="departments"
                id="Department"
                onChange={(e) => {
                  onChangeProject(repeat.pId, e.target.name, e.target.value);
                  setIdValue(e.target.value); // Set idValue on dropdown change
                }}
                value={repeat?.departments || ""}
              >
                <option value="">Select Type</option>
                {projectDepartment &&
                  projectDepartment.map(({ _id, departmentName }, idindex) => {
                    return (
                      <React.Fragment key={idindex}>
                        <option value={_id}>{departmentName}</option>
                      </React.Fragment>
                    );
                  })}
              </select>
              <span className="invalid-feedback">{error.departments}</span>
            </div>
            <div className="col-3">
              <select
                className={`inputArea custm-form-control ${
                  !repeat.employee && error.employee && "is-invalid"
                }`}
                name="employee"
                id="Employee"
                onChange={(e) =>
                  onChangeProject(repeat.pId, e.target.name, e.target.value)
                }
                value={repeat?.employee || ""}
              >
                <option value="">Select Type</option>

                {employeeValue &&
                  employeeValue.map(({ _id, name }, idindex) => {
                    return (
                      <React.Fragment key={idindex}>
                        <option value={_id}>{name}</option>
                      </React.Fragment>
                    );
                  })}
              </select>
              <span className="invalid-feedback">{error.employee}</span>
            </div>
            <div className="col-2">
              <select
                className={`inputArea custm-form-control ${
                  !repeat.workAssignedFor &&
                  error.workAssignedFor &&
                  "is-invalid"
                }`}
                name="workAssignedFor"
                id="workAssigne"
                onChange={(e) =>
                  onChangeProject(repeat.pId, e.target.name, e.target.value)
                }
                value={repeat?.workAssignedFor || ""}
              >
                <option value="">Select Type</option>
                <option value="0">0 Hours</option>
                <option value="1">1 Hours</option>
                <option value="2">2 Hours</option>
                <option value="3">3 Hours</option>
                <option value="4">4 Hours</option>
                <option value="5">5 Hours</option>
                <option value="6">6 Hours</option>
                <option value="7">7 Hours</option>
                <option value="8">8 Hours</option>
              </select>
              <span className="invalid-feedback">{error.workAssignedFor}</span>
            </div>
            <div className="col-2">
              <DatePicker
                placeholderText="Start Date"
                className={`inputArea form-control ${
                  !repeat.startDate && error.startDate && "is-invalid"
                }`}
                name="startDate"
                id="pStartDate"
                selected={Date.parse(repeat.startDate)}
                value={repeat.startDate}
                showYearDropdown
                yearDropdownItemNumber={100}
                scrollableYearDropdown
                showMonthDropdown
                useShortMonthInDropdown
                onChange={(date) =>
                  onChangeProject(repeat.pId, "startDate", date)
                }
                dateFormat="yyyy-MM-dd"
                maxDate={new Date(repeat.endDate)}
              />
              {!repeat.startDate && error.startDate && (
                <p className="datePicker_validation_small">{error.startDate}</p>
              )}
            </div>
            <div className="col-2">
              <DatePicker
                placeholderText="End Date"
                className={`inputArea form-control ${
                  !repeat.endDate && error.endDate && "is-invalid"
                }`}
                name="endDate"
                id="pEndDate"
                selected={Date.parse(repeat.endDate)}
                value={repeat.endDate}
                showYearDropdown
                yearDropdownItemNumber={100}
                scrollableYearDropdown
                showMonthDropdown
                useShortMonthInDropdown
                onChange={(date) =>
                  onChangeProject(repeat.pId, "endDate", date)
                }
                dateFormat="yyyy-MM-dd"
                minDate={new Date(repeat.startDate)}
              />
              {!repeat.endDate && error.endDate && (
                <p className="datePicker_validation_small">{error.endDate}</p>
              )}
            </div>
          </div>
        </form>
      </div>
    </React.Fragment>
  );
}

export default ProjectMemberComponentUpdate;

import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { BiSolidAddToQueue } from "react-icons/bi";
import { TiDelete } from "react-icons/ti";

function AddPlateform({
  label,
  type,
  error,
  addPlateformValue,
  setAddPlateformValue,
  onChangeplateform,
}) {
  const [allOptions, setAllOptions] = useState([]);

  // console.log("type", type);
  // console.log("addPlateformValue in add", addPlateformValue);
  //--------------add-new-row-----------------------
  const handleAddTmRow = () => {
    const newRow = {
      id: Date.now(),
      type: type,
      technologies: "",
      name: "",
      gitUrl: "",
    };
    setAddPlateformValue([...addPlateformValue, newRow]);
  };

  //--------------rremove-row-Type----------------------
  const handleRemoveRow = (id) => {
    const updatedTMRow = addPlateformValue.filter((repeat) => {
      return repeat.id !== id;
    });
    setAddPlateformValue(updatedTMRow);
  };
  //----------------department-API--------------------------
  const fetchPlateform = async () => {
    try {
      const token = localStorage.getItem("demo-token");
      const response = await axios.get(
        `${process.env.REACT_APP_SECRET_KEY}/master-data-technology`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.status === 200) {
        setAllOptions(response.data.data);
      } else {
        toast.error(response.data.message);
      }
    } catch (err) {
      console.log("form", err);
    }
  };

  React.useEffect(() => {
    fetchPlateform();
  }, []);

  return (
    <>
      <div className="milestonButton cstm_margin_btn">
        <BiSolidAddToQueue className="fs-3 color" onClick={handleAddTmRow} />
      </div>

      <div className="row">
        <div className="col-3 mt-2">
          <label htmlFor={type} className="col-form-label">
            Technologie
          </label>
        </div>
        <div className="col-3  mt-2">
          <label htmlFor={label} className="col-form-label mx-2">
            Name
          </label>
        </div>
        <div className="col-3  mt-2 mx-2">
          <label htmlFor="gitUrl" className="col-form-label mx-2">
            url
          </label>
        </div>
      </div>

      {addPlateformValue.map((repeat, index) => {
        return (
          <React.Fragment key={index}>
            {index !== 0 && (
              <div className="milestonRemoveButton">
                <TiDelete
                  className="fs-3 color"
                  onClick={() => handleRemoveRow(repeat.id)}
                />
              </div>
            )}
            <div className="row">
              <div className="col-3 mb-2">
                <select
                  className="custm-form-control "
                  // className={`custm-form-control ${
                  //   !repeat?.technologies && error[`${type},${index},technologies`] && "is-invalid"
                  // }`}
                  name="technologies"
                  id={type}
                  value={repeat?.technologies?._id}
                  onChange={(e) => onChangeplateform(e, type, repeat.id)}
                >
                  <option value="">Select Technology</option>
                  {allOptions &&
                    allOptions.map(({ technologyName, _id }, idindex) => {
                      return (
                        <React.Fragment key={idindex}>
                          <option value={_id}>{technologyName}</option>
                        </React.Fragment>
                      );
                    })}
                </select>
                {/* <div className="invalid-feedback">
                  {error[`${type},${index},technologies`]}
                </div> */}
              </div>

              <div className="col-3  mb-2">
                <input
                  type="text"
                  className="form-control mx-2"
                  // className={`form-control mx-2 ${
                  //   !repeat?.name && error[`${type},${index},name`] && "is-invalid"}`}
                  placeholder="Enter your name here"
                  name="name"
                  id={label}
                  value={repeat?.name}
                  onChange={(e) => onChangeplateform(e, type, repeat.id)}
                />
                {/* <span className="invalid-feedback mx-2">
                    {error[`${type},${index},name`]}
                  </span> */}
              </div>

              <div className="col-3  mb-2 mx-2">
                <input
                  type="text"
                  className="form-control mx-2"
                  // className={`form-control mx-2 ${
                  //   !repeat.gitUrl && error[`${type},${index},gitUrl`] && "is-invalid"
                  // }`}
                  placeholder="Enter your url here"
                  name="gitUrl"
                  id="gitUrl"
                  value={repeat?.gitUrl}
                  onChange={(e) => onChangeplateform(e, type, repeat.id)}
                />
                {/* <span className="invalid-feedback mx-2">
                    {error[`${type},${index},gitUrl`]}
                  </span> */}
              </div>
            </div>
          </React.Fragment>
        );
      })}
    </>
  );
}

export default AddPlateform;

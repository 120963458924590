import React from "react";
import { toast } from "react-toastify";
import axios from "axios";
import ModalBox from '../../Modals/ModalBox';
import useButtonLoader from "../../Hooks/UseButtonLoader";

function VerifyEmployee({verifyId,verifyStatus,employeeName,verifyModal,modalClose,listing}) {
    const [buttonRef, loading] = useButtonLoader("Yes");
    

    // console.log("verifed Status", verifyStatus)
    //----------------Change-status-API--------------------------
    const changeEmpStatus = async () => {
      try {
        loading(true);
        const token = localStorage.getItem("demo-token");
        const response = await axios.put(
          `${process.env.REACT_APP_SECRET_KEY}/employee-mail-status/${verifyId}`,
          {
            verifyStatus: !verifyStatus,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.data.status === 200) {
          loading(false);
          modalClose();
          listing();
          toast.success(response.data.message);
        } else {
          loading(false);
          toast.error(response.data.message);
        }
      } catch (error) {
        loading(false);
        console.log(error);
      }
    };
    return (
      <>
        <ModalBox
          show={verifyModal}
          onClose={modalClose}
          heading="Change Status"
          description={`Are you sure want to change the status of ${employeeName}?`}
          action={changeEmpStatus}
          buttonRef={buttonRef}
        />
      </>
    );
  
}

export default VerifyEmployee

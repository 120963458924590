import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { toast } from "react-toastify";
import usePageLoader from "../../Hooks/UsePageLoader";
import useSearchBox from "../../Hooks/UseSearchBox";
import moment from "moment";
import { IoMdRefresh } from "react-icons/io";
import Pagination from "react-js-pagination";
import AddSingOffDate from "./AddSingOffDate";

function DeliveryTracking() {
  const [loding, show, hide] = usePageLoader();
  const [searchTerm, setSearchTerm] = useState("");
  const searchValue = useSearchBox(searchTerm, 650);
  const [yearSearch, setYearSearch] = useState(new Date().getFullYear().toString());
  const [monthSearch, setMonthSearch] = useState((new Date().getMonth() + 1).toString().padStart(2, '0'));
  const [projectTypeSearch, setProjectTypeSearch] = useState("");
  const [activePage, setActivePage] = useState(1);
  const [total, setTotal] = useState("");
  const [editId, setEditId] = useState("");
  const [editModal, setEditModal] = useState("");
  const [deliveryValue, setDeliveryValue] = useState("");



  const fetchDeliveryList = async () => {
    try {
      show();

      const token = localStorage.getItem("demo-token");
      const response = await axios.get(
        `${process.env.REACT_APP_SECRET_KEY}/project-tracking?projectType=${projectTypeSearch}&month=${monthSearch}&year=${yearSearch}&search=${searchValue}&page=${activePage}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log(response,"Response");
      // console.log("projectManagement", response.data.data.projectTrackingList);
      if (response.data.status === 200) {
        setDeliveryValue(response.data.data.projectTrackingList.listing);
        setTotal(response.data.data.projectTrackingList.total);
        hide();
      } else {
        hide();
        toast.error(response.data.message);
      }
    } catch (err) {
      hide();
      console.log("form", err);
    }
  };

  const searchHandler = (e) => {
    setSearchTerm(e.target.value);
  };

  const editHandler = (_id) => {
    setEditId(_id);
    setEditModal(true);
  };

  const refreshHandler = () => {
    setSearchTerm("");
    setYearSearch(new Date().getFullYear().toString());
    setMonthSearch((new Date().getMonth() + 1).toString().padStart(2, '0'));
    setProjectTypeSearch("");
    fetchDeliveryList();
  };

  useEffect(() => {
    if (searchValue) {
      setActivePage(1)
    } else {
      setActivePage(activePage)
    }
    fetchDeliveryList();
  }, [projectTypeSearch, yearSearch, activePage, monthSearch, searchValue]);

  return (
    <>
      {loding}
      <div className="WrapperArea">
        <div className="WrapperBox">
          <div className="Small-Wrapper ">
            <div className="d-flex">
              <h4 className="Title">Delivery Tracking</h4>
            </div>
            <div className="Small-Wrapper cstm-small_wrapper_employee">
              <div className="row">
                <div className="col-lg-3 col-md-3">
                  <form action="">
                    <input
                      className="input_style"
                      type="text"
                      placeholder="Search by employee name or manager name... "
                      onChange={searchHandler}
                      value={searchTerm}
                    />
                  </form>
                </div>
                <div className="col-lg-3 col-md-3">
                  <select
                    className="input_style"
                    onChange={(e) => setMonthSearch(e.target.value)}
                    value={monthSearch}
                  >
                    <option value="">Select Month</option>
                    <option value="01">January</option>
                    <option value="02">February</option>
                    <option value="03">March</option>
                    <option value="04">April</option>
                    <option value="05">May</option>
                    <option value="06">June</option>
                    <option value="07">July</option>
                    <option value="08">August</option>
                    <option value="09">September</option>
                    <option value="10">October</option>
                    <option value="11">November</option>
                    <option value="12">December</option>
                  </select>
                </div>
                <div className="col-lg-3 col-md-3">
                  <select
                    className="input_style"
                    onChange={(e) => setYearSearch(e.target.value)}
                    value={yearSearch}
                  >
                    <option value="">Select Year</option>
                    <option value={2022}>2022</option>
                    <option value={2023}>2023</option>
                    <option value={2024}>2024</option>
                    <option value={2025}>2025</option>
                    <option value={2026}>2026</option>
                  </select>
                </div>
                <div className="col-lg-2 col-md-2">
                  <select
                    className="input_style"
                    onChange={(e) => setProjectTypeSearch(e.target.value)}
                    value={projectTypeSearch}
                  >
                    <option value="">All Project Type</option>
                    <option value="Fixed Cost">Fixed Cost</option>
                    <option value="T %26 M">T & M</option>
                  </select>
                </div>
                <div className="col-lg-1 col-md-1">
                  <IoMdRefresh
                    className="fs-4 input_style_refresh employeeRefress"
                    role="button"
                    onClick={refreshHandler}
                  />
                </div>
              </div>
            </div>
            <div className="TableList">
              <table>
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Project Name</th>
                    <th>Project Type</th>
                    <th>Milestone Name</th>
                    <th>Manager Name</th>
                    <th>Actual Signed Off Date</th>
                    <th>Signed Off Date</th>
                    <th>Proposed Date</th>
                    <th>Delay status</th>
                    <th>Status</th>
                    <th>action</th>
                  </tr>
                </thead>
                <tbody>
                  {deliveryValue.length > 0 ? (
                    deliveryValue.map(
                      (
                        {
                          _id,
                          delayDays,
                          endDate,
                          milestoneName,
                          projectDelayStatus,
                          projectManagerName,
                          projectName,
                          projectStatus,
                          projectType,
                          proposedDate,
                          signOff,
                          projectAnalyst,
                          testingDate,
                        },
                        index
                      ) => {
                        const managerName = milestoneName === "M1" ? projectAnalyst : projectManagerName;

                        return (
                          <React.Fragment key={index}>
                            <tr>
                              <td>{((activePage - 1) * 50) + ++index}</td>
                              <td>{projectName}</td>
                              <td>{projectType}</td>
                              <td>{milestoneName || `---`}</td>
                              <td>{managerName}</td>
                              <td>{moment(endDate).format("DD-MM-YYYY") || `---`}</td>
                              <td>{signOff ? moment(signOff).format("DD-MM-YYYY") : `---`}</td>
                              <td>{proposedDate ? moment(proposedDate).format("DD-MM-YYYY") : `---`}</td>
                              <td>{delayDays > 0 ? <span className="dealyNegative" >{`${delayDays} days`}</span> : <span className="dealyPossitive">On-Time</span>}</td>
                              <td>{projectStatus}</td>
                              <td>
                                <div className="dropdown wrap_cstm_dropdown_menu">
                                  <button className="btn btn-secondary cstm_btn_sceon_background" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <i className="fa fa-ellipsis-v"></i>
                                  </button>
                                  <ul className="dropdown-menu cstm_dropdown_edit">
                                    <li><Link className="dropdown-item" onClick={() => editHandler(_id)} to="#"><i className="fa fa-pencil-square-o mx-3"></i>Edit</Link></li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                          </React.Fragment>
                        );
                      }
                    )
                  ) : (
                    <tr>
                      <td colSpan={11}>Not Found</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            {total >= 50 ? (
              <nav aria-label="Page navigation example" className="mt-3 cstm_pagination">
                <Pagination
                  previousLabel={"prev"}
                  nextLabel={"next"}
                  // breakLabel={'...'}
                  pageCount={activePage}
                  pageRangeDisplayed={5}
                  itemsCountPerPage={50}
                  totalItemsCount={total}
                  itemClass={"page-item"}
                  linkClass={"page-link"}
                  activeLinkClass={"active"}
                  activePage={activePage}
                  onChange={(page) => setActivePage(page)}
                />
              </nav>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <AddSingOffDate
        editId={editId}
        editModal={editModal}
        modalClose={() => setEditModal(false)}
        listing={fetchDeliveryList}
      />
    </>
  );
}

export default DeliveryTracking;

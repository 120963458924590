import React from "react";
import axios from "axios";
import { toast } from "react-toastify";
import ModalBox from "../../../Modals/ModalBox";
import useButtonLoader from "../../../Hooks/UseButtonLoader";

function DeleteDepartment({ deleteId, deleteModal, modalClose, listing }) {
  const [buttonRef, loading] = useButtonLoader("Yes");

  const deleteDepartment = async () => {
    try {
      loading(true);
      const token = localStorage.getItem("demo-token");
      const response = await axios.delete(
        `${process.env.REACT_APP_SECRET_KEY}/delete-department/${deleteId}`,

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log("delete",response)
      if (response.data.status === 200) {
        loading(false);
        modalClose();
        listing();
        toast.success(response.data.message);
      } else {
        loading(false);
        toast.error(response.data.message);
      }
    } catch (error) {
      // loading(false)
      console.log(error);
    }
  };

  return (
    <>
      <ModalBox
        show={deleteModal}
        onClose={modalClose}
        heading={"Delete"}
        description={"Are you sure want to delete this department?"}
        action={deleteDepartment}
        buttonRef={buttonRef}
      />
    </>
  );
}

export default DeleteDepartment;

import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import usePageLoader from "../../Hooks/UsePageLoader";
import ActiveMemberResource from "./ActiveMemberResource";
import ReleaseMemberResource from "./ReleaseMemberResource";
import { Chart } from "react-google-charts";

function ViewResourceDetails() {
  const { resourceId } = useParams();
  const [loder, show, hide] = usePageLoader();
  const [resourceDeatils, setResourceDetails] = useState("");
  const [projectDetails, setProjectDetails] = useState([]);

  const filterChartValue =
    projectDetails &&
    projectDetails.filter(({ isReleaseinProject }) => {
      return isReleaseinProject === false;
    });

  //----------------------get-resource-Deatils-------------------
  const getResourceDetails = async () => {
    try {
      show();
      const token = localStorage.getItem("demo-token");
      const response = await axios.get(
        `${process.env.REACT_APP_SECRET_KEY}/resource-detailes/${resourceId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log("ViewResourceDetails", response.data.data);
      if (response.data.status === 200) {
        hide();
        setResourceDetails(response.data.data);
        setProjectDetails(response.data.data.projects);
      } else {
        toast.error(response.data.message);
        hide();
      }
    } catch (err) {
      console.log("form", err);
    }
  };

  useEffect(() => {
    getResourceDetails();
  }, []);

  const data = filterChartValue.map((project) => [
    project.projectName,
    project.totalWorkingHoursInProject,
  ]);
  data.unshift(["Project Name", "Total Working Hours"]);
  const options = {
    title: "Project Working Hours",
    // is3D: true,
  };
  return (
    <>
      {loder}
      <div className="WrapperArea">
        <div className="WrapperBox">
          <div className="Small-Wrapper">
            <h4 className="Title">
              <span>
                <Link to="/resource-management">
                  <i className="fa fa-arrow-left" aria-hidden="true"></i>
                </Link>
              </span>
              Resource Details
            </h4>
            <div className="wrapper_radio_flex_align">
              <div className="OrderArea col-6">
                <ul>
                  <li>
                    <span>Employee id</span>
                    {resourceDeatils.employeeId || `---`}
                  </li>
                  <li>
                    <span>Employe name</span>
                    {resourceDeatils.name || `---`}
                  </li>
                  <li>
                    <span>Department name</span>
                    {resourceDeatils.department || `---`}
                  </li>

                  <li>
                    <span>Total working hours</span>
                    {resourceDeatils?.totalWorkingHours
                      ? `${resourceDeatils.totalWorkingHours} hours`
                      : `---`}
                  </li>
                </ul>
              </div>
              <div className="col-6 text-center">
                {data.length > 1 ? (
                  <Chart
                    chartType="PieChart"
                    data={data}
                    options={options}
                    width={"100%"}
                    height={"270px"}
                  />
                ) : (
                  <div>no data fond</div>
                )}
              </div>
            </div>
            <ActiveMemberResource
              projectDetails={projectDetails}
              resourceDeatils={resourceDeatils}
              listing={getResourceDetails}
            />

            <ReleaseMemberResource projectDetails={projectDetails} />
          </div>
        </div>
      </div>
    </>
  );
}

export default ViewResourceDetails;

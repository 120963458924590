import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import sidebar from "../routes/SidebarObject";
import SubMenu from "./SubMenu";

const Sidebar = () => {
  const token = localStorage.getItem("demoToken");
  const navigate = useNavigate();
  // const [open, setOpen] = useState(true);


  // const handleClick = () => {
  //   setOpen(!open);
  // };

  return (
    <div className="SidenavBar ">
      <div className="Logo ">
        <img alt="" className="rotate_img" src="./images/ripenApps.png" />
      </div>

      <ul className="cstm_ul_resource">
        {sidebar.map(({ id, icon, name, path, isChild }) => {
          if (!isChild) {
            return (
              <li key={id} className="">
                <NavLink
                  to={path}
                  style={({ isActive }) => {
                    return {
                      // color: isActive ? "white" : "#222",
                      // backgroundColor: isActive ? "#3e3ed9" : "#fff",
                    };
                  }}
                >
                  <span className="me-2">
                    <i className={icon}></i>
                  </span>
                  {name}
                </NavLink>
              </li>
            );
          } else {
            return (
              <React.Fragment key={id}>

                <SubMenu
                  name={name}
                  id={id}
                  icon={icon}
                  path={path}
                  isChild={isChild}
                />
                {/* <li className="">
                  <ul onClick={handleClick} className="masterData">
                    <li>
                      <div>
                        <span className=" me-2">
                          <i className={icon}></i>
                        </span>
                        {name}
                        {open ? (
                          <i className="fa fa-caret-up ml-auto"></i>
                        ) : (
                          <i className="fa fa-caret-down ml-auto"></i>
                        )} 
                      </div>
                    </li>
                  </ul>
                  {open && (
                    <ul className="cstm_ul_resource">
                      {isChild.map(({ id, icon, name, path }) => (
                        <li key={id} className="">
                          <NavLink

                            to={path}
                            style={({ isActive }) => {
                              return {
                                // color: isActive ? "white" : "#222",
                                // backgroundColor: isActive ? "#3e3ed9" : "#fff",
                              };
                            }}
                          >
                            <span className="">
                              <i className={icon}></i>
                            </span>
                            {name}
                          </NavLink>
                        </li>
                      ))}
                    </ul>
                  )}
                </li> */}
              </React.Fragment>
            );
          }
        })}
        {/* {sidebar.map(({ id, icon, name, path, isChild }) => (
          <React.Fragment key={id}>
            <li className="" key={id}>
              <NavLink
                to={path}
                style={({ isActive }) => {
                  return {
                    // color: isActive ? "white" : "#222",
                    // backgroundColor: isActive ? "#3e3ed9" : "#fff",
                  };
                }}
              >
                <span className="me-2">
                  <i className={icon}></i>
                </span>
                {name}
              </NavLink>
              {isChild && (
                <ul>
                  {isChild.map(({ id: childId, icon, name, path }) => (
                    <li className="" key={childId}>
                      <NavLink
                        to={path}
                        style={({ isActive }) => {
                          return {
                            // color: isActive ? "white" : "#222",
                            // backgroundColor: isActive ? "#3e3ed9" : "#fff",
                          };
                        }}
                      >
                        <span className="me-2">
                          <i className={icon}></i>
                        </span>
                        {name}
                      </NavLink>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          </React.Fragment>
        ))} */}


      </ul>
    </div>
  );
};

export default Sidebar;

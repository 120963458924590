import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import CheckBox from "../../../Modals/CheckBox";
import moment from "moment";
import usePageLoader from "../../../Hooks/UsePageLoader";
import useSearchBox from "../../../Hooks/UseSearchBox";
import Pagination from "react-js-pagination";
import AddDesignation from "./AddDesignation";
import UpdateDesignation from "./UpdateDesignation";
import StatusChangeDesignation from "./StatusChangeDesignation";
import DeleteDesignation from "./DeleteDesignation";

function Designation() {
  const [designation, setDesignation] = useState("");
  const [statusId, setStatusId] = useState("");
  const [status, setStatus] = useState("");
  const [statusModal, setStatusModal] = useState(false);
  const [updateId, setUpdateId] = useState("");
  const [designationName, setDesignationName] = useState("");
  const [updateModal, setUpdateModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [loding, show, hide] = usePageLoader();
  const [searchTerm, setSearchTerm] = useState("");
  const searchValue = useSearchBox(searchTerm, 650);
  const [activePage, setActivePage] = useState(1);
  const [total, setTotal] = useState(1);
  const [addDesignationModal, setAddDesignationModal] = useState(false);

  const fetchdesignation = async () => {
    try {
      show();
      const token = localStorage.getItem("demo-token");
      const response = await axios.get(
        `${process.env.REACT_APP_SECRET_KEY}/designation-list?search=${searchValue}&page=${activePage}`,

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log("departmentValue", response.data.data);
      if (response.data.status === 200) {
        hide();
        setDesignation(response.data.data.designations);
        setTotal(response.data.data.totaldesignation);
      } else {
        hide();
        toast.error(response.data.message);
      }
    } catch (err) {
      console.log("form", err);
    }
  };

  function statusHandler(_id, status) {
    setStatusId(_id);
    setStatus(status);
    setStatusModal(true);
  }
  function updateHandler(_id, designationName) {
    setUpdateId(_id);
    setDesignationName(designationName);
    setUpdateModal(true);
  }
  const deleteHandler = (_id) => {
    setDeleteId(_id);
    setDeleteModal(true);
  };

  function addHandler() {
    setAddDesignationModal(true);
  }

  const searchHandler = (e) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    if (searchValue) {
      setActivePage(1)
    } else {
      setActivePage(activePage)
    }
    fetchdesignation();
  }, [searchValue, activePage]);

  return (
    <>
      {loding}
      <div className="WrapperArea wrapper_area_cstm_employee">
        <div className="WrapperBox">
          <div className="Small-Wrapper">
            <div className="wrapper_employee_flex d-flex">
              <h4 className="Title">Designation Management</h4>
              <div className="Small-Wrapper cstm_wraper_btn borderNone">
                <div className="cstmSearchBox">
                  <Link to="#" onClick={addHandler}>
                    <i className="fa fa-upload mr-2" aria-hidden="true"></i>
                    Add Designation
                  </Link>
                </div>
              </div>
            </div>

            <div className="Small-Wrapper cstm-small_wrapper_employee">
              <div className="row">
                <div className="SearchBox">
                  <form action="">
                    <input
                      type="text"
                      className="input_style"
                      placeholder="Search designation..."
                      onChange={searchHandler}
                    />
                  </form>
                </div>
              </div>
            </div>
            <div className="TableList">
              <table>
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Designation</th>
                    <th>Created On</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>

                <tbody>
                  {designation.length > 0 ? (
                    designation.map(
                      (
                        { _id, designationName, status, createdAt, updatedAt },
                        index
                      ) => {
                        return (
                          <React.Fragment key={_id}>
                            <tr>
                              <td>{(activePage - 1) * 10 + ++index}</td>
                              <td>{designationName}</td>
                              <td>{moment(createdAt).format("DD/MM/YYYY")}</td>
                              <td>
                                <CheckBox
                                  status={status}
                                  onChange={() => statusHandler(_id, status)}
                                />
                              </td>
                              <td>
                                <div className="dropdown wrap_cstm_dropdown_menu">
                                  <button
                                    className="btn btn-secondary cstm_btn_sceon_background"
                                    type="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <i className="fa fa-ellipsis-v"></i>
                                  </button>
                                  <ul className="dropdown-menu cstm_dropdown_edit">
                                    <li>
                                      <Link
                                        className="dropdown-item"
                                        onClick={() =>
                                          updateHandler(_id, designationName)
                                        }
                                        to="#"
                                      >
                                        <i className="fa fa-pencil-square-o mx-3"></i>
                                        Edit
                                      </Link>
                                    </li>
                                    <li>
                                      <Link
                                        className="dropdown-item"
                                        onClick={() => deleteHandler(_id)}
                                        to="#"
                                      >
                                        {" "}
                                        <i
                                          className="fa fa-trash-o mx-3"
                                          aria-hidden="true"
                                        ></i>
                                        Delete
                                      </Link>
                                    </li>
                                  </ul>
                                </div>
                              </td>
                              {/* <td><Link><div className="text-danger fs-4">
                                    <i className="fa fa-trash-o"aria-hidden="true"  onClick={()=>deleteHandler(_id)}></i>
                                  </div></Link></td> */}
                            </tr>
                          </React.Fragment>
                        );
                      }
                    )
                  ) : (
                    <tr>
                      <td colSpan={6}>Not Found</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            {total >= 50 ? (
              <nav aria-label="Page navigation example" className="mt-3">
                <Pagination
                  previousLabel={"prev"}
                  nextLabel={"next"}
                  // breakLabel={'...'}
                  pageCount={activePage}
                  pageRangeDisplayed={5}
                  itemsCountPerPage={10}
                  totalItemsCount={total}
                  itemClass={"page-item"}
                  linkClass={"page-link"}
                  activeLinkClass={"active"}
                  activePage={activePage}
                  onChange={(page) => setActivePage(page)}
                />
              </nav>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <DeleteDesignation
        deleteId={deleteId}
        deleteModal={deleteModal}
        modalClose={() => setDeleteModal(false)}
        listing={fetchdesignation}
      />

      <AddDesignation
        addModal={addDesignationModal}
        modalClose={() => setAddDesignationModal(false)}
        listing={fetchdesignation}
      />

      <UpdateDesignation
        updateModal={updateModal}
        updateId={updateId}
        designationName={designationName}
        setDesignationName={setDesignationName}
        modalClose={() => setUpdateModal(false)}
        listing={fetchdesignation}
      />

      <StatusChangeDesignation
        statusId={statusId}
        status={status}
        statusModal={statusModal}
        modalClose={() => setStatusModal(false)}
        listing={fetchdesignation}
      />
    </>
  );
}

export default Designation;

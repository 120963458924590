import React, { useState } from "react";
import { BiSolidAddToQueue } from "react-icons/bi";
import { TiDelete } from "react-icons/ti";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function TmProject({ tmProject, setTmProject, error }) {

  const onChangeDateTmProject = (tmId, name, date) => {
    const updateTmRow = tmProject.map((repeat) => {
      if (repeat.tmId === tmId) {
        return { ...repeat, [name]: date };
      }
      return repeat;
    });
    setTmProject(updateTmRow);
  };
  const handleAddTmRow = () => {
    const newTmRow = {
      tmId: Date.now(),
      startDate: "",
      endDate: "",
      testingDate: "",
      deliveryDate: "",
    };
    setTmProject([...tmProject, newTmRow]);
  };
  //--------------T&M-project-Type----------------------
  const handleRemoveTmRow = (tmId) => {
    const updatedTMRow = tmProject.filter((repeat) => {
      return repeat.tmId !== tmId;
    });
    setTmProject(updatedTMRow);
  };

  return (
    <>
      <div className="Small-Wrapper ">
        <h6 className="Title my-1">Milestones</h6>
        <div className="Small-Wrapper mt-2">
          <div className="milestonRemoveButton cstm_css_hide mt-2">
            <TiDelete
              className="fs-3 color"
            />
          </div>
          <div className="row">
            <div className="col-3">
              <label htmlFor="tStartDate" className="col-form-label">
                Start Date
              </label>
              <br />
            </div>
            <div className="col-3">
              <label
                htmlFor="tEndDate"
                className="col-form-label"
              >
                End Date
              </label>
              <br />
            </div>
            <div className="col-3">
              <label
                htmlFor="tTestingDate"
                className="col-form-label"
              >
                Testing Date
              </label>
              <br />
            </div>
            <div className="col-3">
              <label
                htmlFor="tDeliveryDate"
                className="col-form-label"
              >
                Delivery Date
              </label>
              <br />
            </div>
          </div>
          <div className="milestonRemoveButton">
            <BiSolidAddToQueue
              className="fs-3 color"
              onClick={handleAddTmRow}
            />
          </div>


          {tmProject.map((repeat, index) => {
            return (
              <React.Fragment key={repeat.tmId}>
                {index !== 0 && (
                  <div className="milestonRemoveButton">
                    <TiDelete
                      className="fs-3 color"
                      onClick={() => handleRemoveTmRow(repeat.tmId)}
                    />
                  </div>
                )}
                <div className="">
                  <form className="needs-validation mb-2">
                    <div className="row">
                      <div className="col-3">
                        <DatePicker
                          placeholderText="Start Date"
                          className={`inputArea form-control ${!repeat.startDate && error.startDate && "is-invalid"}`}
                          name="startDate"
                          id="tStartDate"
                          selected={repeat.startDate}
                          value={repeat.startDate}
                          showYearDropdown
                          yearDropdownItemNumber={100}
                          scrollableYearDropdown
                          showMonthDropdown
                          useShortMonthInDropdown
                          onChange={(date) =>
                            onChangeDateTmProject(
                              repeat.tmId,
                              "startDate",
                              date
                            )
                          }
                          dateFormat="yyyy-MM-dd"
                          maxDate={repeat.endDate}
                        />
                        {!repeat.startDate && error.startDate && <p className="datePicker_validation_small">{error.startDate}</p>}
                      </div>
                      <div className="col-3 ">

                        <DatePicker
                          placeholderText="End Date"
                          className={`inputArea form-control ${!repeat.endDate && error.endDate && "is-invalid"}`}
                          name="endDate"
                          id="tEndDate"
                          selected={repeat.endDate}
                          value={repeat.endDate}
                          showYearDropdown
                          yearDropdownItemNumber={100}
                          scrollableYearDropdown
                          showMonthDropdown
                          useShortMonthInDropdown
                          onChange={(date) =>
                            onChangeDateTmProject(
                              repeat.tmId,
                              "endDate",
                              date
                            )
                          }
                          dateFormat="yyyy-MM-dd"
                          minDate={repeat.startDate}
                        />
                        {!repeat.endDate && error.endDate && <p className="datePicker_validation_small">{error.endDate}</p>}
                      </div>
                      <div className="col-3 ">

                        <DatePicker
                          placeholderText="Testing Date"
                          className={`inputArea form-control ${!repeat.testingDate && error.testingDate && "is-invalid"}`}
                          name="testingDate"
                          id="tTestingDate"
                          selected={repeat.testingDate}
                          value={repeat.testingDate}
                          showYearDropdown
                          yearDropdownItemNumber={100}
                          scrollableYearDropdown
                          showMonthDropdown
                          useShortMonthInDropdown
                          onChange={(date) =>
                            onChangeDateTmProject(
                              repeat.tmId,
                              "testingDate",
                              date
                            )
                          }
                          dateFormat="yyyy-MM-dd"
                          disabled={!repeat.startDate}
                          minDate={repeat.startDate}
                          maxDate={repeat.endDate}
                        />
                        {!repeat.testingDate && error.testingDate && <p className="datePicker_validation_small">{error.testingDate}</p>}
                      </div>
                      <div className="col-3">

                        <DatePicker
                          placeholderText="Delivery Date"
                          className={`inputArea form-control ${!repeat.deliveryDate && error.deliveryDate && "is-invalid"}`}
                          name="deliveryDate"
                          id="tDeliveryDate"
                          selected={repeat.deliveryDate}
                          value={repeat.deliveryDate}
                          showYearDropdown
                          yearDropdownItemNumber={100}
                          scrollableYearDropdown
                          showMonthDropdown
                          useShortMonthInDropdown
                          onChange={(date) =>
                            onChangeDateTmProject(
                              repeat.tmId,
                              "deliveryDate",
                              date
                            )
                          }
                          dateFormat="yyyy-MM-dd"
                          minDate={repeat.endDate}
                          disabled={!repeat.endDate}
                        />
                        {!repeat.deliveryDate && error.deliveryDate && <p className="datePicker_validation_small">{error.deliveryDate}</p>}
                      </div>
                    </div>
                  </form>
                </div>
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default TmProject;

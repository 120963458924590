import React from "react";
import { BiSolidAddToQueue } from "react-icons/bi";

import ProjectMemberComponent from "./ProjectMemberComponent";
import { TiDelete } from "react-icons/ti";

function ProjectMember({
  projectRow,
  setProjectRow,
  error,
  isChecked,
  onChangeCheckbox,
}) {
  const handleAddProjectRow = () => {
    const newProjectRow = {
      pId: Date.now(),
      departments: "",
      employee: "",
      workAssignedFor: "",
      startDate: "",
      endDate: "",
    };
    setProjectRow([...projectRow, newProjectRow]);
  };

  const onChangeProject = (pId, fieldName, value) => {
    if (fieldName === "employee") {
      const isEmployeeAlreadySelected = projectRow.some(
        (row) => row.employee === value
      );
      if (isEmployeeAlreadySelected) {
        let cloneProjectRow = projectRow.map((data) => data);
        const mainIndex = projectRow.findIndex((row) => row.employee === value);
        cloneProjectRow[mainIndex].employee = "";
        cloneProjectRow[mainIndex].departments = "";
        setProjectRow(cloneProjectRow);
      }
    }
    const updateProjectRow = projectRow.map((repeat) => {
      if (repeat.pId === pId) {
        return { ...repeat, [fieldName]: value };
      }
      return repeat;
    });
    setProjectRow(updateProjectRow);
  };

  const handleRemoveProjectRow = (pId) => {
    const updateProjectRow = projectRow.filter((repeat) => {
      return repeat.pId !== pId;
    });
    setProjectRow(updateProjectRow);
  };
  return (
    <>
      <div className="Small-Wrapper cstm_small_wrapper mt-2">
        <h6 className="Title my-1">Project Member</h6>
        <div className="Small-Wrapper cstm_remove_pad_card cstm_margin_add">
          <div className="milestonRemoveButton cstm_css_hide mt-2">
            <TiDelete className="fs-3 color" />
          </div>
          <div className="row">
            <div className="col-3">
              <label htmlFor="Department" className="col-form-label">
                Departments
              </label>
            </div>
            <div className="col-3">
              <label htmlFor="Employee" className="col-form-label">
                Employee
              </label>
            </div>
            <div className="col-2">
              <label htmlFor="workAssigne" className="col-form-label">
                Work Assigned For
              </label>
            </div>
            <div className="col-2">
              <label htmlFor="ProjectStartDate" className="col-form-label">
                Start Date
              </label>
            </div>
            <div className="col-2">
              <label htmlFor="ProjectEndDate" className="col-form-label">
                End Date
              </label>
              <br />
            </div>
          </div>
          <div className="milestonRemoveButton mt-">
            <BiSolidAddToQueue
              className=" fs-3 color"
              onClick={handleAddProjectRow}
            />
          </div>

          {projectRow.map((repeat, index) => {
            return (
              <ProjectMemberComponent
                key={++index}
                repeat={repeat}
                index={index}
                handleRemoveProjectRow={handleRemoveProjectRow}
                onChangeProject={onChangeProject}
                error={error}
                projectRow={projectRow}
                setProjectRow={setProjectRow}
              />
            );
          })}
          <div className="col-lg-6 col-md-6">
            <div>
              <label className="mt-4">
                Send Email Notification :
                <input
                  className="radioButton radioIcon mx-2"
                  type="checkbox"
                  name="sendEmailStatus"
                  checked={isChecked}
                  value={!isChecked}
                  onChange={onChangeCheckbox}
                />
              </label>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProjectMember;

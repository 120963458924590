import React, { useEffect, useState } from "react";
import ModalBox from "../../Modals/ModalBox";
import useButtonLoader from "../../Hooks/UseButtonLoader";
import axios from "axios";
import { toast } from "react-toastify";
import Select from "react-select";

function AssignPersonLaptop({
  assignId,
  assignModal,
  modalClose,
  listing,
  employeName,
}) {
  const [assignValue, setAssignValue] = useState("");
  const [error, setError] = useState({});
  const [buttonRef, loading] = useButtonLoader("Submit");
  const [employee, setEmployee] = useState([]);

  // console.log("employeName", assignValue);

  //-----Assign-select-box-conditon------------
  const defaultOption = employeName._id
    ? { value: employeName._id, label: employeName.name }
    : ""

  const validation = () => {
    let errorMessage = {
      assignPerson: "",
    };
    let status = true;

    if (!assignValue) {
      errorMessage["assignPerson"] = "This fiel is required";
      status = false;
    }
    setError(errorMessage);
    return status;
  };

  //----------------assign-person-API--------------------------
  const fecthemployee = async () => {
    try {
      const token = localStorage.getItem("demo-token");
      const response = await axios.get(
        `${process.env.REACT_APP_SECRET_KEY}/master-data-employee`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      //   console.log("departmentValue", response.data.data.employees);
      if (response.data.status === 200) {
        setEmployee(response.data.data.employees);
      } else {
        // toast.error(response.data.message);
      }
    } catch (err) {
      console.log("form", err);
    }
  };
  //----------------update-Assign-person-API--------------------------
  const updateAssignPerson = async () => {
    const status = validation();
    if (status) {
      try {
        loading(true);
        const token = localStorage.getItem("demo-token");
        const response = await axios.put(
          `${process.env.REACT_APP_SECRET_KEY}/addPerson/${assignId}`,
          { employeeId: assignValue.value },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        // console.log("updateDepartment", response);
        if (response.data.status === 200) {
          loading(false);
          modalClose();
          listing();
          setAssignValue("");
          toast.success(response.data.message);
        } else {
          loading(false);
          toast.error(response.data.message);
        }
      } catch (error) {
        loading(false);
        console.log(error);
      }
    }
  };

  useEffect(() => {
    fecthemployee();
    setAssignValue( defaultOption)
  }, [employeName]);

  return (
    <>
      <ModalBox
        show={assignModal}
        onClose={() => {
          modalClose();
          // setAssignValue("");
          setError("");
        }}
        heading={"Assign person"}
        customizedFooter={
          <div>
            <button
              className="btn btn-secondary modalButtonClose mx-2"
              onClick={() => {
                modalClose();
                // setAssignValue("");
                setError("");
              }}
            >
              close
            </button>
            <button
              className="modalButtonYes btn btn-primary modalButtonSubmit"
              onClick={updateAssignPerson}
              ref={buttonRef}
            >
              Submit
            </button>
          </div>
        }
      >
        <div className="rounded fs-6">
          <label htmlFor="assignPerson" className="col-form-label">
            Assign person
          </label>
          <Select
            // className={`form-control ${
            //   !assignValue && error.assignPerson && "is-invalid"
            // }`}
            className={`form-control ${
              (!assignValue && error.assignPerson) || (defaultOption === null) ? "is-invalid" : ""
            }`}
            name="assignPerson"
            id="assignPerson"
            placeholder="Search...."
            onChange={(e) => setAssignValue(e)}
            value={assignValue}
            options={employee.map(({ _id, name }) => ({
              value: _id,
              label: name,
            }))}
          ></Select>
          <div className="invalid-feedback">{error.assignPerson}</div>
        </div>
      </ModalBox>
    </>
  );
}

export default AssignPersonLaptop;

import React from 'react'

function CheckBox({status,onChange,name}) {
  return (
    <>
    <div className="form-check form-switch mt-2 fs-6 ">
      <input
        className="form-check-input"
        name = {name}
        type="checkbox"
        checked={status}
        onChange={onChange}
        // data-toggal='toggle'
        // data-onstyle='warning'
      />
    </div>
  </>
  )
}

export default CheckBox

import React, { useState, useEffect } from 'react'

function useSearchBox(value, delay) {
  const [searchValue, setSearchValue] = useState(value)
  useEffect(() => {
    const handle = setTimeout(() => {
      setSearchValue(value)
    }, delay)
    return () => {
      clearTimeout(handle)
    }
  }, [value, delay])
  return searchValue
}

export default useSearchBox

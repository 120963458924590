import React, { useEffect, useState } from "react";
import useButtonLoader from "../../Hooks/UseButtonLoader";
import { Link, useNavigate, useParams } from "react-router-dom";
import { BiSolidAddToQueue } from "react-icons/bi";
import { TiDelete } from "react-icons/ti";
import axios from "axios";
import { toast } from "react-toastify";
import { v4 as uuidv4, v4 } from "uuid";
import usePageLoader from "../../Hooks/UsePageLoader";
import { inputAdornmentClasses } from "@mui/material";

function UpdateCategory() {
  const { categoryId } = useParams();
  const [inputFields, setInputFields] = useState([
    { id: Date.now(), name: "", inputType: "inputbox" },
  ]);
  const [categoryName, setCategoryName] = useState("");
  const [buttonRef, loding] = useButtonLoader("Submit");
  const [loader, show, hide] = usePageLoader();
  const navigator = useNavigate();
  const [error, setError] = useState({});

  // console.log("inputTypecheck,", inputFields);

  const validation = () => {
    let errorMessage = {};
    let status = true;

    if (!categoryName) {
      errorMessage["categoryName"] = "Category Name is required";
      status = false;
    }

    inputFields.forEach((field, index) => {
      if (!field.name) {
        errorMessage[`attribute-${index}`] = "This field is required";
        status = false;
      }
    });

    setError(errorMessage);
    return status;
  };

  // Function to handle adding a new input field
  const handleAddField = () => {
    const newInput = { id: Date.now(), name: "", inputType: "inputbox" };
    setInputFields([...inputFields, newInput]);
  };
  // Function to handle Remove a input field
  const handleRemoveField = (id) => {
    const updatedFields = inputFields.filter((field) => field.id !== id);
    setInputFields(updatedFields);
  };

  // Function to handle input field change
  const handleInputChange = (index, event) => {
    const values = [...inputFields];
    values[index].name = event.target.value;
    setInputFields(values);

    setError((prevError) => {
      const updatedError = { ...prevError };
      updatedError[`attribute-${index}`] = "";
      return updatedError;
    });
  };
  // -----------onchange-handler-inputType-----------
  const handleInputTypeChange = (index, event) => {
    const values = [...inputFields];
    values[index].inputType = event.target.value;
    setInputFields(values);
  };

  //-----------update-category-Api----------------------

  const UpdateCategory = async () => {
    const status = validation();
    if (status) {
      try {
        loding(true);
        const token = localStorage.getItem("demo-token");
        const attributeValues = inputFields.map(({ name, inputType }) => {
          return {
            name: name,
            inputType: inputType,
          };
        });
        const response = await axios.put(
          `${process.env.REACT_APP_SECRET_KEY}/category-update/${categoryId}`,
          {
            categoryName: categoryName,
            attributes: attributeValues,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.data.status === 200) {
          loding(false);
          navigator("/category-Management");
          toast.success(response.data.message);
        } else {
          loding(false);
          toast.error(response.data.message);
        }
      } catch (error) {
        loding(false);
        console.log(error);
      }
    }
  };

  //-----------get-value-category-Api----------------------
  const FetchCategory = async () => {
    try {
      show();
      const token = localStorage.getItem("demo-token");
      const response = await axios.get(
        `${process.env.REACT_APP_SECRET_KEY}/category-details/${categoryId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log("FetchUpdateCategory", response.data.data.detailes);
      if (response.data.status === 200) {
        hide();
        //-------------get-Category-Name----------------
        const [categoryValue] = response.data.data.detailes;
        // const categoryNames = {
        //   categoryName: categoryValue.categoryName,
        // };
        setCategoryName(categoryValue.categoryName);
        //-------------get-Attributes-Name----------------
        const attributeAllValue = categoryValue.attributes.map(
          ({ name, inputType }) => {
            return {
              id: uuidv4(),
              name: name,
              inputType: inputType,
            };
          }
        );
        setInputFields(attributeAllValue);
      } else {
        hide();
        toast.error(response.data.message);
      }
    } catch (err) {
      hide();
      console.log("form", err);
    }
  };

  useEffect(() => {
    FetchCategory();
  }, []);

  return (
    <>
      {loader}
      <div className="WrapperArea">
        <div className="WrapperBox">
          <div className="Small-Wrapper">
            <h4 className="Title">
              <span>
                <Link to="/category-Management">
                  <i className="fa fa-arrow-left" aria-hidden="true"></i>
                </Link>
              </span>
              Update Category
            </h4>

            <div>
              <label htmlFor="categoryName">Category Name</label>
              <input
                type="text"
                // className="form-control w-25 mt-1"
                className={`form-control w-50 mt-1 ${
                  !categoryName && error.categoryName && "is-invalid"
                }`}
                placeholder="Enter your category name"
                name="categoryName"
                id="categoryName"
                value={categoryName}
                onChange={(e) => setCategoryName(e.target.value)}
                maxLength={18}
              />
              {!categoryName && (
                <div className="invalid-feedback">{error.categoryName}</div>
              )}
            </div>
            <div>
              <label htmlFor="" className="mt-2">
                Attributes
              </label>
            </div>
            <div>
              {inputFields.map(({ name, inputType, id }, index) => (
                <div className="cstm_width_place" key={index}>
                  <div className="form_field_cstm_error mt-2">
                    <input
                      type="text"
                      // className="form-control w-25 mt-2"
                      className={`form-control ${
                        error[`attribute-${index}`] && "is-invalid"
                      }`}
                      placeholder=""
                      name={`attribute-${index}`}
                      id={`attribute-${index}`}
                      value={name}
                      onChange={(e) => handleInputChange(index, e)} // Handle input change
                      maxLength={18}
                    />
                    <div className="col-lg-2 col-md-2 mx-1">
                      <select
                        className="input_style "
                        name={`inputType${index}`}
                        id={`input-type-${index}`}
                        value={inputType}
                        onChange={(e) => handleInputTypeChange(index, e)}
                      >
                        <option value="inputbox">inputbox</option>
                        <option value="dropdown">dropdown</option>
                      </select>
                    </div>
                    {index !== 0 ? (
                      <div className="milestonRemoveButton_cstm">
                        <TiDelete
                          className="fs-3 color"
                          onClick={() => handleRemoveField(id)}
                        />
                      </div>
                    ) : (
                      <div className="milestonButton_cstm">
                        <BiSolidAddToQueue
                          className="fs-4 color"
                          onClick={handleAddField}
                        />
                      </div>
                    )}
                  </div>
                  {error[`attribute-${index}`] && (
                    <p className="error">{error[`attribute-${index}`]}</p>
                  )}
                </div>
              ))}
              {/* {inputFields.map((input, index) => (
                <div key={index}>
                  {index !== 0 && (
                    <div className="milestonRemoveButton">
                      <TiDelete
                        className="fs-3 color removeAttribute"
                        onClick={() => handleRemoveField(input.id)}
                      />
                    </div>
                  )}
                  <input
                    type="text"
                    // className="form-control w-25 mt-2"
                    className={`form-control w-25 mt-2 ${
                      error[`attribute-${index}`] && "is-invalid"
                    }`}
                    placeholder=""
                    name={`attribute-${index}`}
                    id={`attribute-${index}`}
                    value={input.value}
                    onChange={(e) => handleInputChange(index, e)} // Handle input change
                  />
                  {error[`attribute-${index}`] && (
                    <div className="invalid-feedback">
                      {error[`attribute-${index}`]}
                    </div>
                  )}
                </div>
              ))} */}
            </div>

            <div className="mt-3">
              <button
                className="butonColor"
                ref={buttonRef}
                onClick={UpdateCategory}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UpdateCategory;

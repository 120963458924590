import { Backdrop, CircularProgress } from '@mui/material'
import React, { useState } from 'react'

function usePageLoader() {
    const [pageLoader,setPageLoader]=useState(false)

    const showPageLoader=()=>{
        setPageLoader(true)
    }
 
    const hidePageLoader=()=>{
        setPageLoader(false)
    }

    const mainPageLoader=pageLoader?(
        <Backdrop
        // #4040e0
        sx={{color:'#111' , zIndex:(theme)=>theme.zIndex.drawer+1}}
        open={pageLoader}>
            <CircularProgress color='inherit'/>
        </Backdrop>
        // <img src="./images/loader.gif" className='fp-loader' alt="" /> 
    ):null

    return[mainPageLoader,showPageLoader,hidePageLoader]


}

export default usePageLoader


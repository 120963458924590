import axios from "axios";
import React, { useEffect, useState } from "react";
import { TiDelete } from "react-icons/ti";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import { BiSolidAddToQueue } from "react-icons/bi";

function ProjectMemberComponent({
  repeat,
  index,
  handleRemoveProjectRow,
  onChangeProject,
  error,
  projectRow,
  setProjectRow,
}) {
  const [projectDepartment, setProjectDepartment] = useState("");
  const [employeeValue, setEmployeeValue] = useState([]);
  // console.log("repeat",repeat)
  
  //----------------department-API--------------------------
  const fetchDepartment = async () => {
    try {
      const token = localStorage.getItem("demo-token");
      const response = await axios.get(
        `${process.env.REACT_APP_SECRET_KEY}/master-data-department`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.status === 200) {
        setProjectDepartment(response.data.data.departments);
      } else {
        toast.error(response.data.message);
      }
    } catch (err) {
      console.log("form", err);
    }
  };
  // console.log(typeof workAssignedFor,"work")

  //---------------Fetch-Employees-API--------------------------
  const fetchEmployee = async (id) => {
    try {
      const token = localStorage.getItem("demo-token");
      const response = await axios.get(
        `${process.env.REACT_APP_SECRET_KEY}/project-employee/${repeat.departments}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log("departmentEmploye", response.data.data.projectEmployee);
      if (response.data.status === 200) {
        const empArray = projectRow.map((data) => data.employee);

        const finalArray = response.data.data.projectEmployee.filter((data) => {
          return !empArray.includes(data._id);
        });

        // console.log("final array",finalArray)
        // console.log("empArray",empArray)
        setEmployeeValue(finalArray);
        // setEmployeeValue(response.data.data.projectEmployee)
      } else {
        toast.error(response.data.message);
      }
    } catch (err) {
      console.log("form", err);
    }
  };

  // Call fetchEmployee whenever repeat.departments changes
  useEffect(() => {
    fetchDepartment();
    if (repeat.departments) {
      fetchEmployee();
    }
  }, [repeat.departments]);

  return (
    <React.Fragment key={repeat.pId}>

      {index !== 0 && (
        <div className="milestonRemoveButton mt-">
          <TiDelete
            className="fs-3 color"
            onClick={() => handleRemoveProjectRow(repeat.pId)}
          />
        </div>
      )}


      <div className="mt-1">
        <form className="needs-validation">
          <div className="row">
            <div className="col-3">
              <select
                className={`inputArea custm-form-control ${!repeat.departments && error.departments && "is-invalid"
                  }`}
                name="departments"
                id="Department"
                onChange={(e) => {
                  onChangeProject(
                    repeat.pId,
                    e.target.name,
                    e.target.value
                  );
                }}
                value={repeat.departments}
              >
                <option value="">Departments</option>
                {projectDepartment &&
                  projectDepartment.map(
                    ({ _id, departmentName }, idindex) => (
                      <option key={idindex} value={_id}>
                        {departmentName}
                      </option>
                    )
                  )}
              </select>
              <span className="invalid-feedback">
                {error.departments}
              </span>
            </div>
            <div className="col-3">
              <select
                className={`inputArea custm-form-control ${!repeat.employee && error.employee && "is-invalid"
                  }`}
                name="employee"
                id="Employee"
                onChange={(e) => {
                  onChangeProject(
                    repeat.pId,
                    e.target.name,
                    e.target.value
                  )
                }}
                value={repeat.employee}
              >
                <option>Employee</option>

                {employeeValue &&
                  employeeValue.map(({ _id, name }, idindex) => {
                    return (
                      <React.Fragment key={idindex}>
                        <option value={_id}>{name}</option>
                      </React.Fragment>
                    );
                  })}
              </select>
              <span className="invalid-feedback">{error.employee}</span>
            </div>
            <div className="col-2">
              <select
                className={`inputArea custm-form-control ${!repeat.workAssignedFor &&
                  error.workAssignedFor &&
                  "is-invalid"
                  }`}
                name="workAssignedFor"
                id="workAssigne"
                onChange={(e) =>
                  onChangeProject(
                    repeat.pId,
                    e.target.name,
                    e.target.value
                  )
                }
                value={repeat.workAssignedFor}
              >
                <option>Work Assigned For</option>
                <option value="0">0 Hours</option>
                <option value="1">1 Hours</option>
                <option value="2">2 Hours</option>
                <option value="3">3 Hours</option>
                <option value="4">4 Hours</option>
                <option value="5">5 Hours</option>
                <option value="6">6 Hours</option>
                <option value="7">7 Hours</option>
                <option value="8">8 Hours</option>
              </select>
              <span className="invalid-feedback">
                {error.workAssignedFor}
              </span>
            </div>
            <div className="col-2">
              <DatePicker
                placeholderText="Start Date"
                className={`inputArea form-control ${!repeat.startDate && error.startDate && "is-invalid"
                  }`}
                name="startDate"
                id="ProjectStartDate"
                selected={repeat.startDate}
                value={repeat.startDate}
                showYearDropdown
                yearDropdownItemNumber={100}
                scrollableYearDropdown
                showMonthDropdown
                useShortMonthInDropdown
                onChange={(date) =>
                  onChangeProject(repeat.pId, "startDate", date)
                }
                dateFormat="yyyy-MM-dd"
                maxDate={repeat.endDate}
              />
              {!repeat.startDate && error.startDate && (
                <p className="datePicker_validation_small">
                  {error.startDate}
                </p>
              )}
            </div>
            <div className="col-2">
              <DatePicker
                placeholderText="End Date"
                className={`inputArea form-control ${!repeat.endDate && error.endDate && "is-invalid"
                  }`}
                name="endDate"
                id="ProjectEndDate"
                selected={repeat.endDate}
                value={repeat.endDate}
                showYearDropdown
                yearDropdownItemNumber={100}
                scrollableYearDropdown
                showMonthDropdown
                useShortMonthInDropdown
                onChange={(date) =>
                  onChangeProject(repeat.pId, "endDate", date)
                }
                dateFormat="yyyy-MM-dd"
                minDate={repeat.startDate}
              />
              {!repeat.endDate && error.endDate && (
                <p className="datePicker_validation_small">
                  {error.endDate}
                </p>
              )}
            </div>
          </div>
        </form>
      </div>
    </React.Fragment>
  );
}

export default ProjectMemberComponent;

import React, { useState } from "react";
import Select from "react-select";
import ModalBox from "../../Modals/ModalBox";
import useButtonLoader from "../../Hooks/UseButtonLoader";
import axios from "axios";
import { toast } from "react-toastify";

function DownloadExcel({ category, downloadExcelModal, modalClose, listing }) {
  const [buttonRef, loading] = useButtonLoader("Download");
  const [error, setError] = useState({});
  const [excelValue, setExcelValue] = useState("");

  //   console.log("excelValue", excelValue);

  const validation = () => {
    let errorMessage = {
      excel: "",
    };
    let status = true;

    if (!excelValue) {
      errorMessage["excel"] = "This fiel is required";
      status = false;
    }
    setError(errorMessage);
    return status;
  };
  //----------------Download-Excel-API--------------------------
  const downloadExcel = async () => {
    const status = validation();
    if (status) {
      try {
        loading(true);
        const response = await axios.get(
          `${process.env.REACT_APP_SECRET_KEY}/download-inventory-data/${excelValue}`
        );
        // console.log(response);
        if (response.status === 200) {
          loading(false);
          modalClose();
          listing();
          setExcelValue("");
          toast.success(response.data.message);
          window.open(
            `${process.env.REACT_APP_SECRET_KEY}/download-inventory-data/${excelValue}`
          );
        } else {
          loading(false);
          toast.error(response.data.message);
        }
      } catch (error) {
        loading(false);
        console.log(error);
      }
    }
  };

  return (
    <>
      <ModalBox
        show={downloadExcelModal}
        onClose={() => {
          modalClose();
          setExcelValue("");
          setError("");
        }}
        heading={"Download Excel"}
        customizedFooter={
          // <div>
          //   <button
          //     className="btn btn-secondary modalButtonClose mx-2"
          //     onClick={() => {
          //       modalClose();
          //       setExcelValue("");
          //       setError("");
          //     }}
          //   >
          //     close
          //   </button>
          //   <button
          //     className="modalButtonYes btn btn-primary modalButtonSubmit w-100"
          //     onClick={downloadExcel}
          //     ref={buttonRef}
          //   >
          //     Download
          //   </button>
          // </div>
          <div class="d-flex ">
            <div class="mx-2 ">
              <button
                class="modalButtonClose btn btn-secondary"
                onClick={() => {
                  modalClose();
                  setExcelValue("");
                  setError("");
                }}
              >
                Close
              </button>
            </div>
            <div class="mx-2">
              <button
                class="modalButtonSubmit modalButtonYes btn btn-primary w-100"
                onClick={downloadExcel}
                ref={buttonRef}
              >
                Download
              </button>
            </div>
          </div>
        }
      >
        <div className="rounded fs-6">
          <label htmlFor="downlodExcel" className="col-form-label">
            Select ategory
          </label>
          <select
            className={`form-control input_style ${
              !excelValue && error.excel && "is-invalid"
            }`}
            name="excel"
            id="downlodExcel"
            onChange={(e) => setExcelValue(e.target.value)}
            value={excelValue}
          >
            <option value="">Select cagetory...</option>
            {category &&
              category.map(({ categoryName, _id }, idindex) => {
                return (
                  <React.Fragment key={idindex}>
                    <option value={_id}>{categoryName}</option>
                  </React.Fragment>
                );
              })}
          </select>
          <div className="invalid-feedback">{error.excel}</div>
        </div>
      </ModalBox>
    </>
  );
}

export default DownloadExcel;

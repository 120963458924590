// import "App";
import { HashRouter, Outlet, Route, Routes } from "react-router-dom";
import Login from "./pages/auth/Login";
import routes from "./routes/RouterObject";
import Sidebar from "./common/Sidebar";
import Header from "./common/Header";
import React from "react";

import ForgotPassword from "./pages/auth/ForgotPassword";
import ResetPassword from "./pages/auth/ResetPassword";
import { AxiosInterceptor } from "./config/Interceptor";

const MainHeader = () => {
  return (
    <>
      <Header />
      <Sidebar />
      <Outlet />
    </>
  );
};

function App() {
  return (
    <>
      <HashRouter>
        <AxiosInterceptor>
        <Routes>
          <Route element={<MainHeader />}>
            {routes &&
              routes.map(({ id, path, element, Button }) => {
                return (
                  <React.Fragment key={id}>
                    <Route
                      path={path}
                      element={element}
                      key={id}
                      Button={Button}
                    />
                  </React.Fragment>
                );
              })}
          </Route>
          <Route path="/" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password/:token" element={<ResetPassword/>}/>
        </Routes>
        </AxiosInterceptor>
      </HashRouter>
    </>
  );
}

export default App;

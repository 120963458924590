import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { TiDelete } from "react-icons/ti";
import usePageLoader from "../../Hooks/UsePageLoader";
import useButtonLoader from "../../Hooks/UseButtonLoader";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import ModalBox from "../../Modals/ModalBox";

function UpdateInventoryMobile() {
  const [categorieName, setCategorieName] = useState("");
  const [categorieId, setCategorieId] = useState("");
  const [purchaseValue, setPurchaseValue] = useState("");
  const [deviceIdValue, setDeviceIdValue] = useState("");
  const [workStatus, setWorkStatus] = useState("");
  const [labelValue, setLabelValue] = useState([]);
  const [buttonRef, loding] = useButtonLoader("Submit");
  const [images, setImages] = useState([]);
  const [multiimages, setMultiimages] = useState([]);
  const [loder, show, hide] = usePageLoader();
  const [removeImageId, setRemoveImageId] = useState([]);
  const navigator = useNavigate();
  const { inventoryId } = useParams();
  const [error, setError] = useState({});
  const [showImageModal, setShowImageModal] = useState(false)
  const [showImage, setShowImage] = useState("")

  const validation = () => {
    const alphNumeric = /^[a-zA-Z0-9]+$/;
    const imageExtensions = /\.(jpg|jpeg|png|svg)$/i;
    let errorMessage = {};
    let status = true;

    labelValue.forEach((label, index) => {
      const inputValue = labelValue[index]?.value; // Get the value of the input field
      if (!inputValue) {
        errorMessage[`attribute-${index}`] = `${label.name} is required`;
        status = false;
      }
    });

    if (!deviceIdValue) {
      errorMessage["deviceId"] = "Please Enter device Id";
      status = false;
    } else if (!alphNumeric.test(deviceIdValue)) {
      errorMessage["deviceId"] = "Please Enter Valid device id";
      status = false;
    }

    if (!purchaseValue) {
      errorMessage["purchaseDate"] = "Purchase Date is required";
      status = false;
    }
    if (!workStatus) {
      errorMessage["workStatus"] = "Please select work status";
      status = false;
    }
    multiimages.forEach((image) => {
      if (!imageExtensions.test(image.name)) {
        errorMessage["images"] = "Invalid image format"
        status = false
      }
    })

    setError(errorMessage);
    return status;
  };

  const handleInputChange = (event, index, inputType) => {
    const { name, value } = event.target;
    const updatedLabelValue = [...labelValue];
    updatedLabelValue[index] = {
      ["name"]: name,
      ["value"]: value,
      ["inputType"]: inputType,
    };
    setLabelValue(updatedLabelValue);
    setError((prevError) => ({ ...prevError, [`attribute-${index}`]: "" }));
  };

  function imageHandler(files) {
    const selectedImages = Array.from(files);
    setMultiimages((prevImages) => [...prevImages, ...selectedImages]); // Append to the existing images
  }
  const removeImage = (index, imageId) => {
    setRemoveImageId([...removeImageId, imageId]);
    const updatedImages = [...images];
    updatedImages.splice(index, 1);
    setImages(updatedImages);
  };

  const removeMultImage = (index) => {
    const updatedImages = [...multiimages];
    updatedImages.splice(index, 1);
    setMultiimages(updatedImages);
  };

  //-----------Update-value-inventory-Api----------------------
  const updateInventory = async () => {
    const status = validation();
    if (status) {
      try {
        loding(true);
        const token = localStorage.getItem("demo-token");
        const purchaseDate = moment(purchaseValue).format("YYYY-MM-DD");
        const formdata = new FormData();
        formdata.append("categoryId", categorieId);
        formdata.append("deviceId", deviceIdValue);
        formdata.append("workingStatus", workStatus);
        formdata.append("purchaseDate", purchaseDate);
        formdata.append("attributes", JSON.stringify(labelValue));

        for (let index = 0; index < removeImageId.length; index++) {
          formdata.append("deletedImageIds", removeImageId[index]);
        }
        for (let index = 0; index < multiimages.length; index++) {
          formdata.append("images", multiimages[index]);
        }
        const response = await axios.put(
          `${process.env.REACT_APP_SECRET_KEY}/inventory-update/${inventoryId}`,
          formdata,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.data.status === 200) {
          loding(false);
          navigator("/mobile-inventory");
          toast.success(response.data.message);
        } else {
          loding(false);
          toast.error(response.data.message);
        }
      } catch (error) {
        loding(false);
        console.log(error);
      }
    }
  };

  //-----------get-value-inventory-Api----------------------

  const fetchInventory = async () => {
    try {
      show();
      const token = localStorage.getItem("demo-token");
      const response = await axios.get(
        `${process.env.REACT_APP_SECRET_KEY}/inventory-detailes/${inventoryId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.status === 200) {
        hide();
        //-------------get-Category-Name----------------
        const categoryValue = response.data.data.inventoryDetails;
        setPurchaseValue(new Date(categoryValue.purchaseDate));
        setCategorieName(categoryValue.category.categoryName);
        setCategorieId(categoryValue.category._id);
        setImages(categoryValue.images);
        setDeviceIdValue(categoryValue?.deviceId);
        setWorkStatus(categoryValue?.workingStatus);

        const attributeAllValue = categoryValue?.attributes;

        setLabelValue(attributeAllValue);
      } else {
        hide();
        toast.error(response.data.message);
      }
    } catch (err) {
      console.log("form", err);
    }
  };

  {/* ----------show-image-in-modal--------------- */ }
  const showModal = (image) => {
    setShowImage(image)
    setShowImageModal(true)
  }

  useEffect(() => {
    fetchInventory();
  }, []);

  return (
    <>
      <title>Inventory Management | Update Inventory</title>
      {loder}
      <div className="WrapperArea">
        <div className="WrapperBox">
          <div className="Small-Wrapper">
            <h4 className="Title">
              <span>
                <Link to="/mobile-inventory">
                  <i className="fa fa-arrow-left" aria-hidden="true"></i>
                </Link>
              </span>
              Update Inventory
            </h4>

            <div className="cstm_span_style">
              <span className="">{categorieName}</span>
            </div>
            <div className="row">
              {labelValue &&
                labelValue.map(({ name, value, inputType }, index) => (
                  <div className="col-4" key={index}>
                    <label className="mt-4" htmlFor={`attribute-${index}`}>
                      {name || "---"}
                    </label>
                    {inputType === "inputbox" ? (
                      <input
                        type="text"
                        className={
                          error[`attribute-${index}`]
                            ? "form-control mt-2 is-invalid"
                            : "form-control mt-2"
                        }
                        placeholder={`Please enter ${name}`}
                        name={name}
                        id={`attribute-${index}`}
                        value={value}
                        onChange={(e) => handleInputChange(e, index, inputType)}
                        maxLength={24}
                      />
                    ) : (
                      <select
                        className={
                          error[`attribute-${index}`]
                            ? "custm-form-control mt-2  is-invalid"
                            : "custm-form-control mt-2 "
                        }
                        name={name}
                        id={`attribute-${index}`}
                        value={value}
                        onChange={(e) => handleInputChange(e, index, inputType)}
                      >
                        <option value="">Select {name}</option>
                        <option value="Yes"> Yes </option>, ,
                        <option value="No"> No </option>
                      </select>
                    )}
                    {error[`attribute-${index}`] && (
                      <div className="invalid-feedback">
                        {error[`attribute-${index}`]}
                      </div>
                    )}
                  </div>
                ))}
            </div>
            <div className="row mt-4">
              <div className="col-4">
                <label htmlFor="Device Id" className="col-form-label">
                  Device Id
                </label>
                <br />
                <input
                  type="text"
                  placeholder="Enter your device id"
                  className={`form-control ${!deviceIdValue
                    ? error.deviceId && "is-invalid"
                    : error.deviceId && "is-invalid"
                    }`}
                  name="deviceId"
                  id="Device Id"
                  value={deviceIdValue}
                  onChange={(e) => setDeviceIdValue(e.target.value)}
                  maxLength={21}
                />
                <div className="invalid-feedback">{error.deviceId}</div>
              </div>
              <div className="col-4">
                <label htmlFor="relievingDate" className="col-form-label">
                  Purchase Date
                </label>
                <br />
                <DatePicker
                  placeholderText="select Date"
                  className={`margin_remove_custom form-control  ${!purchaseValue && error.purchaseDate && "is-invalid"
                    }`}
                  name="purchaseDate"
                  selected={purchaseValue}
                  // value={ relievingDate? relievingDate: "" }
                  showYearDropdown
                  yearDropdownItemNumber={100}
                  scrollableYearDropdown
                  showMonthDropdown
                  useShortMonthInDropdown
                  onChange={(date) => setPurchaseValue(date)}
                  dateFormat="yyyy-MM-dd"
                />
                {!purchaseValue && error.purchaseDate && (
                  <p className="datePicker_validation_small">
                    {error.purchaseDate}
                  </p>
                )}
              </div>
              <div className="col-4">
                <label htmlFor="workStatus" className="col-form-label">
                  Work Status
                </label>
                <br />
                <select
                  className={`custm-form-control ${!workStatus && error.workStatus && "is-invalid"
                    }`}
                  name="workStatus"
                  id="workStatus"
                  value={workStatus}
                  onChange={(e) => setWorkStatus(e.target.value)}
                >
                  <option value="">Select Status</option>
                  <option value="Working"> Working </option>, ,
                  <option value="Not Working"> Not Working </option>
                  <option value="Physical Damage"> Physical Damage </option>
                </select>
                <div className="invalid-feedback">{error.workStatus}</div>
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-4">
                <label htmlFor="images" className="col-form-label">
                  Images
                </label>
                <input
                  type="file"
                  className={`form-control mt-0 ${images && error.images && "is-invalid"}`}
                  name="images"
                  id="images"
                  onChange={(e) => imageHandler(e.target.files)}
                  accept="image/*"
                  multiple
                />
                <div className="invalid-feedback">{error.images}</div>
              </div>
            </div>
            {multiimages.length > 0 && (
              <div className="image-preview">
                {multiimages.map((addimage, index) => (
                  <React.Fragment key={index}>
                    <div key={index} className="image-container">
                      <img
                        className="preview-image cursorNone"
                        src={URL.createObjectURL(addimage)}
                        alt={`Preview ${index}`}
                      />
                      <TiDelete
                        className="remove-button fs-1"
                        onClick={() => removeMultImage(index)}
                      />
                    </div>
                  </React.Fragment>
                ))}
              </div>
            )}
            {images.length > 0 && (
              <div className="image-preview">
                {images.map((image, index) => (
                  <React.Fragment key={index}>
                    <div key={index} className="image-container">
                      <img
                        className="preview-image"
                        src={image.url}
                        alt={`Preview ${index}`}
                        onClick={() => showModal(image.url)}
                      />
                      <TiDelete
                        className="remove-button fs-1"
                        onClick={() => removeImage(index, image._id)}
                      />
                    </div>
                  </React.Fragment>
                ))}
              </div>
            )}

            <div className="mt-3 text-center">
              <button
                className="butonColor"
                ref={buttonRef}
                onClick={updateInventory}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
{/* ----------show-image-in-modal--------------- */}
      <ModalBox
        show={showImageModal}
        onClose={() => setShowImageModal(false)}
        customizedFooter={
          <button
            className="btn btn-secondary"
            onClick={() => setShowImageModal(false)}
          >
            Close
          </button>
        }
      >
        <img
        className="preview-image"
          src={showImage}
          alt={showImage}
          onClick={() => setShowImageModal(false)} />
      </ModalBox>
    </>
  );
}

export default UpdateInventoryMobile;

import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import Pagination from "react-js-pagination";
import usePageLoader from "../../Hooks/UsePageLoader";
import useSearchBox from "../../Hooks/UseSearchBox";
import useButtonLoader from "../../Hooks/UseButtonLoader";
import { IoMdRefresh } from "react-icons/io";
import AssignPersonLaptop from "./AssignPersonLaptop";
import DeleteInventoryLaptop from "./DeleteInventoryLaptop";

const LaptopInventory = () => {
    const [searchTerm, setSearchTerm] = useState("");
    const searchValue = useSearchBox(searchTerm, 650);
    const [loding, show, hide] = usePageLoader();
    const [activePage, setActivePage] = useState(1);
    const [total, setTotal] = useState(1);
    const [inventoryList, setInventoryList] = useState("");
    const [category, setCategory] = useState("");
    const [workStatusSearch, setWorkStatusSearch] = useState("");

    const [deleteModal, setDeleteModal] = useState(false);
    const [assignModal, setAssignModal] = useState(false);
    const [assignId, setAssignId] = useState("");
    const [employeName, setEmployeName] = useState("");
    const [deleteId, setDeleteId] = useState("");




    const deleteHandler = (_id) => {
        setDeleteId(_id);
        setDeleteModal(true);
    };
    const assignPersonHandler = (_id, assignedTo) => {
        setAssignId(_id);
        setEmployeName(assignedTo);
        setAssignModal(true);
    }

    const refreshHandler = () => {
        setSearchTerm("");
        setWorkStatusSearch("")
    };

    //----------------Inventory-API--------------------------
    const fetchInventory = async () => {
        try {
            show();
            const token = localStorage.getItem("demo-token");
            const response = await axios.get(
                `${process.env.REACT_APP_SECRET_KEY}/inventory-list?search=${searchValue}&page=${activePage}&categoryId=${category}&workingStatus=${workStatusSearch}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (response.data.status === 200) {
                // console.log("responseMobileInventory",response.data.data.listing.listing)
                hide();
                const value = response.data.data.listing.listing;
                const categoryFilter = value.filter((data) => data.category.categoryName === "Laptop")
                // console.log("filterCategory", categoryFilter)
                setInventoryList(categoryFilter);
                // setAttribuit(extractedValues)
                setTotal(response.data.data.listing.total || 1);
            } else {
                hide();
                toast.error(response.data.message);
            }
        } catch (err) {
            hide();
            console.log("form", err);
        }
    };

    //----------------category-search-API--------------------------
    const fetchCategory = async () => {
        try {
            const token = localStorage.getItem("demo-token");
            const response = await axios.get(
                `${process.env.REACT_APP_SECRET_KEY}/master-data-category`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (response.data.status === 200) {
                const value = response.data.data.categorys
                const filterMobileId = value.filter((data) => data.categoryName === "Laptop")
                const findMobileId = filterMobileId.map((data) => data._id)
                setCategory(findMobileId[0]);
            }
        } catch (err) {
            console.log("form", err);
        }
    };

    useEffect(() => {
        fetchCategory()
    }, [])


    useEffect(() => {
        if (searchValue) {
            setActivePage(1)
        } else {
            setActivePage(activePage)
        }
        fetchInventory();
    }, [searchValue, activePage, category, workStatusSearch]);

    const attArr = ['Operating System', 'Model Name', 'Processor', 'RAM', 'SSD/HDD', 'Serial Number', 'Is Purchased', 'Accessories']
    // const attArr = ['Operating System', 'Screen Size', 'Model Name', 'Processor', 'RAM', 'SSD/HDD' ,'Mac Address','Serial Number','Is Purchased','Accessories']


    return (
        <>
            {loding}
            <div className="WrapperArea wrapper_area_cstm_employee">
                <div className="WrapperBox">
                    <div className="Small-Wrapper ">
                        <div className="wrapper_employee_flex d-flex">
                            <h4 className="Title">Laptop Inventory </h4>
                            <div className="Small-Wrapper cstm_wraper_btn borderNone">
                                <div className="cstmSearchBox">
                                    <Link to="/laptop-inventory/add-inventory-laptop">
                                        <i className="fa fa-plus mr-2" aria-hidden="true"></i>
                                        Add Laptop
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="Small-Wrapper cstm-small_wrapper_employee">
                            <div className="row">
                                <div className="col-lg-3 col-md-3">
                                    <form action="">
                                        <input
                                            className="input_style"
                                            type="text"
                                            placeholder="Search by device name or Id or Person Name..."
                                            onChange={(e) => setSearchTerm(e.target.value)}
                                            value={searchTerm}
                                        />
                                    </form>
                                </div>
                                <div className="col-lg-2 col-md-2">
                                    <select
                                        className="input_style"
                                        onChange={(e) => setWorkStatusSearch(e.target.value)}
                                        value={workStatusSearch}
                                    >
                                        <option value="">All Work Status</option>
                                        <option value="Working"> Working </option>
                                        <option value="Not Working"> Not Working </option>
                                        <option value="Physical Damage">Physical Damage</option>
                                    </select>
                                </div>
                                <div className="col-lg-1 col-md-1">
                                    <IoMdRefresh
                                        className="fs-4 input_style_refresh employeeRefress"
                                        role="button"
                                        onClick={refreshHandler}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="TableList">
                            <table>
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Device Id</th>
                                        <th>Allocated Person name</th>
                                        <th>Working Status</th>
                                        {attArr.map((attribute, index) => (
                                            <th key={index}>{attribute || "---"}</th>
                                        ))}
                                        <th>Action</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {inventoryList && inventoryList.length > 0 ? (
                                        inventoryList.map(({
                                            _id,
                                            category,
                                            status,
                                            assignedTo,
                                            deviceId,
                                            workingStatus,
                                            attributes, }, index) => (
                                            <tr key={index}>
                                                {/* <td>{++index}</td> */}
                                                <td>{(activePage - 1) * 50 + ++index}</td>
                                                <td>{deviceId ? deviceId : "---"}</td>
                                                <td>
                                                    {assignedTo?.name ? (
                                                        <span className="Cstm_definecolor">
                                                            {assignedTo.name}
                                                        </span>
                                                    ) : (
                                                        <span className="Custm_allocateColor">
                                                            Un allocated
                                                        </span>
                                                    )}
                                                </td>
                                                <td>
                                                    {workingStatus === "Not Working" ? (
                                                        <span className="Custm_allocateColorRed">
                                                            Not Working
                                                        </span>
                                                    ) : (
                                                        <span className="Cstm_definecolor">
                                                            {workingStatus || "----"}
                                                        </span>
                                                    )}
                                                </td>
                                                {/* Render other cells */}
                                                {attArr.map((mainAtt, attrIndex) => {
                                                    return (<React.Fragment key={attrIndex}>
                                                        <td >

                                                            {attributes.find((data) => data.name === mainAtt)?.value || "---"}
                                                        </td>
                                                    </React.Fragment>)
                                                })}
                                                <td>
                                                    <div className="dropdown wrap_cstm_dropdown_menu">
                                                        <button
                                                            className="btn btn-secondary cstm_btn_sceon_background"
                                                            type="button"
                                                            data-bs-toggle="dropdown"
                                                            aria-expanded="false"
                                                        >
                                                            <i className="fa fa-ellipsis-v"></i>
                                                        </button>
                                                        <ul className="dropdown-menu cstm_dropdown_edit">
                                                            <li>
                                                                <Link
                                                                    className="dropdown-item"
                                                                    to="#"
                                                                    onClick={() =>
                                                                        assignPersonHandler(_id, assignedTo)
                                                                    }
                                                                >
                                                                    <i className="fa fa-plus mx-3"></i>
                                                                    Assign Person
                                                                </Link>
                                                            </li>
                                                            <li>

                                                                <Link
                                                                    className="dropdown-item"
                                                                    to={`/laptop-inventory/update-inventory-laptop/${_id}`}
                                                                >
                                                                    <i className="fa fa-pencil-square-o mx-3"></i>
                                                                    Edit
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <Link
                                                                    className="dropdown-item"
                                                                    to={`/laptop-inventory/view-inventory-laptop/${_id}`}
                                                                >
                                                                    <i className="fa fa-eye mx-3"></i>View
                                                                </Link>

                                                            </li>
                                                            <li>
                                                                <Link
                                                                    className="dropdown-item"
                                                                    onClick={() => deleteHandler(_id)}
                                                                    to="#"
                                                                >
                                                                    <i
                                                                        className="fa fa-trash-o mx-3"
                                                                        aria-hidden="true"
                                                                    ></i>
                                                                    Delete
                                                                </Link>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan={13}>Not Found</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                        {total >= 50 ? (
                            <nav
                                aria-label="Page navigation example"
                                className="mt-3 cstm_pagination"
                            >
                                <Pagination
                                    previousLabel={"prev"}
                                    nextLabel={"next"}
                                    // breakLabel={'...'}
                                    pageCount={activePage}
                                    pageRangeDisplayed={5}
                                    itemsCountPerPage={50}
                                    totalItemsCount={total}
                                    itemClass={"page-item"}
                                    linkClass={"page-link"}
                                    activeLinkClass={"active"}
                                    activePage={activePage}
                                    onChange={(page) => setActivePage(page)}
                                />
                            </nav>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
            </div>
            <AssignPersonLaptop
                assignId={assignId}
                assignModal={assignModal}
                modalClose={() => setAssignModal(false)}
                listing={fetchInventory}
                employeName={employeName}
            />
            <DeleteInventoryLaptop
                deleteId={deleteId}
                deleteModal={deleteModal}
                modalClose={() => setDeleteModal(false)}
                listing={fetchInventory}
            />
        </>
    );
}

export default LaptopInventory
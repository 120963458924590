import React, { useState } from 'react'
import { NavLink } from 'react-router-dom';

const SubMenu = ({name,icon, path,isChild }) => {
    const [open, setOpen] = useState(true);


    const handleClick = () => {
        setOpen(!open);
    };
    return (
        <li className="">
        <ul onClick={handleClick} className="masterData">
          <li>
            <div>
              <span className=" me-2">
                <i className={icon}></i>
              </span>
              {name}
              {open ? (
                <i className="fa fa-caret-up ml-auto"></i>
              ) : (
                <i className="fa fa-caret-down ml-auto"></i>
              )} 
            </div>
          </li>
        </ul>
        {open && (
          <ul className="cstm_ul_resource">
            {isChild.map(({ id, icon, name, path }) => (
              <li key={id} className="">
                <NavLink

                  to={path}
                  style={({ isActive }) => {
                    return {
                      // color: isActive ? "white" : "#222",
                      // backgroundColor: isActive ? "#3e3ed9" : "#fff",
                    };
                  }}
                >
                  <span className="">
                    <i className={icon}></i>
                  </span>
                  {name}
                </NavLink>
              </li>
            ))}
          </ul>
        )}
      </li>
  )
}

export default SubMenu
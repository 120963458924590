import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

function InventoryHistoryMobile({ inventoryId, employeeId }) {
  const [history, setHistory] = useState("");

  //----------------fetch-inventory-history-API--------------------------
  const fetchInventoryHistory = async () => {
    try {
      const token = localStorage.getItem("demo-token");
      const response = await axios.get(
        `${process.env.REACT_APP_SECRET_KEY}/inventory-History/${inventoryId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log("inventoryHistory", response.data.data.history);
      if (response.data.status === 200) {
        setHistory(response.data.data.history);
      } else {
        toast.error(response.data.message);
      }
    } catch (err) {
      console.log("form", err);
    }
  };

  useEffect(() => {
    fetchInventoryHistory();
  }, [employeeId]);
  return (
    <>
      <h4 className="Title mt-5">Inventory History</h4>

      <div className="TableList">
        <table>
          <thead>
            <tr>
              <th>S.No</th>
              <th>Employee Name</th>
              <th>Working Status</th>
              <th>Assignment Date</th>
              <th>Return Date</th>
            </tr>
          </thead>

          <tbody>
            {history.length > 0 ? (
              history.map(
                (
                  {
                    _id,
                    assignedTo,
                    inventory,
                    returnDate,
                    workingStatus,
                    assignmentDate,
                  },
                  index
                ) => {
                  return (
                    <React.Fragment key={_id}>
                      <tr>
                        <td>{++index}</td>
                        <td>{assignedTo ? assignedTo?.name : "---"}</td>
                        <td>{inventory?.workingStatus || "---"}</td>
                        <td>
                          {(inventory && moment(inventory?.assignmentDate).format("DD-MM-YYYY")) || "---"}
                        </td>
                        <td>
                          {(returnDate && moment(returnDate).format("DD-MM-YYYY")) || "---"}
                        </td>
                      </tr>
                    </React.Fragment>
                  );
                }
              )
            ) : (
              <tr>
                <td colSpan={5}>Not Found</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default InventoryHistoryMobile;

import React, { useState } from "react";
import useButtonLoader from "../../../Hooks/UseButtonLoader";
import axios from "axios";
import ModalBox from "../../../Modals/ModalBox";
import { toast } from "react-toastify";

function AddDepartment({ modal, modalClose, listing }) {
  const [buttonRef, loding] = useButtonLoader("Submit");
  const [addDepartment, setAddDepartment] = useState({
    departmentName: "",
  });
  const [error, setError] = useState({});

  const validation = () => {
    let errorMessage = {
      name: "",
    };
    let status = true;

    if (!addDepartment.departmentName) {
      errorMessage["name"] = "Please Enter Department Name";
      status = false;
    }
    setError(errorMessage);
    return status;
  };

  const onChangeHandler = (event) => {
    const { name, value } = event.target;
    setAddDepartment({ ...addDepartment, [name]: value });
  };

  const addDepartments = async () => {
    const status = validation();
    if (status) {
      try {
        loding(true);
        const token = localStorage.getItem("demo-token");
        const response = await axios.post(
          `${process.env.REACT_APP_SECRET_KEY}/department-create`,
          addDepartment,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        // console.log("AddCatogry", response);
        if (response.data.status === 200) {
          loding(false);
          modalClose();
          listing();
          toast.success(response.data.message);
        } else {
          loding(false);
          toast.error(response.data.message);
        }
      } catch (error) {
        loding(false);
        console.log(error);
      }
    }
  };

  return (
    <div>
      <ModalBox
        show={modal}
        onClose={() => {
          modalClose();
          setAddDepartment("");
          setError("");
        }}
        heading={"Add Department"}
        customizedFooter={
          <div>
            <button
              className="btn btn-secondary modalButtonClose mx-2"
              onClick={() => {
                modalClose();
                setAddDepartment("");
                setError("");
              }}
            >
              close
            </button>
            <button
              className="modalButtonYes btn btn-primary modalButtonSubmit"
              onClick={addDepartments}
              ref={buttonRef}
            >
              Submit
            </button>
          </div>
        }
      >
        <div className="row">
          <label htmlFor="inputName" className="fs-6">
            Add Department
          </label>
          <div className="rounded fs-6">
            <input
              type="text"
              className={`form-control ${
                !addDepartment.departmentName && error.name && "is-invalid"
              }`}
              name="departmentName"
              id="inputName"
              placeholder="Please enter department Name"
              // value={addCategory.financeCompanyType}
              onChange={onChangeHandler}
              maxLength={100}
            />
            <div className="invalid-feedback">{error.name}</div>
          </div>
        </div>
      </ModalBox>
    </div>
  );
}

export default AddDepartment;

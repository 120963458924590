import React, { useState } from "react";
import ModalBox from "../../Modals/ModalBox";
import moment from "moment";
import axios from "axios";
import { toast } from "react-toastify";
import useButtonLoader from "../../Hooks/UseButtonLoader";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function ActiveMemberResource({ projectDetails, listing, resourceDeatils }) {
  const [isRelease, setIsRelease] = useState("");
  const [modal, setModal] = useState("");
  const [memberId, setMemberId] = useState("");
  const [buttonRef, loading] = useButtonLoader("Yes");
  const [date, setDate] = useState("");
  const [error, setError] = useState({});
  const [projectName, setProjectName] = useState("");

  const releseValue =
    projectDetails.length > 0 &&
    projectDetails.filter(({ isReleaseinProject }) => {
      return isReleaseinProject === false;
    });

  const validation = () => {
    let errorMessage = {
      date: "",
    };
    let status = true;
    if (!date) {
      errorMessage["date"] = "This field is required ";
      status = false;
    }
    setError(errorMessage);
    return status;
  };
  const releaseProjectMember = async (e) => {
    e.preventDefault();
    let status = validation();
    const dateFormat = date && moment(date).format("YYYY-MM-DD");
    if (status) {
      try {
        loading(true);
        const token = localStorage.getItem("demo-token");
        const response = await axios.put(
          `${process.env.REACT_APP_SECRET_KEY}/release-member/${memberId}`,
          {
            isRelease: !isRelease,
            releaseDate: dateFormat,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.data.status === 200) {
          loading(false);
          setModal(false);
          listing();
          setDate("");
          toast.success(response.data.message);
        } else {
          loading(false);
          // toast.error(response.data.message);
        }
      } catch (error) {
        loading(false);
        console.log(error);
      }
    }
  };

  const statusHandler = (memberId, isReleaseinProject, projectName) => {
    setIsRelease(isReleaseinProject);
    setMemberId(memberId);
    setProjectName(projectName);
    setModal(true);
  };

  return (
    <>
      <div className="">
        <div className=" mt-2">
          <h4 className="Title">Active Project </h4>
          <div className="TableList">
            <table>
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Project name</th>
                  <th>Allocation type</th>
                  <th>Start date</th>
                  <th>End date</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {releseValue.length > 0 ? (
                  releseValue.map(
                    (
                      {
                        projectId,
                        memberId,
                        projectName,
                        totalWorkingHoursInProject,
                        startDateinProject,
                        endDateinProject,
                        isReleaseinProject,
                      },
                      index
                    ) => {
                      return isReleaseinProject === false ? (
                        <React.Fragment key={index}>
                          <tr>
                            <td>{++index}</td>
                            <td>{projectName ? projectName : `---`}</td>
                            <td>
                              {totalWorkingHoursInProject
                                ? totalWorkingHoursInProject
                                : `---`}
                            </td>
                            <td>
                              {startDateinProject
                                ? moment(startDateinProject).format(
                                    "DD-MM-YYYY"
                                  )
                                : `---`}
                            </td>
                            <td>
                              {endDateinProject
                                ? moment(endDateinProject).format("DD-MM-YYYY")
                                : `---`}
                            </td>
                            <td>
                              <button
                                className="releseButton"
                                onClick={() =>
                                  statusHandler(
                                    memberId,
                                    isReleaseinProject,
                                    projectName
                                  )
                                }
                              >
                                Relese
                              </button>
                            </td>
                          </tr>
                        </React.Fragment>
                      ) : (
                        ""
                      );
                    }
                  )
                ) : (
                  <tr>
                    <td colSpan={7}>Not Found</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        <ModalBox
          show={modal}
          onClose={() => {
            setModal(false);
            setDate("");
            setError("")
          }}
          heading={"Release Member"}
          action={releaseProjectMember}
          buttonRef={buttonRef}
        >
          <div className="modalBoxSize">
            {/* Are you sure? */}
            Do you want to release
            {/* Amresh Pandey from PET Play project? */}
            <strong> {resourceDeatils.name}</strong> from
            <strong> {projectName} </strong> project?
          </div>
          <div className="">
            <label htmlFor="date" className="releseDtae">
              Select Date
            </label>
            <br />
            <DatePicker
              placeholderText="Select relese Date"
              className={`form-control deliveryDatepicker ${
                !date && error.date && "is-invalid"
              }`}
              selected={date}
              value={date}
              name="date"
              id="date"
              showYearDropdown
              yearDropdownItemNumber={100}
              scrollableYearDropdown
              showMonthDropdown
              useShortMonthInDropdown
              onChange={(date) => setDate(date)}
              dateFormat="yyyy-MM-dd"
              // maxDate={new Date()}
            />
            {!date && error.date && (
              <p className="datePicker_validation_large">{error.date}</p>
            )}
          </div>
        </ModalBox>
      </div>
    </>
  );
}

export default ActiveMemberResource;

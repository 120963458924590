import moment from 'moment';
import React from 'react'

function ReleaseMemberResource({projectDetails}) {

  const releaseData =
  projectDetails.length > 0 &&
  projectDetails.filter(({isReleaseinProject }) => {
    return isReleaseinProject === true;
  });
  return (
    <>
      <div className="">
        <div className=" mt-5">
          <h4 className="Title"> Release member </h4>
          <div className="TableList">
            <table>
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Project name</th>
                  {/* <th>Name</th> */}
                  <th>Allocation type</th>
                  <th>Start date</th>
                  <th>End date</th>
                  <th>Relsase date</th>
                </tr>
              </thead>
              <tbody>
                {releaseData.length > 0 ? (
                  releaseData.map(
                    (
                      {
                        _id,
                        projectName,
                        employee,
                        totalWorkingHoursInProject,
                        isRelease,
                        startDateinProject,
                        endDateinProject,
                        releaseDateinProject,
                      },
                      index
                    ) => {
                      return (
                        <React.Fragment key={index}>
                          <tr>
                            <td>{++index}</td>
                            <td>{projectName || "---"}</td>
                            {/* <td>{employee.name}</td> */}
                            <td>{totalWorkingHoursInProject || "---"}</td>
                            <td>{startDateinProject?moment(startDateinProject).format("DD-MM-YYYY"):`---`}</td>
                            <td>{endDateinProject?moment(endDateinProject).format("DD-MM-YYYY"):`---`}</td>
                            <td>{releaseDateinProject?moment(releaseDateinProject).format("DD-MM-YYYY"):`---`}</td>
                          </tr>
                        </React.Fragment>
                      );
                    }
                  )
                ) : (
                  <tr>
                    <td colSpan={7}>Not Found</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  )
}

export default ReleaseMemberResource

import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function Dashboard() {
  const [dateInput, setInputData] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    setInputData({ ...dateInput, [name]: value });
    console.log(name, value);
  };

  return (
    <>
      <div className="WrapperArea">
        <div className="WrapperBox">
          <div className="Small-Wrapper">
            <div className="wrapper_employee_flex d-flex">
              <h4 className="Title">Dashboard</h4>
              <div className="Small-Wrapper cstm_wraper_btn borderNone">
                <div className="cstmSearchBox">
                  <button
                    className="butonColor mx-2"
                  // onClick={
                  //     dateValue.form_date && dateValue.to_date
                  //         ? () => fetchDashboardList(
                  //             dateValue.form_date,
                  //             dateValue.to_date
                  //         ) : null
                  // }
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
            <div className="Small-Wrapper cstm-small_wrapper_employee">
              <div className="row">
                <div className="col-lg-3 col-md-3">
                  <DatePicker
                    placeholderText="From Date"
                    className="input input_style dataPickerWidth"
                    selected={startDate}
                    value={startDate}
                    showYearDropdown
                    yearDropdownItemNumber={100}
                    scrollableYearDropdown
                    showMonthDropdown
                    useShortMonthInDropdown
                    onChange={(date) => setStartDate(date)}
                    dateFormat="yyyy-MM-dd"
                    maxDate={new Date(endDate)}
                  />
                </div>
                <div className="col-lg-3 col-md-3">
                <DatePicker
                      placeholderText="To Date"
                      className="input input_style dataPickerWidth"
                      selected={endDate}
                      value={endDate}
                      showYearDropdown
                      yearDropdownItemNumber={100}
                      scrollableYearDropdown
                      showMonthDropdown
                      useShortMonthInDropdown
                      onChange={(date) => setEndDate(date)}
                      dateFormat="yyyy-MM-dd"
                      minDate={new Date(startDate)}
                    />
                </div>
              </div>
            </div>

            <div className="DashboardArea">
              <div className="DashboardFilter">
                <div className="clear"></div>
              </div>

              <div className="row">
                {/* {dashboardValue && dashboardValue.map(([key, value]) => {
                                    return (
                                        <>
                                            <div className="col-sm-4 my-2">
                                                <aside>
                                                    <h4>{key.split(/(?=[A-Z])/).join(' ')}</h4>
                                                    <h2 style={{ color: 'rgb(255,147,22)' }}>{value}</h2>
                                                </aside>
                                            </div>
                                        </>
                                    )
                                })} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;

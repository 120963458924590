import React, { useState } from "react";
import useButtonLoader from "../../../Hooks/UseButtonLoader";
import axios from "axios";
import { toast } from "react-toastify";
import ModalBox from "../../../Modals/ModalBox";

function AddTechnology({ addModal, modalClose, listing }) {
  const [buttonRef, loding] = useButtonLoader("Submit");
  const [addTechnology, setAddTechnology] = useState("");
  const [error, setError] = useState({});

  const validation = () => {
    let errorMessage = {
      name: "",
    };
    let status = true;

    if (!addTechnology) {
      errorMessage["name"] = "Please Enter technology name";
      status = false;
    }
    setError(errorMessage);
    return status;
  };

  const addTechnologyApi = async () => {
    const status = validation();
    if (status) {
      try {
        loding(true);
        const token = localStorage.getItem("demo-token");
        const response = await axios.post(
          `${process.env.REACT_APP_SECRET_KEY}/add-technology`,
          addTechnology,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.data.status === 200) {
          loding(false);
          modalClose();
          listing();
          toast.success(response.data.message);
        } else {
          loding(false);
          toast.error(response.data.message);
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    setAddTechnology({ ...addTechnology, [name]: value });
    // console.log(name,value)
  };
  return (
    <div>
      <ModalBox
        show={addModal}
        onClose={() => {
          modalClose();
          setAddTechnology("");
          setError("");
        }}
        heading={"Add Technology"}
        customizedFooter={
          <div>
            <button
              className="btn btn-secondary modalButtonClose mx-2"
              onClick={() => {
                modalClose();
                setAddTechnology("");
                setError("");
              }}
            >
              close
            </button>
            <button
              className="modalButtonYes btn btn-primary modalButtonSubmit"
              onClick={addTechnologyApi}
              ref={buttonRef}
            >
              Submit
            </button>
          </div>
        }
      >
        <div className="row">
          <label htmlFor="inputName" className="fs-6 my-2">
            Add Technology
          </label>
          <div className="rounded fs-6">
            <input
              type="text"
              className={`form-control ${
                !addTechnology && error.name && "is-invalid"
              }`}
              name="technologyName"
              id="inputName"
              placeholder="Please enter technology Name"
              onChange={onChangeHandler}
              maxLength={18}
            />
            <div className="invalid-feedback">{error.name}</div>
          </div>
        </div>
      </ModalBox>
    </div>
  );
}

export default AddTechnology;

import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import usePageLoader from "../../../Hooks/UsePageLoader";
import moment from "moment";
import MilestoneView from "./MilestoneView";
import ActiveMember from "./ActiveMember";
import ReleaseMember from "./ReleaseMember";
import AddPdf from "../AddProjectManagement/AddPdf";

function ViewProject() {
  const [loder, show, hide] = usePageLoader();
  const param = useParams();
  const { userId } = param;
  const [projectDeatils, setProjectDetails] = useState("");
  const [milestones, setMilestone] = useState("");
  const [members, setMembers] = useState([]);
  const [pdf, setPdf] = useState("");
  const [importModal, setImportModal] = useState(false);
  //----------------------get-Project-Deatils-------------------
  const getProjectDeatils = async () => {
    try {
      show();
      const token = localStorage.getItem("demo-token");
      const response = await axios.get(
        `${process.env.REACT_APP_SECRET_KEY}/project-detailes/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log("view Project", response.data.data);
      if (response.data.status === 200) {
        hide();
        setProjectDetails({
          ...response.data.data.project,
          projectManager: response.data.data.project.projectManager.name,
          startDate: moment(response.data.data.project.startDate).format(
            "DD-MM-YYYY"
          ),
          endDate: moment(response.data.data.project.endDate).format(
            "DD-MM-YYYY"
          ),
        });
        setPdf(response.data.data.pdfUrl);
        setMilestone(response.data.data.milestones);
        setMembers(response.data.data.members);
      } else {
        toast.error(response.data.message);
        hide();
      }
    } catch (err) {
      console.log("form", err);
    }
  };

  useEffect(() => {
    getProjectDeatils();
  }, []);
  return (
    <>
      {loder}
      <div className="WrapperArea">
        <div className="WrapperBox">
          <div className="Small-Wrapper">
            <h4 className="Title">
              <span>
                <Link to="/project-management">
                  <i className="fa fa-arrow-left" aria-hidden="true"></i>
                </Link>
              </span>
              Project Details
            </h4>
            <div className="OrderArea">
              <ul>
                <li>
                  <span>Project name</span>
                  {projectDeatils.projectName || `---`}
                </li>
                <li>
                  <span>Manager Name</span>{" "}
                  {projectDeatils.projectManager || `---`}{" "}
                </li>
                <li>
                  <span>Client name</span>
                  {projectDeatils.clientName || `---`}{" "}
                </li>

                <li>
                  <span>Project status</span>
                  {projectDeatils.projectStatus || `---`}{" "}
                </li>
                <li>
                  <span>Start date</span>
                  {projectDeatils.startDate || `---`}{" "}
                </li>
                <li>
                  <span>End date</span>
                  {projectDeatils.endDate || `---`}{" "}
                </li>
                 <li>
                  <span>Pdf version</span>
                  {projectDeatils.version || `---`}{" "}
                </li>
              <li>
                <span>Pdf</span>
                {pdf ? (
                  <span className="cstm_remove_style" >
                    <a href={pdf} target="_blank">
                      <i className="fa fa-download mr-2" aria-hidden="true"></i>
                      Download pdf
                    </a>
                    <button
                      className="pdfViewButton mx-4"
                      onClick={() => setImportModal(true)} >
                      Update pdf
                    </button>
                  </span>
                ) : (
                  <button
                      className="pdfViewButton cstmpdf_view_btn"
                      onClick={() => setImportModal(true)}>
                      Add pdf
                    </button>
                )}
              </li>
               
              </ul>
            </div>
            <MilestoneView
              milestones={milestones}
              projectDeatils={projectDeatils}
            />
            <ActiveMember
              members={members}
              listing={getProjectDeatils}
              projectDeatils={projectDeatils}
            />

            <ReleaseMember members={members} />
          </div>
        </div>
      </div>
      <AddPdf
        importModal={importModal}
        setImportModal={setImportModal}
        projectId={projectDeatils._id}
        listing={getProjectDeatils}
      />
    </>
  );
}

export default ViewProject;

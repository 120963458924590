import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { toast } from "react-toastify";
import usePageLoader from "../../Hooks/UsePageLoader";
import useSearchBox from "../../Hooks/UseSearchBox";
import moment from "moment";
import { IoMdRefresh } from "react-icons/io";
import Pagination from "react-js-pagination";
function ProjectManagement() {
  const [loding, show, hide] = usePageLoader();
  const [projectList, setProjectList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const searchValue = useSearchBox(searchTerm, 650);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [projectStatusSearch, setProjectStatusSearch] = useState("");
  const [projectTypeSearch, setProjectTypeSearch] = useState("");
  const [activePage, setActivePage] = useState(1);
  const [total, setTotal] = useState("");

  const [sortByProjectNameAsc, setSortByProjectNameAsc] = useState(true);
  const [serchType, setSearchType] = useState();
  const [type, setType] = useState("");

  const sortByProjectName = () => {
    console.log("hello1");
    const newSortOrder = !sortByProjectNameAsc;
    setSortByProjectNameAsc(newSortOrder);
    setType("projectName");
    setSearchType(sortByProjectNameAsc); /*  */
    fetchProjectList();
  };
  //-------Render-the-column-header-with-sorting-arrows------------
  const renderProjectNameColumnHeader = () => (
    // console.log("hello"),
    <th
      className="cstm_th_style cstm_width_style"
      onClick={sortByProjectName}
      style={{ cursor: "pointer" }}
    >
      Project Name
      {!sortByProjectNameAsc ? (
        <i
          className="fa fa-angle-down cstm-arrow-drop cstm_arrow_drop_2"
          aria-hidden="true"
        ></i> //ASC
      ) : (
        <i
          className="fa fa-angle-up cstm-arrow-drop cstm_arrow_drop_2"
          aria-hidden="true"
        ></i> //DESC
      )}
    </th>
  );

  const fetchProjectList = async () => {
    try {
      show();
      const token = localStorage.getItem("demo-token");
      let apiStartDate = startDate
        ? moment(startDate).format("YYYY-MM-DD")
        : "";
      let apiEndDate = endDate ? moment(endDate).format("YYYY-MM-DD") : "";
      const response = await axios.get(
        `${process.env.REACT_APP_SECRET_KEY
        }/get-projects?search=${searchValue}&startDate=${apiStartDate}&endDate=${apiEndDate}&status=${projectStatusSearch}&type=${projectTypeSearch}&page=${activePage}&sort=${type}&sortDirection=${serchType ? "asc" : "DESC"
        }`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log("projectManagement", response.data.data);
      if (response.data.status === 200) {
        setProjectList(response.data.data.data);
        setTotal(response.data.data.totalCount);
        hide();
      } else {
        hide();
        toast.error(response.data.message);
      }
    } catch (err) {
      hide();
      console.log("form", err);
    }
  };

  const searchHandler = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleStartDate = (date) => {
    setStartDate(date);
  };

  const handleEndDate = (date) => {
    setEndDate(date);
  };

  const refreshHandler = () => {
    setSearchTerm("");
    setStartDate("");
    setEndDate("");
    setProjectStatusSearch("");
    setProjectTypeSearch("");
    fetchProjectList();
  };
  useEffect(() => {
    if (searchValue) {
      setActivePage(1)
    } else {
      setActivePage(activePage)
    }
    fetchProjectList();
  }, [searchValue, projectStatusSearch, projectTypeSearch, activePage]);

  useEffect(() => {
    if (startDate && endDate) {
      fetchProjectList();
    }
  }, [startDate, endDate]);

  return (
    <>
      {loding}
      <div className="WrapperArea wrapper_area_cstm_employee">
        <div className="WrapperBox">
          <div className="Small-Wrapper">
            <div className="wrapper_employee_flex d-flex">
              <h4 className="Title">Project Management</h4>
              <div className="Small-Wrapper cstm_wraper_btn borderNone">
                <div className="cstmSearchBox">
                  <Link
                    className="butonColor addButtonPM"
                    to="/project-management/add-project"
                  >
                    <i className="fa fa-plus mr-2" aria-hidden="true"></i>
                    Add Project
                  </Link>
                </div>
              </div>
            </div>
            <div className="Small-Wrapper cstm-small_wrapper_employee">
              <div className="row">
                <div className="col-lg-3 col-md-3">
                  <form action="">
                    <input
                      className="input_style"
                      type="text"
                      placeholder="Search by project name or client name... "
                      onChange={searchHandler}
                      value={searchTerm}
                    />
                  </form>
                </div>
                <div className="col-lg-2 col-md-2">
                  <DatePicker
                    placeholderText="Start Date"
                    className="input input_style dataPickerWidth"
                    selected={startDate}
                    value={startDate}
                    showYearDropdown
                    yearDropdownItemNumber={100}
                    scrollableYearDropdown
                    showMonthDropdown
                    useShortMonthInDropdown
                    onChange={handleStartDate}
                    dateFormat="yyyy-MM-dd"
                    maxDate={endDate}
                  />
                </div>
                <div className="col-lg-2 col-md-2">
                  <div className="form-field date-picker">
                    <DatePicker
                      placeholderText="End Date"
                      className="input input_style dataPickerWidth"
                      selected={endDate}
                      value={endDate}
                      showYearDropdown
                      yearDropdownItemNumber={100}
                      scrollableYearDropdown
                      showMonthDropdown
                      useShortMonthInDropdown
                      onChange={handleEndDate}
                      dateFormat="yyyy-MM-dd"
                      minDate={startDate}
                    />
                  </div>
                </div>
                <div className="col-lg-2 col-md-2">
                  <select
                    className="input_style"
                    onChange={(e) => setProjectStatusSearch(e.target.value)}
                    value={projectStatusSearch}
                  >
                    <option value="">All Status</option>
                    <option value="Yet to start">Yet to start</option>
                    <option value="In Progress">In Progress</option>
                    <option value="Hold">Hold</option>
                    <option value="Completed">Completed</option>
                    <option value="Terminated">Terminated</option>
                    <option value="Abscond">Abscond</option>
                  </select>
                </div>
                <div className="col-lg-2 col-md-2">
                  <select
                    className="input_style"
                    onChange={(e) => setProjectTypeSearch(e.target.value)}
                    value={projectTypeSearch}
                  >
                    <option value="">All Project Type</option>
                    <option value="Fixed Cost">Fixed Cost</option>
                    <option value="T %26 M">T & M</option>
                  </select>
                </div>
                <div className="col-lg-1 col-md-1">
                  <IoMdRefresh
                    className="fs-4 input_style_refresh employeeRefress"
                    role="button"
                    onClick={refreshHandler}
                  />
                </div>
              </div>
            </div>
            <div className="TableList">
              <table>
                <thead>
                  <tr>
                    <th>S.No</th>
                    {renderProjectNameColumnHeader()}
                    <th>Project Type</th>
                    <th>Project Manager</th>
                    <th>Client Name</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                    <th>Status</th>
                    <th>action</th>
                  </tr>
                </thead>
                <tbody>
                  {projectList.length > 0 ? (
                    projectList.map(
                      (
                        {
                          projectName,
                          projectType,
                          clientName,
                          endDate,
                          startDate,
                          updatedAt,
                          createdAt,
                          isDelete,
                          projectManager,
                          projectStatus,
                          _id,
                        },
                        index
                      ) => {
                        return (
                          <React.Fragment key={_id}>
                            <tr>
                              <td>{(activePage - 1) * 50 + ++index}</td>
                              <td>{projectName}</td>
                              <td>{projectType}</td>
                              <td>{projectManager?.name}</td>
                              <td>{clientName}</td>
                              <td>{moment(startDate).format("DD-MM-YYYY")}</td>
                              <td>{moment(endDate).format("DD-MM-YYYY")}</td>
                              <td>{projectStatus}</td>
                              <td>
                                <div className="dropdown wrap_cstm_dropdown_menu">
                                  <button
                                    className="btn btn-secondary cstm_btn_sceon_background"
                                    type="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <i className="fa fa-ellipsis-v"></i>
                                  </button>
                                  <ul className="dropdown-menu cstm_dropdown_edit">
                                    <li>
                                      <Link
                                        className="dropdown-item"
                                        to={`/project-management/update-project/${_id}`}
                                      >
                                        <i className="fa fa-pencil-square-o mx-3"></i>
                                        Edit
                                      </Link>
                                    </li>
                                    <li>
                                      <Link
                                        className="dropdown-item"
                                        to={`/project-management/view-project/${_id}`}
                                      >
                                        <i className="fa fa-eye mx-3"></i>View
                                      </Link>
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                          </React.Fragment>
                        );
                      }
                    )
                  ) : (
                    <tr>
                      <td colSpan={9}>Not Found</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            {total >= 50 ? (
              <nav
                aria-label="Page navigation example"
                className="mt-3 cstm_pagination"
              >
                <Pagination
                  previousLabel={"prev"}
                  nextLabel={"next"}
                  // breakLabel={'...'}
                  pageCount={activePage}
                  pageRangeDisplayed={5}
                  itemsCountPerPage={50}
                  totalItemsCount={total}
                  itemClass={"page-item"}
                  linkClass={"page-link"}
                  activeLinkClass={"active"}
                  activePage={activePage}
                  onChange={(page) => setActivePage(page)}
                />
              </nav>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default ProjectManagement;

import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { Link, useNavigate, useParams } from "react-router-dom";
import useButtonLoader from "../../Hooks/UseButtonLoader";
import usePageLoader from "../../Hooks/UsePageLoader";
import Select from "react-select";

function UpdateAccess() {
  const { userId } = useParams();
  const [employee, setEmployee] = useState([]);
  const [employeeOnhange, setEmployeeOnChange] = useState("");
  const [roleValue, setRoleValue] = useState({});
  const [projectLabel, setProjectLabel] = useState([]);
  const [buttonref, loading] = useButtonLoader("Submit");
  const navigator = useNavigate();
  const [loding, show, hide] = usePageLoader();
  const [error, setError] = useState({});

  // console.log("employeeOnhange", employeeOnhange);

  const validation = () => {
    let errorMessage = {};
    let status = true;

    if (!employeeOnhange) {
      errorMessage["employee"] = "Please select Employe";
      status = false;
    }
    setError(errorMessage);
    return status;
  };
  //------------role-value-onChange-cehecked-unCheecked------------
  const roleOnChange = (value, id) => {
    if (roleValue[id]?.includes(value)) {
      const updatedArr = roleValue[id].filter((item) => item !== value);
      setRoleValue({ ...roleValue, [id]: updatedArr });
    } else {
      let newArr = roleValue[id] ? [...roleValue[id]] : [];
      newArr.push(value);
      setRoleValue({ ...roleValue, [id]: newArr });
    }
  };

  //-----------------get-Access-value-API----------------------
  const getAccessValue = async () => {
    try {
      show();
      const token = localStorage.getItem("demo-token");
      const response = await axios.get(
        `${process.env.REACT_APP_SECRET_KEY}/get-single-permission/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log("updateAccess", response.data.data.permission);
      if (response.data.status === 200) {
        hide();
        const accessData = response.data.data.permission.menues;
        const employee = response.data.data.permission.employeeId;
        const defaultOption = employee?._id
          ? { value: employee._id, label: employee?.name }
          : "";
        setEmployeeOnChange(defaultOption);
        let roleObj = {};
        accessData.forEach((data) => {
          Object.assign(roleObj, { [data.menueId]: data.role });
        });
        setRoleValue(roleObj);
        // const tem = accessData.find((data)=>data.)
      } else {
        toast.error(response.data.message);
        hide();
      }
    } catch (err) {
      console.log("form", err);
    }
  };
  //---------------get-employee-API--------------------------
  const fetchEmployee = async () => {
    try {
      const token = localStorage.getItem("demo-token");
      const response = await axios.get(
        `${process.env.REACT_APP_SECRET_KEY}/master-data-employee`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      //   console.log("employeeAccess",response.data.data.employees )
      if (response.data.status === 200) {
        setEmployee(response.data.data.employees);
      } else {
        toast.error(response.data.message);
      }
    } catch (err) {
      console.log("form", err);
    }
  };
  //---------------get-Department-name-API--------------------------
  const fetchDepartment = async () => {
    try {
      show();
      const token = localStorage.getItem("demo-token");
      const response = await axios.get(
        `${process.env.REACT_APP_SECRET_KEY}/get-menue`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log("employeeAccess", response.data.data);
      if (response.data.status === 200) {
        setProjectLabel(response.data.data);
        hide();
      } else {
        hide();
        toast.error(response.data.message);
      }
    } catch (err) {
      console.log("form", err);
      hide();
    }
  };
  //---------------Update-Access-management-API--------------------------
  const updateAccess = async (e) => {
    e.preventDefault();
    let status = validation();
    if (status) {
      try {
        loading(true);
        const token = localStorage.getItem("demo-token");
        const response = await axios.put(
          `${process.env.REACT_APP_SECRET_KEY}/update-permission/${userId}`,
          {
            employeeId: employeeOnhange.value,
            menues: projectLabel
              .filter((data) => roleValue[data._id]?.length > 0) // Filter menus based on non-empty role values
              .map((data) => ({
                menueId: data._id,
                role: roleValue[data._id],
              })),
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.data.status === 200) {
          loading(false);
          toast.success(response.data.message);
          navigator("/access-Management");
        } else {
          loading(false);
          toast.error(response.data.message);
        }
      } catch (err) {
        loading(false);
        console.log(err);
      }
    }
  };

  useEffect(() => {
    fetchEmployee();
    fetchDepartment();
    getAccessValue();
  }, []);
  return (
    <>
      {loding}
      <div className="WrapperArea">
        <div className="WrapperBox">
          <div className="Small-Wrapper borderRadius">
            <h4 className="Title">
              <span>
                <Link to="/access-Management">
                  <i className="fa fa-arrow-left" aria-hidden="true"></i>
                </Link>
              </span>
              Update Access
            </h4>
            <div className="container mt-4">
              <form className="needs-validation">
                <div className="row">
                  <div className="col-lg-6 col-md-6">
                    <label htmlFor="employee" className="col-form-label">
                      Select Employee
                    </label>
                    <br />
                    <Select
                      className={`form-control ${
                        !employeeOnhange && error.employee && "is-invalid"
                      }`}
                      name="employee"
                      id="employee"
                      onChange={(e) => setEmployeeOnChange(e)}
                      placeholder="Search...."
                      // onChange={(e) => setAssignValue(e)}
                      value={employeeOnhange}
                      options={employee.map(({ _id, name }) => ({
                        value: _id,
                        label: name,
                      }))}
                    ></Select>
                    <div className="invalid-feedback">{error.employee}</div>
                  </div>

                  <h5 className="Title mt-4 mb-3">Permissions</h5>

                  <div  className="row">
                  {projectLabel &&
                    projectLabel.map((data, index) => {
                      return (
                          <div key={index} className="col-lg-3 col-md-3">
                            <label className="col-form-label">
                              {data.name}
                            </label>
                            <div className="">
                              <div>
                                <div>
                                  <label className="">
                                    <input
                                      className="radioButton radioIcon mx-1"
                                      type="checkbox"
                                      name="1"
                                      // checked={roleValue.includes("1")}
                                      checked={
                                        roleValue[data._id]?.includes(1) ||
                                        false
                                      }
                                      onChange={() => roleOnChange(1, data._id)}
                                    />
                                    View
                                  </label>
                                </div>

                                <div>
                                  <label className="">
                                    <input
                                      className="radioButton radioIcon mx-1"
                                      type="checkbox"
                                      name="2"
                                      checked={
                                        roleValue[data._id]?.includes(2) ||
                                        false
                                      }
                                      onChange={() => roleOnChange(2, data._id)}
                                    />
                                    Create
                                  </label>
                                </div>

                                <div>
                                  <label className="">
                                    <input
                                      className="radioButton radioIcon mx-1"
                                      type="checkbox"
                                      name="3"
                                      checked={
                                        roleValue[data._id]?.includes(3) ||
                                        false
                                      }
                                      onChange={() => roleOnChange(3, data._id)}
                                    />
                                    Update
                                  </label>
                                </div>

                                <div>
                                  <label className="">
                                    <input
                                      className="radioButton radioIcon mx-1"
                                      type="checkbox"
                                      name="4"
                                      checked={
                                        roleValue[data._id]?.includes(4) ||
                                        false
                                      }
                                      onChange={() => roleOnChange(4, data._id)}
                                    />
                                    Delete
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                      );
                    })}
                    </div>
                  <div className="width_btn_cstm">
                    <button
                      className="butonColor my-3"
                      type="submit"
                      onClick={updateAccess}
                      ref={buttonref}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UpdateAccess;

import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-toastify";
import useButtonLoader from "../../Hooks/UseButtonLoader";

function ForgotPassword() {
  const [inputEmail, setInputEmail] = useState("");
  const [error, setError] = useState("");
  const [buttonRef, loader] = useButtonLoader("Forgot Password");

  const validation = () => {
    let errorMessage = {
      email: "",
    };
    let status = true;
    const emailregex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    if (!inputEmail.email) {
      errorMessage["email"] = "Please Enter email";
      status = false;
    } else if (!emailregex.test(inputEmail.email)) {
      errorMessage["email"] = "Please Enter Valid Email";
      status = false;
    }
    setError(errorMessage);
    return status;
  };

  const forgerPasswordLink = async (e) => {
    e.preventDefault();
    let status = validation();
    if (status) {
      try {
        loader(true);
        const response = await axios.post(
          `${process.env.REACT_APP_SECRET_KEY}/forgetPassword`,
          inputEmail
        );
        if (response.data.status === 200) {
          loader(false);
          toast.success(response.data.message);
        } else {
          loader(false);
          toast.error(response.data.message);
        }
      } catch (err) {
        loader(false);
        console.log(err);
      }
    }
  };

  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    setInputEmail({ ...inputEmail, [name]: value });
  };


  return (
    <>
      <div className="LoginArea">
        <div className="LoginBox">
          <figure>
            <img alt="" src="/images/ripenApps.png" />
          </figure>
          <h5>Forgot Password</h5>
          <p className="text-center">
            Please enter you registered email id. We will send you link to
            create new password.
          </p>
          <form>
            <div className="form-group">
              <label htmlFor="email" className="col-form-label">
                User ID
              </label>
              <input
                type="text"
                className={`form-control ${
                  !inputEmail.email
                    ? error.email && "is-invalid"
                    : error.email && "is-invalid"
                }`}
                placeholder="Enter User ID "
                name="email"
                id="email"
                onChange={onChangeHandler}
              />
              <span className="Icon forgotMargin">
                <i className="fa fa-user"></i>
              </span>

              <div className="invalid-feedback">{error.email}</div>
            </div>
           
            

            <button
              onClick={forgerPasswordLink}
              ref={buttonRef}
            >
              Forgot Password <i className="fa fa-sign-in"></i>
            </button>
          </form>
        </div>
      </div>
    </>
  );
}

export default ForgotPassword;

import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import TmProject from "./TmCostProject";
import FixCostProject from "./FixCostProject";
import ProjectMember from "./ProjectMember";
import { toast } from "react-toastify";
import axios from "axios";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import useButtonLoader from "../../../Hooks/UseButtonLoader";
import AddPlateform from "./AddPlateform";

function AddProject({ listing }) {
  const [inputData, setInputData] = useState("");
  const [error, setError] = useState("");
  const [value, setValue] = useState("1");
  const [manager, setManager] = useState("");
  const [projectAnalysis, setProjectAnalysis] = useState("");
  // const [designationId, setDesignationId] = useState("");
  const [buttonref, loading] = useButtonLoader("Submit");
  const navigator = useNavigate();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isChecked, setIsChecked] = useState(false);

  //--------------Add-plateform-----------------------
  const [platFormValue, setPlatformValue] = useState([]);
  const [websiteValue, setWebsiteValue] = useState([]);
  const [iosValue, setIosValue] = useState([]);
  const [androidValue, setAndroidValue] = useState([]);
  const [adminValue, setAdminValue] = useState([]);
  const [backendValue, setBackendValue] = useState([]);
  //--------Fix-Cost-Project------------------
  const [row, setRow] = useState([
    {
      id: 1,
      milestoneType: "",
      milestonePhase: "",
      milestoneName: "",
      projectAnalyst: "",
      pdf: "",
      startDate: "",
      endDate: "",
      testingDate: "",
      deliveryDate: "",
    },
  ]);
  //--------------T&M-project-Type----------------------
  const [tmProject, setTmProject] = useState([
    {
      tmId: 1,
      startDate: "",
      endDate: "",
      testingDate: "",
      deliveryDate: "",
    },
  ]);

  //------------------Project-Member-----------------
  const [projectRow, setProjectRow] = useState([
    {
      pId: Date.now(),
      departments: "",
      employee: "",
      workAssignedFor: "",
      startDate: "",
      endDate: "",
    },
  ]);

  let validation = () => {
    let errorMessage = {};
    let status = true;

    if (!inputData.projectName) {
      errorMessage["projectName"] = "Please enter projectName";
      status = false;
    }

    if (!inputData.clientName) {
      errorMessage["clientName"] = "Please enter client Name";
      status = false;
    }
    if (!startDate) {
      errorMessage["startDate"] = "Please enter start Date";
      status = false;
    }
    if (!inputData.projectManager) {
      errorMessage["projectManager"] = "Please select project owner";
      status = false;
    }
    if (!endDate) {
      errorMessage["endDate"] = "Please enter end date";
      status = false;
    }
    if (!inputData.projectStatus) {
      errorMessage["projectStatus"] = "Please select project status";
      status = false;
    }
    // if (!inputData.projectAnalyst) {
    //   errorMessage["projectAnalyst"] = "Please select project analyst";
    //   status = false;
    // }
    // // -----------------Add-Platform-name-----------

    // websiteValue.forEach((data, index) => {
    //   if (!data.technologies) {
    //     errorMessage[
    //       `${data.type},${index},technologies`
    //     ] = `Technologies is required`;
    //     status = false;
    //   }
    //   if (!data.name) {
    //     errorMessage[`${data.type},${index},name`] = "Name is required";
    //     status = false;
    //   }
    //   if (!data.gitUrl) {
    //     errorMessage[`${data.type},${index},gitUrl`] = "Url is required";
    //     status = false;
    //   }
    // });
    // iosValue.forEach((data, index) => {
    //   if (!data.technologies) {
    //     errorMessage[
    //       `${data.type},${index},technologies`
    //     ] = `Technologies is required`;
    //     status = false;
    //   }
    //   if (!data.name) {
    //     errorMessage[`${data.type},${index},name`] = "Name is required";
    //     status = false;
    //   }
    //   if (!data.gitUrl) {
    //     errorMessage[`${data.type},${index},gitUrl`] = "Url is required";
    //     status = false;
    //   }
    // });
    // androidValue.forEach((data, index) => {
    //   if (!data.technologies) {
    //     errorMessage[
    //       `${data.type},${index},technologies`
    //     ] = `Technologies is required`;
    //     status = false;
    //   }
    //   if (!data.name) {
    //     errorMessage[`${data.type},${index},name`] = "Name is required";
    //     status = false;
    //   }
    //   if (!data.gitUrl) {
    //     errorMessage[`${data.type},${index},gitUrl`] = "Url is required";
    //     status = false;
    //   }
    // });
    // adminValue.forEach((data, index) => {
    //   if (!data.technologies) {
    //     errorMessage[
    //       `${data.type},${index},technologies`
    //     ] = `Technologies is required`;
    //     status = false;
    //   }
    //   if (!data.name) {
    //     errorMessage[`${data.type},${index},name`] = "Name is required";
    //     status = false;
    //   }
    //   if (!data.gitUrl) {
    //     errorMessage[`${data.type},${index},gitUrl`] = "Url is required";
    //     status = false;
    //   }
    // });

    // backendValue.forEach((data, index) => {
    //   if (!data.technologies) {
    //     errorMessage[
    //       `${data.type},${index},technologies`
    //     ] = `Technologies is required`;
    //     status = false;
    //   }
    //   if (!data.name) {
    //     errorMessage[`${data.type},${index},name`] = "Name is required";
    //     status = false;
    //   }
    //   if (!data.gitUrl) {
    //     errorMessage[`${data.type},${index},gitUrl`] = "Url is required";
    //     status = false;
    //   }
    // });

    //------------------Fix-CostProject----------------
    if (value === "1") {
      for (let i = 0; i < row.length; i++) {
        const repeat = row[i];

        if (!repeat.milestoneType) {
          errorMessage["milestoneType"] = "Please enter milestone type";
          status = false;
        }
        if (!repeat.milestonePhase) {
          errorMessage["milestonePhase"] = "Please enter milestone phase";
          status = false;
        }
        if (!repeat.milestoneName) {
          errorMessage["milestoneName"] = "Please enter milestone name";
          status = false;
        }
        // if (repeat.milestonePhase === "Analysis" && !repeat.milestoneAnalyst) {
        //   errorMessage["milestoneAnalyst"] = "Please select milestone Analyst";
        //   status = false;
        // }
        if (!repeat.startDate) {
          errorMessage["startDate"] = "Please select start date";
          status = false;
        }
        if (!repeat.endDate) {
          errorMessage["endDate"] = "Please select end date";
          status = false;
        }
        if (!repeat.testingDate) {
          errorMessage["testingDate"] = "Please select testing date";
          status = false;
        }
        if (!repeat.deliveryDate) {
          errorMessage["deliveryDate"] = "Please enter delivery date";
          status = false;
        }
      }
    }
    //--------------------T & M-project-Type------------------------

    if (value === "2") {
      for (let i = 0; i < tmProject.length; i++) {
        const repeat = tmProject[i];

        if (!repeat.startDate) {
          errorMessage["startDate"] = "Please enter start date ";
          status = false;
        }
        if (!repeat.endDate) {
          errorMessage["endDate"] = "Please enter end date ";
          status = false;
        }
        if (!repeat.testingDate) {
          errorMessage["testingDate"] = "Please enter testing date ";
          status = false;
        }
        if (!repeat.deliveryDate) {
          errorMessage["deliveryDate"] = "Please enter delivery date ";
          status = false;
        }
      }
    }
    //------------------Project-Member-----------------
    for (let i = 0; i < projectRow.length; i++) {
      const repeat = projectRow[i];

      if (!repeat.departments) {
        errorMessage["departments"] = "Please enter departments ";
        status = false;
      }
      if (!repeat.employee) {
        errorMessage["employee"] = "Please enter employee ";
        status = false;
      }
      if (!repeat.workAssignedFor) {
        errorMessage["workAssignedFor"] = "Please enter work assigned for ";
        status = false;
      }
      if (!repeat.startDate) {
        errorMessage["startDate"] = "Please Enter start date ";
        status = false;
      }
      if (!repeat.endDate) {
        errorMessage["endDate"] = "Please Enter end date ";
        status = false;
      }
    }

    setError(errorMessage);
    return status;
  };

  //------------email-cehecked-unCheecked------------
  const onChangeCheckbox = () => {
    setIsChecked(!isChecked);
  };
  //------------add-plateform-cehecked-unCheecked------------

  const platformSelection = (value) => {
    if (platFormValue.includes(value)) {
      // Platform is already selected, uncheck it and remove data
      setPlatformValue(platFormValue.filter((data) => data !== value));

      if (value === "website") {
        setWebsiteValue([]);
      }
      if (value === "ios") {
        setIosValue([]);
      }
      if (value === "android") {
        setAndroidValue([]);
      }
      if (value === "admin") {
        setAdminValue([]);
      }
      if (value === "backend") {
        setBackendValue([]);
      }
    } else {
      // Platform is not selected, check it
      setPlatformValue([...platFormValue, value]);
      if (value === "website") {
        setWebsiteValue([
          {
            id: Date.now(),
            type: "website",
            technologies: "",
            name: "",
            gitUrl: "",
          },
        ]);
      }
      if (value === "ios") {
        setIosValue([
          {
            id: Date.now(),
            type: "ios",
            technologies: "",
            name: "",
            gitUrl: "",
          },
        ]);
      }
      if (value === "android") {
        setAndroidValue([
          {
            id: Date.now(),
            type: "android",
            technologies: "",
            name: "",
            gitUrl: "",
          },
        ]);
      }
      if (value === "admin") {
        setAdminValue([
          {
            id: Date.now(),
            type: "admin",
            technologies: "",
            name: "",
            gitUrl: "",
          },
        ]);
      }
      if (value === "backend") {
        setBackendValue([
          {
            id: Date.now(),
            type: "backend",
            technologies: "",
            name: "",
            gitUrl: "",
          },
        ]);
      }
    }
  };
  const onChangeHandler = (e, date) => {
    const { name, value } = e.target;
    const formatStartDate = startDate? moment(startDate).format("YYYY-MM-DD"): "";
    const formatEndtDate = endDate ? moment(endDate).format("YYYY-MM-DD") : "";
    setInputData({
      ...inputData,
      [name]: value,
      startDate: formatStartDate,
      endDate: formatEndtDate,
    });
  };

  //--------------Rdio-Button-onChange-value------------------
  const buttonBandler = (value) => {
    setValue(value);
  };
  // ---------onChange-add-Platform-Project---------------
  const onChangeplateform = (e, typeName, id) => {
    const { name, value } = e.target;
    if (typeName === "website") {
      const findIndex = websiteValue.findIndex((data) => data.id === id);
      let websiteClone = JSON.parse(JSON.stringify(websiteValue));
      websiteClone[findIndex][name] = value;
      setWebsiteValue(websiteClone);
    }
    if (typeName === "ios") {
      const findIndex = iosValue.findIndex((data) => data.id === id);
      let iosClone = JSON.parse(JSON.stringify(iosValue));
      iosClone[findIndex][name] = value;
      setIosValue(iosClone);
    }
    if (typeName === "android") {
      const findIndex = androidValue.findIndex((data) => data.id === id);
      let androidClone = JSON.parse(JSON.stringify(androidValue));
      androidClone[findIndex][name] = value;
      setAndroidValue(androidClone);
    }
    if (typeName === "admin") {
      const findIndex = adminValue.findIndex((data) => data.id === id);
      let adminClone = JSON.parse(JSON.stringify(adminValue));
      adminClone[findIndex][name] = value;
      setAdminValue(adminClone);
    }
    if (typeName === "backend") {
      const findIndex = backendValue.findIndex((data) => data.id === id);
      let backendClone = JSON.parse(JSON.stringify(backendValue));
      backendClone[findIndex][name] = value;
      setBackendValue(backendClone);
    }
  };
  // ---------onChange-Fix-Cost-Project---------------

  const onChangefixCost = (id, fieldName, value) => {
    const updatedRow = row.map((repeat) => {
      if (repeat.id === id) {
        return { ...repeat, [fieldName]: value };
      }
      return repeat;
    });
    setRow(updatedRow);
  };

  //----------------designation-API--------------------------
  // const fetchdesignation = async () => {
  //   try {
  //     const token = localStorage.getItem("demo-token");
  //     const response = await axios.get(
  //       `${process.env.REACT_APP_SECRET_KEY}/designation-list`,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );
  //     if (response.data.status === 200) {
  //       setDesignationId(response.data.data.designations);
  //     } else {
  //       toast.error(response.data.message);
  //     }
  //   } catch (err) {
  //     console.log("form", err);
  //   }
  // };
  //-----------------fetch-Project-Manager-Api----------------

  const fetchProjectManager = async () => {
    try {
      const token = localStorage.getItem("demo-token");
      const response = await axios.get(
        `${process.env.REACT_APP_SECRET_KEY}/project-manager`,

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.status === 200) {
        setManager(response.data.data.projectManagers);
      } else {
        toast.error(response.data.message);
      }
    } catch (err) {
      console.log("form", err);
    }
  };
  //-----------------fetch-Project-Analysisi-Api----------------

  const fetchProjectAnalysis = async () => {
    try {
      const token = localStorage.getItem("demo-token");
      const response = await axios.get(
        `${process.env.REACT_APP_SECRET_KEY}/project-analyst`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log("projectAnalysis",response.data.data)
      if (response.data.status === 200) {
        setProjectAnalysis(response.data.data.projectAnalysts);
      } else {
        toast.error(response.data.message);
      }
    } catch (err) {
      console.log("form", err);
    }
  };
  //-----------------AAD-Project-Api----------------
  const addProjectMember = async (e) => {
    e.preventDefault();
    let platformArr = [];
    const websiteValueForApi = websiteValue.map((data) => {
      return {
        name: data.name,
        technologies: data.technologies,
        gitUrl: data.gitUrl,
      };
    });
    const iosValueForApi = iosValue.map((data) => {
      return {
        name: data.name,
        technologies: data.technologies,
        gitUrl: data.gitUrl,
      };
    });
    const androidValueForApi = androidValue.map((data) => {
      return {
        name: data.name,
        technologies: data.technologies,
        gitUrl: data.gitUrl,
      };
    });
    const adminValueForApi = adminValue.map((data) => {
      return {
        name: data.name,
        technologies: data.technologies,
        gitUrl: data.gitUrl,
      };
    });
    const backendValueForApi = backendValue.map((data) => {
      return {
        name: data.name,
        technologies: data.technologies,
        gitUrl: data.gitUrl,
      };
    });
    if (websiteValue.length > 0) {
      platformArr.push({
        name: "website",
        subPlatform: websiteValueForApi,
      });
    }

    if (iosValue.length > 0) {
      platformArr.push({
        name: "ios",
        subPlatform: iosValueForApi,
      });
    }

    if (androidValue.length > 0) {
      platformArr.push({
        name: "android",
        subPlatform: androidValueForApi,
      });
    }

    if (adminValue.length > 0) {
      platformArr.push({
        name: "admin",
        subPlatform: adminValueForApi,
      });
    }
    if (backendValue.length > 0) {
      platformArr.push({
        name: "backend",
        subPlatform: backendValueForApi,
      });
    }

    // console.log(platformArr);

    const status = validation();
    if (status) {
      try {
        loading(true);
        const token = localStorage.getItem("demo-token");

        let milestoneArr = tmProject.map((data) => {
          return {
            ...data,
            startDate: moment(data.startDate).format("YYYY-MM-DD"),
            endDate: moment(data.endDate).format("YYYY-MM-DD"),
            testingDate: moment(data.testingDate).format("YYYY-MM-DD"),
            deliveryDate: moment(data.deliveryDate).format("YYYY-MM-DD"),
          };
        });

        let milestonesArr = row.map((data) => {
          return {
            ...data,
            startDate: moment(new Date(data.startDate)).format("YYYY-MM-DD"),
            endDate: moment(data.endDate).format("YYYY-MM-DD"),
            testingDate: moment(data.testingDate).format("YYYY-MM-DD"),
            deliveryDate: moment(data.deliveryDate).format("YYYY-MM-DD"),
          };
        });

        let projectArr = projectRow.map((data) => {
          return {
            ...data,
            startDate: moment(data.startDate).format("YYYY-MM-DD"),
            endDate: moment(data.endDate).format("YYYY-MM-DD"),
          };
        });

        const finalData = {
          ...inputData,
          emailStatus: isChecked,
          platforms: platformArr,
          milestones: milestonesArr,
          members: projectArr,
          projectType: value === "1" ? "Fixed Cost" : "T & M",
        };

        const finalData2 = {
          ...inputData,
          emailStatus: isChecked,
          platforms: platformArr,
          milestones: milestoneArr,
          members: projectArr,

          projectType: value === "1" ? "Fixed Cost" : "T & M",
        };

        const response = await axios.post(
          `${process.env.REACT_APP_SECRET_KEY}/add-project`,
          value === "1" ? finalData : finalData2,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.data.status === 200) {
          loading(false);

          toast.success(response.data.message);
          navigator("/project-Management");
          // listing();
        } else {
          loading(false);
          toast.error(response.data.message);
        }
      } catch (error) {
        loading(false);
        console.log(error);
      }
    }
  };

  // console.log("inputData", {
  //   ...inputData,
  //   milestones: row,
  //   milestonesT$M: tmProject,
  //   member: projectRow,
  // });

  useEffect(() => {
    fetchProjectAnalysis();
    fetchProjectManager();
  }, []);
  return (
    <>
      <div className="WrapperArea">
        <div className="WrapperBox">
          {/*-----Add-project-details----- */}
          <div className="Small-Wrapper ">
            <h4 className="Title">
              <span>
                <Link to="/project-Management">
                  <i className="fa fa-arrow-left" aria-hidden="true"></i>
                </Link>
              </span>
              Add Project Information
            </h4>
            <div className="container mt-4">
              <form className="needs-validation">
                <div className="row">
                  <div className="col-3">
                    <label htmlFor="projectname" className="col-form-label">
                      Project Name
                    </label>
                    <br />
                    <input
                      type="text"
                      className={`form-control ${
                        !inputData.projectName &&
                        error.projectName &&
                        "is-invalid"
                      }`}
                      placeholder="Enter your project name here"
                      name="projectName"
                      id="projectname"
                      onChange={onChangeHandler}
                      value={inputData.projectName || ""}
                      maxLength={52}
                    />
                    <span className="invalid-feedback">
                      {error.projectName}
                    </span>
                  </div>
                  <div className="col-3">
                    <label htmlFor="clientName" className="col-form-label">
                      Client Name
                    </label>
                    <br />
                    <input
                      type="text"
                      className={`form-control ${
                        !inputData.clientName &&
                        error.clientName &&
                        "is-invalid"
                      }`}
                      placeholder="Enter client name here "
                      name="clientName"
                      id="clientName"
                      onChange={onChangeHandler}
                      value={inputData.clientName || ""}
                      maxLength={32}
                    />
                    <div className="invalid-feedback">{error.clientName}</div>
                  </div>
                  <div className="col-3">
                    <label htmlFor="StartDate" className="col-form-label">
                      Start Date
                    </label>
                    <br />
                    <div>
                      <DatePicker
                        placeholderText="Start date"
                        className={`form-control dataPickerinputWidth ${
                          !startDate && error.startDate && "is-invalid"
                        }`}
                        name="startDate"
                        id="StartDate"
                        selected={startDate}
                        // value={inputData.startDate}
                        showYearDropdown
                        yearDropdownItemNumber={100}
                        scrollableYearDropdown
                        showMonthDropdown
                        useShortMonthInDropdown
                        onChange={(date) => setStartDate(date)}
                        dateFormat="yyyy-MM-dd"
                        maxDate={endDate}
                      />
                      {!startDate && error.startDate && (
                        <p className="datePicker_validation_small">
                          {error.startDate}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-3">
                    <div>
                      <label htmlFor="EndDate" className="col-form-label">
                        End Date
                      </label>
                      <br />

                      <DatePicker
                        placeholderText="End date"
                        className={`form-control dataPickerinputWidth ${
                          !endDate && error.endDate && "is-invalid"
                        }`}
                        name="endDate"
                        id="EndDate"
                        selected={endDate}
                        // value={inputData.endDate}
                        showYearDropdown
                        yearDropdownItemNumber={100}
                        scrollableYearDropdown
                        showMonthDropdown
                        useShortMonthInDropdown
                        onChange={(date) => setEndDate(date)}
                        dateFormat="yyyy-MM-dd"
                        minDate={startDate}
                      />
                      {!endDate && error.endDate && (
                        <p className="datePicker_validation_small">
                          {error.endDate}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-3">
                    <label htmlFor="projectManager" className="col-form-label">
                      Project Owner
                    </label>
                    <br />
                    <select
                      className={`custm-form-control ${
                        !inputData.projectManager &&
                        error.projectManager &&
                        "is-invalid"
                      }`}
                      name="projectManager"
                      id="projectManager"
                      onChange={onChangeHandler}
                      value={inputData.projectManager}
                    >
                      <option>Select Project Owner</option>
                      {manager &&
                        manager.map(({ _id, name }, index) => {
                          return (
                            <React.Fragment key={index}>
                              <option value={_id}>{name}</option>
                            </React.Fragment>
                          );
                        })}
                    </select>
                    <div className="invalid-feedback">
                      {error.projectManager}
                    </div>
                  </div>
                  <div className="col-3">
                    <label htmlFor="projectStatus" className="col-form-label">
                      Project Status
                    </label>
                    <br />
                    <select
                      className={`custm-form-control ${
                        !inputData.projectStatus &&
                        error.projectStatus &&
                        "is-invalid"
                      }`}
                      name="projectStatus"
                      id="projectStatus"
                      value={inputData.projectStatus}
                      onChange={onChangeHandler}
                    >
                      <option value="">Select Status</option>
                      <option value="Yet to start">Yet to start</option>
                      <option value="In Progress">In Progress</option>
                      <option value="Hold">Hold</option>
                      <option value="Completed">Completed</option>
                      <option value="Terminated">Terminated</option>
                      <option value="Abscond">Abscond</option>
                    </select>
                    <div className="invalid-feedback">
                      {error.projectStatus}
                    </div>
                  </div>
                  <div className="col-3">
                    <label htmlFor="projectAnalyst" className="col-form-label">
                      Project Analyst
                    </label>
                    <br />
                    <select
                      className={`custm-form-control ${
                        !inputData.projectAnalyst &&
                        error.projectAnalyst &&
                        "is-invalid"
                      }`}
                      name="projectAnalyst"
                      id="projectAnalyst"
                      onChange={onChangeHandler}
                      value={inputData.projectAnalyst}
                    >
                      <option value="">Select Project Analyst</option>
                      {projectAnalysis &&
                        projectAnalysis.map(({ _id, name }, index) => {
                          return (
                            <React.Fragment key={index}>
                              <option value={_id}>{name}</option>
                            </React.Fragment>
                          );
                        })}
                    </select>
                    <div className="invalid-feedback">
                      {error.projectAnalyst}
                    </div>
                    {/* </div> */}
                  </div>
                </div>
              </form>
            </div>
          </div>
          {/*-----Add------PlateForm-----Name----- */}

          <div className="Small-Wrapper">
            <div>
              <h6 className="Title my-1"> Platform Name </h6>

              <br />
              <div>
                <label className="">
                  <input
                    className="radioButton radioIcon mx-1"
                    type="checkbox"
                    name="website"
                    checked={platFormValue.includes("website")}
                    onChange={() => platformSelection("website")}
                  />
                  Website
                </label>
                {platFormValue.includes("website") ? (
                  <AddPlateform
                    label={"Website"}
                    type={"website"}
                    error={error}
                    addPlateformValue={websiteValue}
                    onChangeplateform={onChangeplateform}
                    setAddPlateformValue={setWebsiteValue}
                  />
                ) : null}
              </div>

              <div>
                <label className=" mt-2">
                  <input
                    className="radioButton radioIcon mx-1"
                    type="checkbox"
                    name="ios"
                    checked={platFormValue.includes("ios")}
                    onChange={() => platformSelection("ios")}
                  />
                  IOS App
                </label>
                {platFormValue.includes("ios") ? (
                  <AddPlateform
                    label={"IOS App"}
                    type={"ios"}
                    error={error}
                    addPlateformValue={iosValue}
                    onChangeplateform={onChangeplateform}
                    setAddPlateformValue={setIosValue}
                  />
                ) : null}
              </div>

              <label className="mt-2">
                <input
                  className="radioButton radioIcon mx-1"
                  type="checkbox"
                  name="android"
                  checked={platFormValue.includes("android")}
                  onChange={() => platformSelection("android")}
                />
                Android App
              </label>
              {platFormValue.includes("android") ? (
                <AddPlateform
                  label={"Android App"}
                  type={"android"}
                  error={error}
                  addPlateformValue={androidValue}
                  onChangeplateform={onChangeplateform}
                  setAddPlateformValue={setAndroidValue}
                />
              ) : null}

              <div>
                <label className="mt-2">
                  <input
                    className="radioButton radioIcon mx-1"
                    type="checkbox"
                    name="admin"
                    checked={platFormValue.includes("admin")}
                    onChange={() => platformSelection("admin")}
                  />
                  Admin panel
                </label>
                {platFormValue.includes("admin") ? (
                  <AddPlateform
                    label={"Admin Panel"}
                    type={"admin"}
                    error={error}
                    addPlateformValue={adminValue}
                    onChangeplateform={onChangeplateform}
                    setAddPlateformValue={setAdminValue}
                  />
                ) : null}
              </div>

              <div>
                <label className="mt-2">
                  <input
                    className="radioButton radioIcon mx-1"
                    type="checkbox"
                    name="backend"
                    checked={platFormValue.includes("backend")}
                    onChange={() => platformSelection("backend")}
                  />
                  Backend
                </label>
                {platFormValue.includes("backend") ? (
                  <AddPlateform
                    label={"Backend"}
                    type={"backend"}
                    error={error}
                    addPlateformValue={backendValue}
                    // onChangeHandler={onChangeHandler}
                    onChangeplateform={onChangeplateform}
                    setAddPlateformValue={setBackendValue}
                  />
                ) : null}
              </div>
            </div>
          </div>

          <div className="Small-Wrapper">
            <div className="wrapper_radio_flex_align">
              <input
                className="radioIcon"
                type="radio"
                htmlFor=""
                id="html"
                name="html"
                value="fixCost"
                defaultChecked={true}
                onClick={() => buttonBandler("1")}
              />
              <label className="radioButton" htmlFor="html">
                Fixed Cost
              </label>

              <input
                className="radioIcon"
                type="radio"
                htmlFor=""
                id="css"
                name="html"
                value="T&M"
                onClick={() => buttonBandler("2")}
              />
              <label className="radioButton" htmlFor="css">
                T & M Project
              </label>
            </div>
          </div>

          {/* milestone component call here */}
          {value === "1" && (
            <FixCostProject
              row={row}
              setRow={setRow}
              projectAnalysis={projectAnalysis}
              error={error}
              onChangefixCost={onChangefixCost}
            />
          )}

          {value === "2" && (
            <TmProject
              tmProject={tmProject}
              setTmProject={setTmProject}
              error={error}
            />
          )}
          {
            <ProjectMember
              projectRow={projectRow}
              setProjectRow={setProjectRow}
              error={error}
              isChecked={isChecked}
              onChangeCheckbox={onChangeCheckbox}
            />
          }

          <div className="cstm_margin_padding">
            <button
              className="butonColor my-3 "
              type="submit"
              onClick={addProjectMember}
              ref={buttonref}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddProject;

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { toast } from "react-toastify";
import usePageLoader from "../../Hooks/UsePageLoader";
import useSearchBox from "../../Hooks/UseSearchBox";
import moment from "moment";
import { IoMdRefresh } from "react-icons/io";
import Pagination from "react-js-pagination";

function ResourceManagement() {
  const [loding, show, hide] = usePageLoader();
  const [searchTerm, setSearchTerm] = useState("");
  const searchValue = useSearchBox(searchTerm, 650);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [activePage, setActivePage] = useState(1);
  const [total, setTotal] = useState("");
  const [resourcelist, setResourceList] = useState("");
  const [isReleaseSearch, setIsReleseSearch] = useState("");
  const [department, setDepartment] = useState([]);
  const [departmentSearch, setDepartmentSearch] = useState("");

  //------------get-Resource------------------
  const fetchResourceDeatils = async () => {
    try {
      show();
      const token = localStorage.getItem("demo-token");
      let searchStartDate = startDate
        ? moment(startDate).format("YYYY-MM-DD")
        : "";
      let searchEndDate = endDate ? moment(endDate).format("YYYY-MM-DD") : "";
      const response = await axios.get(
        `${process.env.REACT_APP_SECRET_KEY}/resource-management?search=${searchValue}&isRelease=${isReleaseSearch}&page=${activePage}&startDate=${searchStartDate}&endDate=${searchEndDate}&department=${departmentSearch}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log("resourcelist", response.data.data.listing);
      if (response.data.status === 200) {
        setResourceList(response.data.data.listing);
        setTotal(response.data.data.total);
        hide();
      } else {
        hide();
        toast.error(response.data.message);
      }
    } catch (err) {
      hide();
      console.log(err);
    }
  };
  //------------get-department------------------
  const fetchDepartment = async () => {
    try {
      const token = localStorage.getItem("demo-token");
      const response = await axios.get(
        `${process.env.REACT_APP_SECRET_KEY}/department-list`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.status === 200) {
        setDepartment(response.data.data.departments);
      }
    } catch (err) {
      console.log("form", err);
    }
  };

  const handleStartDate = (date) => {
    setStartDate(date);
  };

  const handleEndDate = (date) => {
    setEndDate(date);
  };

  const refreshHandler = () => {
    setSearchTerm("");
    setStartDate(new Date());
    setEndDate(new Date());
    setIsReleseSearch("");
    fetchResourceDeatils();
    setDepartmentSearch("");
  };
  useEffect(() => {
    if (searchValue) {
      setActivePage(1)
    } else {
      setActivePage(activePage)
    }
    fetchResourceDeatils();
    fetchDepartment();
  }, [
    searchValue,
    activePage,
    isReleaseSearch,
    startDate,
    endDate,
    departmentSearch,
  ]);

  useEffect(() => {
    if (startDate && endDate) {
      fetchResourceDeatils();
    }
  }, [startDate, endDate]);
  return (
    <>
      {loding}
      <div className="WrapperArea">
        <div className="WrapperBox">
          <div className="Small-Wrapper">
            <div className="d-flex">
              <h4 className="Title">Resource Management</h4>
            </div>
            <div className="Small-Wrapper cstm-small_wrapper_employee">
              <div className="row">
                <div className="col-lg-3 col-md-3">
                  <form action="">
                    <input
                      className="input_style"
                      type="text"
                      placeholder="Search by name..."
                      onChange={(e) => setSearchTerm(e.target.value)}
                      value={searchTerm}
                    />
                  </form>
                </div>
                <div className="col-lg-3 col-md-3">
                  <DatePicker
                    placeholderText="Start Date"
                    className="input dataPickerWidth input_style"
                    selected={startDate}
                    value={startDate}
                    showYearDropdown
                    yearDropdownItemNumber={100}
                    scrollableYearDropdown
                    showMonthDropdown
                    useShortMonthInDropdown
                    onChange={handleStartDate}
                    dateFormat="yyyy-MM-dd"
                    maxDate={new Date(endDate)}
                  />
                </div>
                <div className="col-lg-3 col-md-3">
                  <div className="form-field date-picker ">
                    <DatePicker
                      placeholderText="End Date"
                      className="input dataPickerWidth input_style"
                      selected={endDate}
                      value={endDate}
                      showYearDropdown
                      yearDropdownItemNumber={100}
                      scrollableYearDropdown
                      showMonthDropdown
                      useShortMonthInDropdown
                      onChange={handleEndDate}
                      dateFormat="yyyy-MM-dd"
                      minDate={new Date(startDate)}
                    />
                  </div>
                </div>
                <div className="col-lg-2 col-md-2">
                  <select
                    className="input_style"
                    onChange={(e) => setDepartmentSearch(e.target.value)}
                    value={departmentSearch}
                  >
                    <option value="">All Department</option>
                    {department &&
                      department.map(({ departmentName, _id }, idindex) => {
                        return (
                          <React.Fragment key={idindex}>
                            <option value={_id}>{departmentName}</option>
                          </React.Fragment>
                        );
                      })}
                  </select>
                </div>
                <div className="col-lg-1 col-md-1">
                  <IoMdRefresh
                    className="fs-4 input_style_refresh employeeRefress"
                    role="button"
                    onClick={refreshHandler}
                  />
                </div>
              </div>
            </div>
            <div className="TableList">
              <table>
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Name</th>
                    <th>Department</th>
                    {/* <th>Status</th> */}
                    <th>Free Time</th>
                    <th>Occupied Till Date</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {resourcelist && resourcelist.length > 0 ? (
                    resourcelist.map(
                      (
                        {
                          _id,
                          name,
                          department,
                          employee,
                          employeeId,
                          endDate,
                          isRelease,
                          freeTime,
                          releaseDate,
                        },
                        index
                      ) => {
                        return (
                          <React.Fragment key={index}>
                            <tr>
                              <td>{(activePage - 1) * 50 + ++index}</td>
                              <td>{name || `---`}</td>
                              <td>{department?.name || `---`}</td>
                              {/* <td>{isRelease === true ? <span className="dealyPossitive">Free</span> : 
                                isRelease === false ? <span className="dealyNegative">Occupied</span> : 
                                <span className="dealyPossitive">---</span>}</td> */}

                              <td>
                                {freeTime === 0 ? (
                                  <span className="dealyoccupied">
                                    Occupied
                                  </span>
                                ) : freeTime === 9 ? (
                                  <span className="dealyFullFree">
                                    Full Free
                                  </span>
                                ) : freeTime < 0 ? (
                                  <span className="dealyNegative">
                                    {-freeTime}hr Over Time
                                  </span>
                                ) : (
                                  <span className="dealyHrFree">
                                    {freeTime} hr Free
                                  </span>
                                )}
                              </td>
                              <td>
                                {!releaseDate && !endDate
                                  ? "---"
                                  : releaseDate > endDate
                                    ? moment(releaseDate).format("DD-MM-YYYY")
                                    : moment(endDate).format("DD-MM-YYYY")}
                              </td>
                              <td>
                                <div className="dropdown wrap_cstm_dropdown_menu">
                                  <button
                                    className="btn btn-secondary cstm_btn_sceon_background"
                                    type="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <i className="fa fa-ellipsis-v"></i>
                                  </button>
                                  <ul className="dropdown-menu cstm_dropdown_edit">
                                    <li>
                                      <Link
                                        className="dropdown-item"
                                        to={`/resource-management/view-details/${_id}`}
                                      >
                                        <i className="fa fa-eye mx-3"></i>View
                                      </Link>
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                          </React.Fragment>
                        );
                      }
                    )
                  ) : (
                    <tr>
                      <td colSpan={8}>Not Found</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            {total >= 50 ? (
              <nav
                aria-label="Page navigation example"
                className="mt-3 cstm_pagination"
              >
                <Pagination
                  previousLabel={"prev"}
                  nextLabel={"next"}
                  pageCount={activePage}
                  pageRangeDisplayed={5}
                  itemsCountPerPage={50}
                  totalItemsCount={total}
                  itemClass={"page-item"}
                  linkClass={"page-link"}
                  activeLinkClass={"active"}
                  activePage={activePage}
                  onChange={(page) => setActivePage(page)}
                />
              </nav>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default ResourceManagement;

import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import usePageLoader from "../../Hooks/UsePageLoader";
import { toast } from "react-toastify";
import axios from "axios";
import moment from "moment";
import ModalBox from "../../Modals/ModalBox";
import useButtonLoader from "../../Hooks/UseButtonLoader";
import { TiDelete } from "react-icons/ti";
import ReturnInventory from "./ReturnInventoryMobile";
import InventoryHistory from "./InventoryHistoryMobile";
import ReturnInventoryMobile from "./ReturnInventoryMobile";
import InventoryHistoryMobile from "./InventoryHistoryMobile";

function ViewInventoryMobile() {

  const { inventoryId } = useParams();
  const [loder, show, hide] = usePageLoader();
  const [inventoryDeatial, setInventoryDeatial] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [purchaseDate, setPurchaseDate] = useState("");
  const [viewImage, setViewImage] = useState([]);
  const [modal, setModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [updateModalId, setUpdateModalId] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalId, setDeleteModalId] = useState("");
  const [note, setNote] = useState("");
  const [viewNote, setViewNote] = useState([]);
  const [buttonRef, loding] = useButtonLoader("Submit");
  const [error, setError] = useState({});
  const [addNote, setAddNote] = useState("");
  const [images, setImages] = useState([]);
  const [removeImageId, setRemoveImageId] = useState([]);
  const [imageUpdate, setImageUpdate] = useState([]);
  const [returnModal, setReturnModal] = useState(false);
  const [employeeId, setEmployeeId] = useState("");
  const imageExtensions = /\.(jpg|jpeg|png|svg)$/i;
  const [selectedImage, setSelectedImage] = useState(null);
  const [showImageModal, setShowImageModal] = useState(false)


  const addvalidation = () => {
    let errorMessage = {};
    let status = true;

    if (!addNote) {
      errorMessage["addNote"] = "Note is required";
      status = false;
    }
    if(images.length>0){
      images.forEach((images, index) => {
        if (!imageExtensions.test(images.name)) {
          errorMessage["images"] = "Invalid image format";
          status = false;
        }
      })
    }
      
    setError(errorMessage);
    return status;
  };

  const updateValidation = () => {
    let errorMessage = {};
    let status = true;

    if (!note) {
      errorMessage["note"] = "Note is required";
      status = false;
    }

   if(images.length > 0 ){
    images.forEach((images) => {
      if (!imageExtensions.test(images.name)) {
        errorMessage["images"] = "Invalid image format"
        status = false
      }
    })
   }

    setError(errorMessage);
    return status;
  };
  //-------aad-image-OnChange--------
  function imageHandler(files) {
    const selectedImages = Array.from(files);
    setImages((prevImages) => [...prevImages, ...selectedImages]); // Append to the existing images
  }
  //------remove-aad-image---------
  const removeImage = (index) => {
    const updatedImages = [...images];
    updatedImages.splice(index, 1);
    setImages(updatedImages);
  };
  //------remove-already-save-imges-with-_id----------------
  const removeImageUpdate = (index, imageId) => {
    setRemoveImageId([...removeImageId, imageId]);
    const updatedImages = [...imageUpdate];
    updatedImages.splice(index, 1);
    setImageUpdate(updatedImages);
  };

  const handleEdit = (_id, note, images) => {
    setNote(note);
    setUpdateModal(true);
    setUpdateModalId(_id);
    setImageUpdate(images);
  };

  const showImage = (image) => {
    setSelectedImage(image)
    setShowImageModal(true)
  }

  const handleDelete = (_id) => {
    setDeleteModalId(_id);
    setDeleteModal(true);
  };
  //---------------Update-Note-API--------------------------
  const updateNote = async () => {
    const status = updateValidation();
    if (status) {
      try {
        loding(true);
        const token = localStorage.getItem("demo-token");
        const formdata = new FormData();
        formdata.append("note", note);
        formdata.append("inventoryId", inventoryId);
        for (let index = 0; index < removeImageId.length; index++) {
          formdata.append("deletedImageIds", removeImageId[index]);
        }
        for (let index = 0; index < images.length; index++) {
          formdata.append("images", images[index]);
        }
        const response = await axios.put(
          `${process.env.REACT_APP_SECRET_KEY}/note-update/${updateModalId}`,
          formdata,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.data.status === 200) {
          loding(false);
          setNote("");
          setImages("");
          setUpdateModal(false);
          fetchInventoryDeatils();

          toast.success(response.data.message);
        } else {
          loding(false);
          toast.error(response.data.message);
        }
      } catch (error) {
        loding(false);
        console.log(error);
      }
    }
  };

  //---------------Add-Note-API--------------------------
  const addNotes = async () => {
    const status = addvalidation();
    if (status) {
      try {
        loding(true);
        const token = localStorage.getItem("demo-token");
        const formdata = new FormData();
        formdata.append("note", addNote);
        formdata.append("inventoryId", inventoryId);
        for (let index = 0; index < images.length; index++) {
          formdata.append("images", images[index]);
        }
        const response = await axios.post(
          `${process.env.REACT_APP_SECRET_KEY}/note-create`,
          formdata,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.data.status === 200) {
          loding(false);
          setAddNote("");
          setImages("");
          setModal(false);
          fetchInventoryDeatils();

          toast.success(response.data.message);
        } else {
          loding(false);
          toast.error(response.data.message);
        }
      } catch (error) {
        loding(false);
        console.log(error);
      }
    }
  };

  //---------------Delete-Note-API--------------------------
  const deleteNote = async () => {
    try {
      loding(true);
      const token = localStorage.getItem("demo-token");
      const response = await axios.delete(
        `${process.env.REACT_APP_SECRET_KEY}/delete-note/${deleteModalId}`,

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.status === 200) {
        setDeleteModal(false);
        loding(false);
        fetchInventoryDeatils();
        toast.success(response.data.message);
      } else {
        loding(false);
        toast.error(response.data.message);
      }
    } catch (error) {
      loding(false);
      console.log(error);
    }
  };

  //---------------View-Inventory-API--------------------------
  const fetchInventoryDeatils = async () => {
    try {
      show();
      const token = localStorage.getItem("demo-token");
      const response = await axios.get(
        `${process.env.REACT_APP_SECRET_KEY}/inventory-detailes/${inventoryId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log("Viewinventorylist", response.data.data.inventoryDetails);
      if (response.data.status === 200) {
        hide();
        const inventoryValue = response.data.data.inventoryDetails;

        setInventoryDeatial(inventoryValue);
        setCategoryName(inventoryValue?.category.categoryName);
        setPurchaseDate(inventoryValue?.purchaseDate);
        setViewImage(inventoryValue?.images);
        setViewNote(inventoryValue?.notes);
        setEmployeeId(inventoryValue?.assignedTo._id);
      } else {
        hide();
        toast.error(response.data.message);
      }
    } catch (err) {
      console.log("form", err);
      hide();
    }
  };

  useEffect(() => {
    fetchInventoryDeatils();
  }, []);

  return (
    <>
      <title>Inventory Management | View Inventory</title>
      {loder}
      <div className="WrapperArea">
        <div className="WrapperBox">
          <div className="Small-Wrapper cstm_margin_add">
            <h4 className="Title">
              <span>
                <Link to="/mobile-inventory">
                  <i className="fa fa-arrow-left" aria-hidden="true"></i>
                </Link>
              </span>
              View Inventory Details
            </h4>
            <div className="OrderArea col-">
              <ul>
                <li>
                  <span>Category Name</span>
                  {categoryName || `---`}
                </li>
                <li>
                  <span>device Id</span>
                  {inventoryDeatial.deviceId || `---`}
                </li>

                {inventoryDeatial &&
                  inventoryDeatial.attributes.map(({ name, value }, index) => (
                    <li key={index}>
                      {/* <span>{Object.keys(data)}</span> */}
                      <span>
                        {name
                          ? name.charAt(0).toUpperCase() +
                          name.slice(1).toLowerCase()
                          : "---"}
                      </span>

                      {/* {Object.values(data) || "---"} */}
                      {value || "---"}
                    </li>
                  ))}

                <li>
                  <span>Purchase Date</span>
                  {purchaseDate
                    ? moment(purchaseDate).format("DD-MM-YYYY")
                    : "---"}
                </li>
                <li className="d-flex">
                  <span>Current Employee</span>
                  <div className="d-flex justify-content-between align-items-center w-50">
                    {inventoryDeatial?.assignedTo?.name || `---`}
                    {employeeId && (
                      <div className="text-end cstmSearchBox">
                        <Link onClick={() => setReturnModal(true)}>Return</Link>
                      </div>
                    )}
                  </div>
                </li>
              </ul>

              {viewImage.length > 0 && (
                <div className="image-preview">
                  {viewImage.map((image, index) => (
                    <React.Fragment key={index}>
                      <div key={index} className="image-container">
                        <img
                          className="preview-image"
                          src={image.url}
                          alt={`Preview ${index}`}
                          onClick={() => showImage(image.url)}
                        />
                      </div>
                    </React.Fragment>
                  ))}
                </div>
              )}

            </div>
            {/* Inventory History component */}
            <InventoryHistoryMobile
              inventoryId={inventoryId}
              employeeId={employeeId}
            />
          </div>

          <div className="Small-Wrapper cstm_margin_add">
            <div className="SearchBox cstm_searchbox cstmSearchBox ">
              <Link className="addNote" to="" onClick={() => setModal(true)}>
                Add Note
              </Link>
            </div>
            <div className="overflow-cstm">
              {viewNote.length > 0 ? (
                viewNote.map(({ _id, note, createdAt, images }, index) => (
                  <React.Fragment key={index}>
                    <div className="notes_textarea input_style_2">
                      <div>
                        <span>{++index}. </span>
                        {note}
                      </div>

                      <div
                        className="editIcon"
                        onClick={() => handleEdit(_id, note, images)}
                      >
                        <i className="fa fa-pencil-square-o fs-4"></i>
                      </div>
                      <div className="">
                        <TiDelete
                          className="fs-3 color removeAttribute deleteIcon"
                          onClick={() => handleDelete(_id)}
                        />
                        <div className="createdAt">
                          <span>
                            created At :
                            <strong>
                              {createdAt ? moment(createdAt).format("DD-MM-YYYY") : "---"}
                            </strong>
                          </span>
                        </div>
                      </div>
                    </div>
                    {images && images.length > 0 && (
                      <div className="image-preview mt3">
                        {images.map((image, index) => (
                          <div key={index} className="image-container">
                            <img
                              className="preview-image"
                              src={image.url}
                              alt={`Preview ${index}`}
                              onClick={() => showImage(image.url)}
                            />
                          </div>
                        ))}
                      </div>
                    )}
                  </React.Fragment>
                ))
              ) : (
                <div className="notes_textarea input_style_2">
                  <div>No notes available.</div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* -----Add-note-modal-------- */}
      <ModalBox
        show={modal}
        onClose={() => {
          setModal(false);
          setAddNote("");
          setError("");
          setImages("");
        }}
        heading={"Add Note"}
        customizedFooter={
          <div className="d-flex ">
            <div className="mx-2 ">
              <button
                className="modalButtonClose btn btn-secondary"
                onClick={() => {
                  setModal(false);
                  setAddNote("");
                  setError("");
                  setImages("");
                }}
              >
                Cancel
              </button>
            </div>
            <div className="mx-2">
              <button
                className="modalButtonSubmit modalButtonYes btn btn-primary"
                ref={buttonRef}
                onClick={addNotes}
              >
                Save
              </button>
            </div>
          </div>
        }
      >
        <div className="note-input">
          <textarea
            className={`form-control textArea ${!addNote && error.addNote && "is-invalid"
              }`}
            name="addNote"
            value={addNote}
            placeholder="Write your note here..."
            onChange={(e) => setAddNote(e.target.value)}
          />
          <div className="invalid-feedback fs-6">{error.addNote}</div>
        </div>
        <div className="col-">
          <label htmlFor="images" className="col-form-label">
            Images
          </label>
          <input
            type="file"
            // className="form-control mt-0"
            className={`form-control mt-0 ${error.images && "is-invalid"}`}

            name="images"
            id="images"
            onChange={(e) => imageHandler(e.target.files)} // Change
            accept="image/*"
            multiple
          />
          <div className="invalid-feedback fs-6">{error.images}</div>
        </div>
        {images.length > 0 && (
          <div className="image-preview mt-3">
            {images.map((image, index) => (
              <React.Fragment key={index}>
                <div key={index} className="image-container">
                  <img
                    className="preview-image cursorNone"
                    src={URL.createObjectURL(image)}
                    alt={`Preview ${index}`}
                  />
                  <TiDelete
                    className="remove-button fs-1"
                    onClick={() => removeImage(index)}
                  />
                </div>
              </React.Fragment>
            ))}
          </div>
        )}
      </ModalBox>

{/*-------------update-Modale------------------------- */}
      <ModalBox
        show={updateModal}
        onClose={() => {
          setUpdateModal(false);
          setImages("");
        }}
        heading={"Update Note"}
        customizedFooter={
          <div className="d-flex ">
            <div className="mx-2 ">
              <button
                className="modalButtonClose btn btn-secondary"
                onClick={() => {
                  setUpdateModal(false);
                  setImages("");
                }}
              >
                Cancel
              </button>
            </div>
            <div className="mx-2">
              <button
                className="modalButtonSubmit modalButtonYes btn btn-primary "
                ref={buttonRef}
                onClick={updateNote}
              >
                Save
              </button>
            </div>
          </div>
        }
      >
        <div className="note-input">
          <textarea
            className={`form-control textArea ${!note && error.note && "is-invalid"
              }`}
            placeholder="Write your note here..."
            name="note"
            value={note}
            onChange={(e) => setNote(e.target.value)}
          />
          <div className="invalid-feedback fs-6">{error.note}</div>
        </div>

        <div className="col-">
          <label htmlFor="images" className="col-form-label">
            Images
          </label>
          <input
            type="file"
            className={`form-control mt-0 ${images && error.images && "is-invalid"}`}
            name="images"
            id="images"
            onChange={(e) => imageHandler(e.target.files)} // Change
            accept="image/*"
            multiple
          />
          <div className="invalid-feedback fs-6">{error.images}</div>
        </div>
        {images.length > 0 && (
          <div className="image-preview mt-3">
            {images.map((image, index) => (
              <React.Fragment key={index}>
                <div key={index} className="image-container">
                  <img
                    className="preview-image cursorNone"
                    src={URL.createObjectURL(image)}
                    alt={`Preview ${index}`}
                  />
                  <TiDelete
                    className="remove-button fs-1"
                    onClick={() => removeImage(index)}
                  />
                </div>
              </React.Fragment>
            ))}
          </div>
        )}
        {imageUpdate.length > 0 && (
          <div className="image-preview mt3">
            {imageUpdate.map((image, index) => (
              <React.Fragment key={index}>
                <div key={index} className="image-container">
                  <img
                    className="preview-image cursorNone"
                    src={image.url}
                    alt={`Preview ${index}`}
                  />
                  <TiDelete
                    className="remove-button fs-1"
                    onClick={() => removeImageUpdate(index, image._id)}
                  />
                </div>
              </React.Fragment>
            ))}
          </div>
        )}
      </ModalBox>
{/*-------------delete-Modale-note-------------------- */}
      <ModalBox
        show={deleteModal}
        onClose={() => setDeleteModal(false)}
        heading={"Delete"}
        description={"Are you sure want to delete this note?"}
        action={deleteNote}
        buttonRef={buttonRef}
        />
{/* ---------Show-image-in-Modal---------------------- */}
      <ModalBox
        show={showImageModal}
        onClose={() => setShowImageModal(false)}
        customizedFooter={
          <button
            className="modalButtonClose btn btn-secondary"
            onClick={() => {
              setShowImageModal(false)
            }}
          > Cancel </button>
        }
      >
        <img
          className="preview-image"
          src={selectedImage}
          alt={selectedImage}
          onClick={()=>setShowImageModal(false)}
        />
      </ModalBox>

      <ReturnInventoryMobile
        returnModal={returnModal}
        modalClose={() => setReturnModal(false)}
        listing={fetchInventoryDeatils}
        employeeId={employeeId}
        inventoryId={inventoryId}
      />
    </>
  );
}

export default ViewInventoryMobile;

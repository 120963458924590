import React from "react";
import useButtonLoader from "../../../Hooks/UseButtonLoader";
import axios from "axios";
import { toast } from "react-toastify";
import ModalBox from "../../../Modals/ModalBox";

function StatusChangeDesignation({
  statusId,
  status,
  statusModal,
  modalClose,
  listing,
}) {
  const [buttonRef, loading] = useButtonLoader("Yes");

  const changeStatusDesignation = async () => {
    // console.log("checkStatus",status)
    try {
      loading(true);
      const token = localStorage.getItem("demo-token");
      const response = await axios.put(
        `${process.env.REACT_APP_SECRET_KEY}/designation-status/${statusId}`,
        {
          status: !status,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    //   console.log("rsp-userstatus", response);
      if (response.data.status === 200) {
        loading(false);
        modalClose();
        listing();
        toast.success(response.data.message);
      } else {
        loading(false);
        toast.error(response.data.message);
      }
    } catch (error) {
      loading(false);
      console.log(error);
    }
  };

  return (
    <div>
      <ModalBox
        show={statusModal}
        onClose={modalClose}
        heading={"Change Status"}
        description={"Are you sure want to change the status?"}
        action={changeStatusDesignation}
        buttonRef={buttonRef}
      />
    </div>
  );

}

export default StatusChangeDesignation;

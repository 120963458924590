const sidebar = [
  {
    id: 101,
    icon: "fa fa-tachometer",
    name: "Dashboard",
    path: "/dashboard",
    enable: true,
    isChild: null,
  },
  {
    id: 102,
    icon: "fa fa-user",
    name: "Employee Management",
    path: "/employee-Management",
    enable: true,
    isChild: null,
  },
  {
    id: 103,
    icon: "fa fa-product-hunt",
    name: "Project Management",
    path: "/project-management",
    enable: true,
    isChild: null,
  },
  {
    id: 104,
    icon: "fa fa-truck",
    name: "Delivery Tracking",
    path: "/delivery-tracking",
    enable: true,
    isChild: null,
  },
  {
    id: 105,
    icon: "fa fa-users",
    name: "Resource Management",
    path: "/resource-management",
    enable: true,
    isChild: null,
  },
  // {
  //   id: 106,
  //   icon: "fa fa-archive",
  //   name: "Inventory Management",
  //   path: "/inventory-management",
  //   enable: true,
  //   isChild: null,
  // },
  // {
  //   id: 107,
  //   icon: "fa fa-mobile",
  //   name: "Mobile",
  //   path: "/mobile-inventory",
  //   enable: true,
  //   isChild: null,
  // },
  {
    id: 106,
    icon: "fa fa-archive",
    name: "Inventory Management",
    path: "",
    enable: true,
    isChild: [
      {
        id: 107,
        icon: "fa fa-archive",
        name: "All Inventory",
        path: "/inventory-management",
        enable: true,
      },
      {
        id: 108,
        icon: "fa fa-mobile",
        name: "Mobile",
        path: "/mobile-inventory",
        enable: true,
      },
      {
        id: 109,
        icon: "fa fa-laptop",
        name: "Laptop",
        path: "/laptop-inventory",
        enable: true,
      },
     
    ],
  },

  {
    id: 110,
    icon: "fa fa-database",
    name: "Master Data",
    path: "",
    enable: true,
    isChild: [
      {
        id: 111,
        icon: "fa fa-building-o",
        name: "Department",
        path: "/department",
        enable: true,
      },
      {
        id: 112,
        icon: "fa fa-suitcase",
        name: "Designation",
        path: "/designation",
        enable: true,
      },
      {
        id: 113,
        icon: "fa fa-american-sign-language-interpreting",
        name: "Category",
        path: "/category-management",
        enable: true,
      },
      {
        id: 114,
        icon: "fa fa-laptop",
        name: "Technology",
        path: "/technology",
        enable: true,
      },
    ],
  },
  {
    id: 115,
    icon: "fa fa-archive",
    name: "Access Management",
    path: "/access-management",
    enable: true,
    isChild: null,
  },
  {
    id: 116,
    icon: "fa fa-cog",
    name: "Change Password",
    path: "/change-password",
    enable: true,
    isChild: null,
  },
];

export default sidebar.filter((sidebare) => sidebare.enable);

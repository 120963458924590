import Form from "../pages/employeeManagement/Form";
import Dashboard from "../pages/dashboard/Dashboard";
import EmpolyeeManagement from "../pages/employeeManagement/EmpolyeeManagement";
import UpdateForm from "../pages/employeeManagement/UpdateForm";
import DeleteEmploye from "../pages/employeeManagement/DeleteEmploye";
import ProjectManagement from "../pages/projectManagement/ProjectManagement";
import ChangePassword from "../pages/auth/ChangePassword";
import Department from "../pages/masterData/department/Department";
import DeleteDepartment from "../pages/masterData/department/DeleteDepartment";
import UpdateDepartment from "../pages/masterData/department/UpdateDepartment";
import StatusChange from "../pages/masterData/department/StatusChange";
import AddDepartment from "../pages/masterData/department/AddDepartment";
import Designation from "../pages/masterData/designation/Designation";
import StatusChangeDesignation from "../pages/masterData/designation/StatusChangeDesignation";
import UpdateDesignation from "../pages/masterData/designation/UpdateDesignation";
import DeleteDesignation from "../pages/masterData/designation/DeleteDesignation";
import AddDesignation from "../pages/masterData/designation/AddDesignation";
import AddProject from "../pages/projectManagement/AddProjectManagement/AddProject";
import UpdateProject from "../pages/projectManagement/UpdateProjectManagement/UpdateProject";
import ViewProject from "../pages/projectManagement/viewProject/ViewProject";
import DeliveryTracking from "../pages/ deliverytracking/ DeliveryTracking";
import ResourceManagement from "../pages/resourceManagement/ResourceManagement";
import ViewForm from "../pages/employeeManagement/ViewForm";
import InventoryManagement from "../pages/inventoryManagement/InventoryManagement";
import CategoryManagement from "../pages/categoryManagement/CategoryManagement";
import AddCategory from "../pages/categoryManagement/AddCategory";
import AddInventory from "../pages/inventoryManagement/addInventory/AddInventory";
import UpdateCategory from "../pages/categoryManagement/UpdateCategory";
import UpdateInventory from "../pages/inventoryManagement/UpdateInventory";
import ViewInventory from "../pages/inventoryManagement/ViewInventory";
import ViewResourceDetails from "../pages/resourceManagement/ViewResourceDetails";
import Technology from "../pages/masterData/technology/Technology";
import DeleteTechnology from "../pages/masterData/technology/DeleteTechnology";
import StatusChangeTechnology from "../pages/masterData/technology/StatusChangeTechnology";
import UpdateTechnology from "../pages/masterData/technology/UpdateTechnology";
import AddTechnology from "../pages/masterData/technology/AddTechnology";
import AccessManagement from "../pages/accessManagement/AccessManagement";
import AddAccess from "../pages/accessManagement/AddAccess";
import UpdateAccess from "../pages/accessManagement/UpdateAccess";
import DeleteAccess from "../pages/accessManagement/DeleteAccess";
import MobileInventory from "../pages/mobileInventory/MobileInventory";
import LaptopInventory from "../pages/laptopInventory/LaptopInventory";
import AddInventoryMobile from "../pages/mobileInventory/AddInventoryMobile";
import UpdateInventoryMobile from "../pages/mobileInventory/UpdateInventoryMobile";
import ViewInventoryMobile from "../pages/mobileInventory/ViewInventoryMobile";
import AddInventoryLaptop from "../pages/laptopInventory/AddInventoryLaptop";
import UpdateInventoryLaptop from "../pages/laptopInventory/UpdateInventoryLaptop";
import ViewInventoryLaptop from "../pages/laptopInventory/ViewInventoryLaptop";
// import Logout from "../pages/auth/Logout";

const routes = [
  {
    id: 101,
    path: "/dashboard",
    element: <Dashboard />,
    enable: true,
  },
  {
    id: 102,
    path: "/employee-Management",
    element: <EmpolyeeManagement />,
    enable: true,
  },
  {
    id: 103,
    path: "/project-management",
    element: <ProjectManagement />,
    enable: true,
  },
  {
    id: 104,
    path: "/department",
    element: <Department />,
    enable: true,
  },
  {
    id: 105,
    path: "/designation",
    element: <Designation />,
    enable: true,
  },
  {
    id: 106,
    path: "/change-password",
    element: <ChangePassword />,
    enable: true,
  },

  //---------------employee-management---------------------

  {
    id: 107,
    path: "/employee-Management/form",
    element: <Form />,
    enable: true,
  },
  {
    id: 108,
    path: "/employee-Management/update-form/:userId",
    element: <UpdateForm />,
    enable: true,
  },
  {
    id: 109,
    path: "/employee-Management/delete-employee/:userId",
    element: <DeleteEmploye />,
    enable: true,
  },

  {
    id: 110,
    path: "/employee-Management/view-form/:userId",
    element: <ViewForm />,
    enable: true,
  },

  //--------------CURD-department----------------------
  {
    id: 111,
    path: "/department/change-status/:userId",
    element: <StatusChange />,
    enable: true,
  },
  {
    id: 112,
    path: "/department/update/:userId",
    element: <UpdateDepartment />,
    enable: true,
  },
  {
    id: 113,
    path: "/department/delete/:userId",
    element: <DeleteDepartment />,
    enable: true,
  },
  {
    id: 114,
    path: "/department/Add",
    element: <AddDepartment />,
    enable: true,
  },

  //--------------CURD-desiganation----------------------
  {
    id: 115,
    path: "/technology/change-status/:userId",
    element: <StatusChangeDesignation />,
    enable: true,
  },
  {
    id: 116,
    path: "/technology/update/:userId",
    element: <UpdateDesignation />,
    enable: true,
  },
  {
    id: 117,
    path: "/technology/delete/:userId",
    element: <DeleteDesignation />,
    enable: true,
  },
  {
    id: 118,
    path: "/technology/Add",
    element: <AddDesignation />,
    enable: true,
  },
  //--------------CURD-Technology----------------------
  {
    id: 105,
    path: "/technology",
    element: <Technology />,
    enable: true,
  },
  {
    id: 119,
    path: "/technology/delete/:userId",
    element: <DeleteTechnology />,
    enable: true,
  },
  {
    id: 120,
    path: "/technology/change-status/:userId",
    element: <StatusChangeTechnology />,
    enable: true,
  },
  {
    id: 121,
    path: "/technology/update/:userId",
    element: <UpdateTechnology />,
    enable: true,
  },

  {
    id: 122,
    path: "/technology/Add",
    element: <AddTechnology />,
    enable: true,
  },
  //--------------project-desiganation----------------------
  {
    id: 123,
    path: "/project-management/add-project",
    element: <AddProject />,
    enable: true,
  },
  {
    id: 124,
    path: "/project-management/update-project/:userId",
    element: <UpdateProject />,
    enable: true,
  },
  {
    id: 125,
    path: "/project-management/view-project/:userId",
    element: <ViewProject />,
    enable: true,
  },

  //------------Delivery-Tracking-----------------
  {
    id: 126,
    path: "/delivery-tracking",
    element: <DeliveryTracking />,
    enable: true,
  },
  //------------Resource-Management-----------------
  {
    id: 127,
    path: "/resource-management",
    element: <ResourceManagement />,
    enable: true,
  },
  {
    id: 128,
    path: "/resource-management/view-details/:resourceId",
    element: <ViewResourceDetails />,
    enable: true,
  },
  //------------Inventory-Management-----------------
  {
    id: 129,
    path: "/inventory-management",
    element: <InventoryManagement />,
    enable: true,
  },
  {
    id: 130,
    path: "/inventory-management/add-inventory",
    element: <AddInventory />,
    enable: true,
  },
  {
    id: 131,
    path: "/inventory-management/update-inventory/:inventoryId",
    element: <UpdateInventory />,
    enable: true,
  },
  {
    id: 132,
    path: "/inventory-management/view-inventory/:inventoryId",
    element: <ViewInventory />,
    enable: true,
  },

  //------------Category-Management-----------------
  {
    id: 133,
    path: "/category-management",
    element: <CategoryManagement />,
    enable: true,
  },
  {
    id: 134,
    path: "/category-management/add-category",
    element: <AddCategory />,
    enable: true,
  },
  {
    id: 135,
    path: "/category-management/Update-category/:categoryId",
    element: <UpdateCategory />,
    enable: true,
  },
  //------------Category-Management-----------------
  {
    id: 136,
    path: "/Access-management",
    element: <AccessManagement />,
    enable: true,
  },
  {
    id: 137,
    path: "/Access-management/delete/:userId",
    element: <DeleteAccess />,
    enable: true,
  },
  {
    id: 138,
    path: "/Access-management/update/:userId",
    element: <UpdateAccess />,
    enable: true,
  },

  {
    id: 139,
    path: "/Access-management/Add",
    element: <AddAccess />,
    enable: true,
  },
  //------------mobile-inventory-----------------
  {
    id: 140,
    path: "/mobile-inventory",
    element: <MobileInventory />,
    enable: true,
  },
  {
    id: 120,
    path: "/mobile-inventory/add-inventory",
    element: <AddInventoryMobile />,
    enable: true,
  },
  {
    id: 121,
    path: "/mobile-inventory/update-inventory-mobile/:inventoryId",
    element: <UpdateInventoryMobile />,
    enable: true,
  },
  {
    id: 122,
    path: "/mobile-inventory/view-inventory-mobile/:inventoryId",
    element: <ViewInventoryMobile />,
    enable: true,
  },
  //------------laptop-inventory-----------------
  {
    id: 140,
    path: "/laptop-inventory",
    element: <LaptopInventory />,
    enable: true,
  },
  {
    id: 121,
    path: "/laptop-inventory/add-inventory-laptop",
    element: <AddInventoryLaptop />,
    enable: true,
  },
  {
    id: 121,
    path: "/laptop-inventory/update-inventory-laptop/:inventoryId",
    element: <UpdateInventoryLaptop />,
    enable: true,
  },
  {
    id: 121,
    path: "/laptop-inventory/view-inventory-laptop/:inventoryId",
    element: <ViewInventoryLaptop />,
    enable: true,
  },

];

export default routes.filter((rout) => rout.enable);

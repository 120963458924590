import React from "react";
import useButtonLoader from "../../Hooks/UseButtonLoader";
import ModalBox from "../../Modals/ModalBox";
import { toast } from "react-toastify";
import axios from "axios";

function ChangeStatusSendEmail({
  emailId,
  sendEmailStatus,
  emailModal,
  modalClose,
  listing,
}) {
  const [buttonRef, loading] = useButtonLoader("Yes");

  //----------------Change-status-API--------------------------
  const changeStatusEmail = async () => {
    try {
      loading(true);
      const token = localStorage.getItem("demo-token");
      const response = await axios.put(
        `${process.env.REACT_APP_SECRET_KEY}/employee-mail-status/${emailId}`,
        {
          sendEmailStatus: !sendEmailStatus,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.status === 200) {
        loading(false);
        modalClose();
        listing();
        toast.success(response.data.message);
      } else {
        loading(false);
        toast.error(response.data.message);
      }
    } catch (error) {
      loading(false);
      console.log(error);
    }
  };
  return (
    <>
      <ModalBox
        show={emailModal}
        onClose={modalClose}
        heading="Change Status"
        description="Are you sure want to change the status?"
        action={changeStatusEmail}
        buttonRef={buttonRef}
      />
    </>
  );
}

export default ChangeStatusSendEmail;

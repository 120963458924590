import React, { useEffect, useState } from "react";
import { BiSolidAddToQueue } from "react-icons/bi";
import { TiDelete } from "react-icons/ti";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";

function FixCostProject({
  row,
  setRow,
  onChangefixCost,
  projectAnalysis,
  error,
}) {
  const [employee, setEmployee] = useState([]);
  const handleAddRow = () => {
    const newRow = {
      id: Date.now(),
      milestoneType: "",
      milestonePhase: "",
      milestoneName: "",
      projectAnalyst: "",
      startDate: "",
      endDate: "",
      testingDate: "",
      deliveryDate: "",
      pdf: "",
    };
    setRow([...row, newRow]);
  };

  const handleRemoveRow = (id) => {
    const updateRow = row.filter((repeat) => {
      return repeat.id !== id;
    });
    setRow(updateRow);
  };

  let analysis = row.map((data) => data.milestonePhase);
  //----------------assign-person-API--------------------------
  const fecthemployee = async () => {
    try {
      const token = localStorage.getItem("demo-token");
      const response = await axios.get(
        `${process.env.REACT_APP_SECRET_KEY}/master-data-employee`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      //   console.log("departmentValue", response.data.data.employees);
      if (response.data.status === 200) {
        setEmployee(response.data.data.employees);
      } else {
        // toast.error(response.data.message);
      }
    } catch (err) {
      console.log("form", err);
    }
  };
  useEffect(() => {
    fecthemployee();
  }, []);
  return (
    <>
      <div className="Small-Wrapper">
        <h6 className="Title my-1">Milestones</h6>
      
        <div className="Small-Wrapper mt-2">
          <div className="milestonRemoveButton cstm_css_hide mt-2">
            <TiDelete className="fs-3 color" />
          </div>
          <div className="row">
            <div className="col-1 cstm_cards_validate">
              <label htmlFor="milestoneType" className="col-form-label">
                Milestone Type
              </label>
              <br />
            </div>
            <div className="col-1 cstm_cards_validate">
              <label htmlFor="milestonePhase" className="col-form-label">
                Milestone Phase
              </label>
            </div>
            <div className="col-1 cstm_cards_validate">
              <label htmlFor="milestoneName" className="col-form-label">
                Milestone Name
              </label>
              <br />
            </div>
            <div className="col-1 cstm_cards_validate">
              <label htmlFor="startDate" className="col-form-label">
                Start Date
              </label>
              <br />
            </div>
            <div className="col-1 cstm_cards_validate">
              <label htmlFor="endDate" className="col-form-label">
                End Date
              </label>
              <br />
            </div>
            <div className="col-1 cstm_cards_validate">
              <label htmlFor="testingDate" className="col-form-label">
                Testing Date
              </label>
              <br />
            </div>
            <div className="col-1 cstm_cards_validate">
              <label htmlFor="deliveryDate" className="col-form-label">
                Delivery Date
              </label>
              <br />
            </div>

            {/* {analysis.includes("Analysis") ? (
              <div className="col-1 cstm_cards_validate">
                <label htmlFor="Analysis" className="col-form-label">
                  Project Analysis
                </label>
                <br />
              </div>
            ) : null} */}
          </div>
          <div className="milestonRemoveButton mt-">
            <BiSolidAddToQueue className="fs-3 color" onClick={handleAddRow} />
          </div>
          {row.map((repeat, index) => {
            return (
              <React.Fragment key={repeat.id}>
                {index !== 0 && (
                  <div className="milestonRemoveButton">
                    <TiDelete
                      className="fs-3 color"
                      onClick={() => handleRemoveRow(repeat.id)}
                    />
                  </div>
                )}

                <form className="needs-validation mb-2 ">
                  <div className="row">
                    <div className="col-1 cstm_cards_validate">
                      <select
                        className={`inputArea custm-form-control ${
                          !repeat.milestoneType &&
                          error.milestoneType &&
                          "is-invalid"
                        }`}
                        name="milestoneType"
                        id="milestoneType"
                        onChange={(e) =>
                          onChangefixCost(
                            repeat.id,
                            e.target.name,
                            e.target.value
                          )
                        }
                        value={repeat.milestoneType}
                      >
                        <option value=""> Milestone Type</option>
                        <option value="Milestone">Milestone</option>
                        <option value="CR">CR</option>
                      </select>
                      {/* show validation's error msg */}
                      <span className="invalid-feedback">
                        {error.milestoneType}
                      </span>
                    </div>
                    <div className="col-1 cstm_cards_validate">
                      <select
                        className={`inputArea custm-form-control ${
                          !repeat.milestonePhase &&
                          error.milestonePhase &&
                          "is-invalid"
                        }`}
                        name="milestonePhase"
                        id="milestonePhase"
                        onChange={(e) =>
                          onChangefixCost(
                            repeat.id,
                            e.target.name,
                            e.target.value
                          )
                        }
                        value={repeat.milestonePhase}
                      >
                        <option value="">Milestone Phase</option>
                        <option value="Analysis">Analysis</option>
                        <option value="Development">Development</option>
                      </select>
                      <span className="invalid-feedback">
                        {error.milestoneType}
                      </span>
                    </div>
                    <div className="col-1 cstm_cards_validate">
                      <select
                        className={`inputArea custm-form-control ${
                          !repeat.milestoneName &&
                          error.milestoneName &&
                          "is-invalid"
                        }`}
                        name="milestoneName"
                        id="milestoneName"
                        onChange={(e) =>
                          onChangefixCost(
                            repeat.id,
                            e.target.name,
                            e.target.value
                          )
                        }
                        value={repeat.milestoneName}
                      >
                        <option value="">Milestone Name</option>

                        {repeat.milestoneType === "Milestone" ? (
                          <>
                            <option value="M1">M1</option>
                            <option value="M2">M2</option>
                            <option value="M3">M3</option>
                            <option value="M4">M4</option>
                            <option value="M5">M5</option>
                          </>
                        ) : (
                          <>
                            <option value="C1">C1</option>
                            <option value="C2">C2</option>
                            <option value="C3">C3</option>
                            <option value="C4">C4</option>
                            <option value="C5">C5</option>
                          </>
                        )}
                      </select>
                      <span className="invalid-feedback">
                        {error.milestoneName}
                      </span>
                    </div>

                    <div className="col-1 cstm_cards_validate">
                      <DatePicker
                        placeholderText="Start Date"
                        className={`inputArea form-control ${
                          !repeat.startDate && error.startDate && "is-invalid"
                        }`}
                        name="startDate"
                        id="startDate"
                        selected={repeat.startDate}
                        value={repeat.startDate}
                        showYearDropdown
                        yearDropdownItemNumber={100}
                        scrollableYearDropdown
                        showMonthDropdown
                        useShortMonthInDropdown
                        onChange={(date) =>
                          onChangefixCost(repeat.id, "startDate", date)
                        }
                        dateFormat="yyyy-MM-dd"
                        maxDate={repeat.endDate}
                      />
                      {!repeat.startDate && error.startDate && (
                        <p className="datePicker_validation_small">
                          {error.startDate}
                        </p>
                      )}
                    </div>
                    <div className="col-1 cstm_cards_validate">
                      <DatePicker
                        placeholderText="End Date"
                        className={`inputArea form-control ${
                          !repeat.endDate && error.endDate && "is-invalid"
                        }`}
                        name="endDate"
                        id="endDate"
                        selected={repeat.endDate}
                        value={repeat.endDate}
                        showYearDropdown
                        yearDropdownItemNumber={100}
                        scrollableYearDropdown
                        showMonthDropdown
                        useShortMonthInDropdown
                        onChange={(date) =>
                          onChangefixCost(repeat.id, "endDate", date)
                        }
                        dateFormat="yyyy-MM-dd"
                        minDate={repeat.startDate}
                      />
                      {!repeat.endDate && error.endDate && (
                        <p className="datePicker_validation_small">
                          {error.endDate}
                        </p>
                      )}
                    </div>
                    <div className="col-1 cstm_cards_validate">
                      <DatePicker
                        placeholderText="Testing Date"
                        className={`inputArea form-control ${
                          !repeat.testingDate &&
                          error.testingDate &&
                          "is-invalid"
                        }`}
                        name="testingDate"
                        id="testingDate"
                        selected={repeat.testingDate}
                        value={repeat.testingDate}
                        showYearDropdown
                        yearDropdownItemNumber={100}
                        scrollableYearDropdown
                        showMonthDropdown
                        useShortMonthInDropdown
                        onChange={(date) =>
                          onChangefixCost(repeat.id, "testingDate", date)
                        }
                        dateFormat="yyyy-MM-dd"
                        minDate={repeat.startDate}
                        maxDate={repeat.endDate}
                        disabled={!repeat.startDate}
                      />
                      {!repeat.testingDate && error.testingDate && (
                        <p className="datePicker_validation_small">
                          {error.testingDate}
                        </p>
                      )}
                    </div>
                    <div className="col-1 cstm_cards_validate">
                      <DatePicker
                        placeholderText="Delivery Date"
                        className={`inputArea form-control ${
                          !repeat.deliveryDate &&
                          error.deliveryDate &&
                          "is-invalid"
                        }`}
                        name="deliveryDate"
                        id="deliveryDate"
                        selected={repeat.deliveryDate}
                        value={repeat.deliveryDate}
                        showYearDropdown
                        yearDropdownItemNumber={100}
                        scrollableYearDropdown
                        showMonthDropdown
                        useShortMonthInDropdown
                        onChange={(date) =>
                          onChangefixCost(repeat.id, "deliveryDate", date)
                        }
                        dateFormat="yyyy-MM-dd"
                        disabled={!repeat.endDate}
                        minDate={repeat.endDate}
                      />
                      {!repeat.deliveryDate && error.deliveryDate && (
                        <p className="datePicker_validation_small">
                          {error.deliveryDate}
                        </p>
                      )}
                    </div>
                    {/* {repeat.milestonePhase === "Analysis" ? (
                      <div className="col-1 cstm_cards_validate">
                        <select
                          className={`inputArea custm-form-control ${
                            !repeat.milestoneAnalyst &&
                            error.milestoneAnalyst &&
                            "is-invalid"
                          }`}
                          name="milestoneAnalyst"
                          id="Analysis"
                          onChange={(e) =>
                            onChangefixCost(
                              repeat.id,
                              e.target.name,
                              e.target.value
                            )
                          }
                          value={repeat.milestoneAnalyst}
                        >
                          <option value="">Project Analysis</option>
                          {employee &&
                            employee.map(({ _id, name }, index) => {
                              return (
                                <React.Fragment key={index}>
                                  <option value={_id}>{name}</option>
                                </React.Fragment>
                              );
                            })}
                        </select>
                        <span className="invalid-feedback">
                          {error.milestoneAnalyst}
                        </span>
                      </div>
                    ) : null} */}
                    {/* <div className="col-2 cstm_cards_validate mt-2">
                      <input
                        type="file"
                        accept=".pdf"
                        name="pdf"
                        onChange={(e) =>
                          onChangefixCost(
                            repeat.id,
                            e.target.name,
                            e.target.value
                          )
                        }
                      />
                    </div> */}
                  </div>
                </form>
              </React.Fragment>
            );
          })}
        </div>
      </div>
    
    </>
  );
}
export default FixCostProject;

import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { TiDelete } from "react-icons/ti";
import useButtonLoader from "../../../Hooks/UseButtonLoader";
import usePageLoader from "../../../Hooks/UsePageLoader";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import ModalBox from "../../../Modals/ModalBox";

function AddInventory() {
  const [categories, setCategories] = useState("");
  const [inputData, setInputData] = useState({});
  const [purchaseValue, setPurchaseValue] = useState("");
  const [deviceIdValue, setDeviceIdValue] = useState("");
  const [workStatus, setWorkStatus] = useState("");
  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const [inputFields, setInputFields] = useState([]);
  const [labelValue, setLabelValue] = useState([""]);
  const [buttonRef, loding] = useButtonLoader("Submit");
  const [images, setImages] = useState([]);
  const [imageUlr, setImageUrl] = useState("");
  const [loder, show, hide] = usePageLoader();
  const navigator = useNavigate();
  const [error, setError] = useState({});
  const [importModal, setImportModal] = useState(false);
  const [importError, setImportError] = useState({});
  const [file, setFile] = useState("");
  const navigate = useNavigate();
  const ALLOWED_EXTENSIONS = /\.(xls[xm]?|csv)$/i;
  const alphNumeric = /^[a-zA-Z0-9]+$/;


  const validation = () => {
    let errorMessage = {};
    let status = true;

    labelValue.forEach((label, index) => {
      const inputValue = inputFields[index]?.value; // Get the value of the input field
      if (!inputValue) {
        errorMessage[`attribute-${index}`] = `${label.name} is required`;
        status = false;
      }
    });

    if (!deviceIdValue) {
      errorMessage["deviceId"] = "Device Id is required";
      errorMessage.status = false;
    } else if (!alphNumeric.test(deviceIdValue)) {
      errorMessage["deviceId"] = "Please Enter Valid device id";
      errorMessage.status = false;
    }

    if (!selectedCategoryId) {
      errorMessage["selectedCategoryId"] = "Category is required";
      status = false;
    }

    if (!purchaseValue) {
      errorMessage["purchaseDate"] = "Purchase Date is required";
      status = false;
    }
    if (!workStatus) {
      errorMessage["workStatus"] = "Work status is required";
      status = false;
    }

    setError(errorMessage);
    return status;
  };

  const importValidation = () => {
    let errorMessage = {};
    let status = true;

    if (!file[0]) {
      errorMessage["excelFile"] = "This fiel is required";
      status = false;
    } else if (!ALLOWED_EXTENSIONS.test(file[0].name)) {
      errorMessage["excelFile"] = "Invalid file format";
      status = false;
    }
    setImportError(errorMessage);
    return status;
  };

  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    if (name === "categoryId") {
      setSelectedCategoryId(value);
    }
    setInputData({ ...inputData, [name]: value });
  };

  const handleInputChange = (event, index, inputType) => {
    const { name, value } = event.target;
    const updatedInputFields = [...inputFields];
    updatedInputFields[index] = {
      ["name"]: name,
      ["value"]: value,
      ["inputType"]: inputType,
    };
    setInputFields(updatedInputFields);
    setError((prevError) => ({ ...prevError, [`attribute-${index}`]: "" }));
  };

  function imageHandler(files) {
    const selectedImages = Array.from(files);
    setImages((prevImages) => [...prevImages, ...selectedImages]); // Append to the existing images
  }

  const removeImage = (index) => {
    const updatedImages = [...images];
    updatedImages.splice(index, 1);
    setImages(updatedImages);
  };

  //----------------getch-Category-name-API--------------------------
  const fetchCategoryName = async () => {
    try {
      const token = localStorage.getItem("demo-token");
      const response = await axios.get(
        `${process.env.REACT_APP_SECRET_KEY}/master-data-category`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.status === 200) {
        setCategories(response.data.data.categorys);
      } else {
        toast.error(response.data.message);
      }
    } catch (err) {
      console.log("form", err);
    }
  };

  //-----------get-value-category-Api----------------------

  const fetchCategory = async () => {
    try {
      show();
      const token = localStorage.getItem("demo-token");
      const response = await axios.get(
        `${process.env.REACT_APP_SECRET_KEY}/category-details/${selectedCategoryId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.status === 200) {
        hide();
        //-------------get-Category-Name----------------
        const [categoryValue] = response.data.data.detailes;
        const attributeAllValue = categoryValue.attributes.map(
          ({ name, inputType }) => {
            return {
              name: name,
              inputType: inputType,
            };
          }
        );
        setLabelValue(attributeAllValue);
      } else {
        hide();
        toast.error(response.data.message);
      }
    } catch (err) {
      console.log("form", err);
    }
  };
  //---------------import-excel-file-API-----------------
  const importExcel = async () => {
    const status = importValidation();
    if (status) {
      try {
        loding(true);
        const token = localStorage.getItem("demo-token");
        const formdata = new FormData();
        formdata.append("file", file[0]);
        const response = await axios.post(
          `${process.env.REACT_APP_SECRET_KEY}/importInventory`,
          formdata,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.data.status === 200) {
          loding(false);
          setImportModal(false);
          navigate("/inventory-Management");
          toast.success(response.data.message);
        } else {
          loding(false);
          toast.error(response.data.message);
        }
      } catch (error) {
        loding(false);
        console.log(error);
      }
    }
  };

  //-----------Add-value-inventory-Api----------------------
  const addInventory = async () => {
    const status = validation();
    if (status) {
      try {
        loding(true);
        const token = localStorage.getItem("demo-token");
        const purchaseDate = moment(purchaseValue).format("YYYY-MM-DD");
        const formdata = new FormData();
        formdata.append("categoryId", selectedCategoryId);
        formdata.append("deviceId", deviceIdValue);
        formdata.append("workingStatus", workStatus);
        formdata.append("purchaseDate", purchaseDate);
        formdata.append("attributes", JSON.stringify(inputFields));
        for (let index = 0; index < images.length; index++) {
          formdata.append("images", images[index]);
        }
        const response = await axios.post(
          `${process.env.REACT_APP_SECRET_KEY}/inventory-create`,
          formdata,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.data.status === 200) {
          loding(false);
          navigator("/inventory-Management");
          // modalClose();
          // listing();
          toast.success(response.data.message);
        } else {
          loding(false);
          toast.error(response.data.message);
        }
      } catch (error) {
        loding(false);
        console.log(error);
      }
    }
  };

  useEffect(() => {
    fetchCategoryName();
  }, []);

  useEffect(() => {
    if (selectedCategoryId) {
      fetchCategory();
    }
  }, [selectedCategoryId]);

  return (
    <>
      {loder}
      <div className="WrapperArea">
        <div className="WrapperBox">
          <div className="Small-Wrapper">
            <h4 className="Title">
              <span>
                <Link to="/inventory-Management">
                  <i className="fa fa-arrow-left" aria-hidden="true"></i>
                </Link>
              </span>
              Add Inventory
            </h4>
            <select
              name="categoryId"
              id="category"
              onChange={onChangeHandler}
              className={
                error["selectedCategoryId"]
                  ? "custm-form-control is-invalid cstm_form_control_category"
                  : "custm-form-control cstm_form_control_category"
              }
            >
              <option value="">Select Type</option>
              {categories &&
                categories.map(({ categoryName, _id }, idindex) => {
                  return (
                    <React.Fragment key={idindex}>
                      <option value={_id}>{categoryName}</option>
                    </React.Fragment>
                  );
                })}
            </select>
            {error["selectedCategoryId"] && (
              <div className="invalid-feedback">
                {error["selectedCategoryId"]}
              </div>
            )}

            {selectedCategoryId && (
              <>
                <div className="cstmSearchBox cstmBox_import_btn  text-end mt-3">
                  <Link to="#" onClick={() => setImportModal(true)}>
                    <i className="fa fa-upload mr-2" aria-hidden="true"></i>
                    Import Excel
                  </Link>
                </div>
                <div className="row">
                  {labelValue &&
                    labelValue.map(({ name, inputType }, index) => (
                      <div className="col-4" key={index}>
                        <label className="mt-3" htmlFor={`attribute-${index}`}>
                          {name}
                        </label>
                        {inputType === "inputbox" ? (
                          <input
                            type="text"
                            className={
                              error[`attribute-${index}`]
                                ? "form-control mt-2 is-invalid"
                                : "form-control mt-2"
                            }
                            placeholder={`Please enter here ${name}`}
                            name={name}
                            id={`attribute-${index}`}
                            // value={input.value}
                            onChange={(e) =>
                              handleInputChange(e, index, inputType)
                            }
                            maxLength={24}
                          />
                        ) : (
                          <select
                            className={
                              error[`attribute-${index}`]
                                ? "custm-form-control mt-2 is-invalid"
                                : "custm-form-control mt-2"
                            }
                            name={name}
                            id={`attribute-${index}`}
                            onChange={(e) =>
                              handleInputChange(e, index, inputType)
                            }
                          >
                            <option value="">Select {name}</option>
                            <option value="Yes"> Yes </option>, ,
                            <option value="No"> No </option>
                          </select>
                        )}
                        {error[`attribute-${index}`] && (
                          <div className="invalid-feedback">
                            {error[`attribute-${index}`]}
                          </div>
                        )}
                      </div>
                    ))}

                  <div className="row mt-3">
                    <div className="col-4">
                      <label htmlFor="Device Id" className="col-form-label">
                        Device Id
                      </label>
                      <br />
                      <input
                        type="text"
                        placeholder="Enter your device id"
                        className={`form-control ${!deviceIdValue
                            ? error.deviceId && "is-invalid"
                            : error.deviceId && "is-invalid"
                          }`}
                        name="deviceId"
                        id="Device Id"
                        onChange={(e) => setDeviceIdValue(e.target.value)}
                        maxLength={21}
                      />
                      <div className="invalid-feedback">{error.deviceId}</div>
                    </div>
                    <div className="col-4">
                      <label htmlFor="relievingDate" className="col-form-label">
                        Purchase Date
                      </label>
                      <br />
                      <DatePicker
                        placeholderText="select Date"
                        className={`margin_remove_custom form-control  ${!purchaseValue && error.purchaseDate && "is-invalid"
                          }`}
                        name="purchaseDate"
                        selected={purchaseValue ? new Date(purchaseValue) : ""}
                        // value={ relievingDate? relievingDate: "" }
                        showYearDropdown
                        yearDropdownItemNumber={100}
                        scrollableYearDropdown
                        showMonthDropdown
                        useShortMonthInDropdown
                        onChange={(date) => setPurchaseValue(date)}
                        dateFormat="yyyy-MM-dd"
                      // minDate={new Date(startDate)}
                      />
                      {!purchaseValue && error.purchaseDate && (
                        <p className="datePicker_validation_small">
                          {error.purchaseDate}
                        </p>
                      )}
                    </div>
                    <div className="col-4">
                      <label htmlFor="workStatus" className="col-form-label">
                        Work Status
                      </label>
                      <br />
                      <select
                        className={`custm-form-control ${!workStatus && error.workStatus && "is-invalid"
                          }`}
                        name="workStatus"
                        id="workStatus"
                        onChange={(e) => setWorkStatus(e.target.value)}
                      >
                        <option value="">Select Status</option>
                        <option value="Working"> Working </option>, ,
                        <option value="Not Working"> Not Working </option>
                        <option value="Physical Damage">Physical Damage</option>
                      </select>
                      <div className="invalid-feedback">{error.workStatus}</div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-4">
                      <label htmlFor="images" className="col-form-label">
                        Images
                      </label>
                      <input
                        type="file"
                        className="form-control mt-0"
                        name="images"
                        id="images"
                        onChange={(e) => imageHandler(e.target.files)} // Change
                        accept="image/*"
                        multiple
                      />
                    </div>
                  </div>

                  {images.length > 0 && (
                    <div className="image-preview mt-3">
                      {images.map((image, index) => (
                        <React.Fragment key={index}>
                          <div key={index} className="image-container">
                            <img
                              className="preview-image"
                              src={URL.createObjectURL(image)}
                              alt={`Preview ${index}`}
                            />
                            <TiDelete
                              className="remove-button fs-1"
                              onClick={() => removeImage(index)}
                            />
                          </div>
                        </React.Fragment>
                      ))}
                    </div>
                  )}

                  <div className="mt-3 text-center">
                    <button
                      className="butonColor"
                      ref={buttonRef}
                      onClick={addInventory}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      {/* import Excel modal */}
      <ModalBox
        show={importModal}
        onClose={() => {
          setImportModal(false);
          setFile("");
          setImportError("");
        }}
        heading={"Import File"}
        customizedFooter={
          <div className="d-flex ">
            <div className="mx-2 ">
              <button
                className="modalButtonClose btn btn-secondary"
                onClick={() => {
                  setImportModal(false);
                  setFile("");
                  setImportError("");
                }}
              >
                Cancel
              </button>
            </div>
            <div className="mx-2">
              <button
                className="modalButtonSubmit modalButtonYes btn btn-primary"
                ref={buttonRef}
                onClick={importExcel}
              >
                Upload
              </button>
            </div>
          </div>
        }
      >
        <div>
          <label htmlFor="chooseFile">Select File</label>
          <input
            type="file"
            className={`form-control ${!file[0]
                ? importError.excelFile && "is-invalid"
                : importError.excelFile && "is-invalid"
              }`}
            name="excelFile"
            id="chooseFile"
            accept=".csv, .xls, .xlsx, .xlsm"
            onChange={(e) => setFile(e.target.files)}
          />
          <div className="invalid-feedback fs-6">{importError.excelFile}</div>
          <div className="sample_excel">
            <a
              href={`${process.env.REACT_APP_SECRET_KEY}/download-inventory/${selectedCategoryId}`}
              // target="__blank"
              rel="noopener noreferrer"
            >
              Sample file
            </a>
          </div>
        </div>
      </ModalBox>
    </>
  );
}

export default AddInventory;

import  { useEffect, useRef, useState } from 'react'

const useButtonLoader = (defaultValue) => {
  const buttonref = useRef(null)

  const [loadingValue, setLoadingValue] = useState(false)

  const loader = (data) => {
    setLoadingValue(data)
  }

  useEffect(() => {
    // console.log(buttonref)
    if (buttonref.current) {
      if (loadingValue) {
        buttonref.current.innerHTML =
          buttonref.current.innerHTML = `<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>  `
        // buttonref.current.innerHTML = 'hello'
        buttonref.current.disabled = true
      } else {
        buttonref.current.innerHTML = defaultValue
        buttonref.current.disabled = false
      }
    }
  }, [loadingValue])

  return [buttonref, loader]
}

export default useButtonLoader

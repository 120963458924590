import React, { useState } from "react";
import ModalBox from "../../../Modals/ModalBox";
import useButtonLoader from "../../../Hooks/UseButtonLoader";
import axios from "axios";
import { toast } from "react-toastify";

function AddPdf({ importModal, setImportModal, projectId ,listing}) {
  const [file, setFile] = useState("");
  const [error, setError] = useState({});
  const [version, setVersion] = useState("");
  const [buttonRef, loding] = useButtonLoader("Upload");
  //   const pdfRegex = [0-9]+\.
  // console.log("pdf", file);
  // console.log("versionValue", version);
  // console.log("projectId", projectId);

  const validation = () => {
    let errorMessage = {};
    let status = true;

    if (!version) {
      errorMessage["version"] = "Please Enter version";
      status = false;
    }
    if (!file[0]) {
      errorMessage["pdfFile"] = "This field is required";
      status = false;
    }
    //  else if (!ALLOWED_EXTENSIONS.test(file[0].name)) {
    //   errorMessage["excelFile"] = "Invalid file format";
    //   status = false;
    // }
    setError(errorMessage);
    return status;
  };
  // ---------------put-Pdf-Api----------------------
  const updatePdf = async () => {
    const status = validation();
    if (status) {
      try {
        loding(true);
        const token = localStorage.getItem("demo-token");
        const formdata = new FormData();
        formdata.append("version", version);
        formdata.append("pdf", file[0]);
        const response = await axios.put(
          `${process.env.REACT_APP_SECRET_KEY}/upload-pdf/${projectId}`,
          formdata,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "content-type": "multipart/form-data",
            },
          }
        );
        // console.log("updateDepartment", response);
        if (response.data.status === 200) {
          loding(false);
          setImportModal(false);
          toast.success(response.data.message);
          listing();
        } else {
          loding(false);
          toast.error(response.data.message);
        }
      } catch (error) {
        loding(false);
        console.log(error);
      }
    }
  };

  return (
    <div>
      <ModalBox
        show={importModal}
        onClose={() => {
          setImportModal(false);
          setFile("");
          setError("");
        }}
        heading={"Milestone pdf"}
        customizedFooter={
          <div className="d-flex ">
            <div className="mx-2 ">
              <button
                className="modalButtonClose btn btn-secondary"
                onClick={() => {
                  setImportModal(false);
                  setFile("");
                  setError("");
                }}
              >
                Cancel
              </button>
            </div>
            <div className="mx-2">
              <button
                className="modalButtonSubmit modalButtonYes btn btn-primary"
                ref={buttonRef}
                onClick={updatePdf}
              >
                Upload
              </button>
            </div>
          </div>
        }
      >
        {/* <div> */}
        <label htmlFor="version" className="fs-6">
          Add version
        </label>
        <div className="rounded fs-6">
          <input
            type="text"
            className={`form-control ${
              !version && error.version && "is-invalid"
            }`}
            name="version"
            id="version"
            placeholder="Please enter version Name"
            // value={addCategory.financeCompanyType}
            onChange={(e) => setVersion(e.target.value)}
            maxLength={60}
          />
          <div className="invalid-feedback">{error.version}</div>
        </div>
        {/* </div> */}

        <label htmlFor="chooseFile">Select pdf</label>
        <div className="rounded fs-6">
          <input
            type="file"
            className={`form-control  mb-2 ${
              !file[0] && error.pdfFile && "is-invalid"
            }`}
            name="pdfFile"
            id="chooseFile"
            accept=".pdf"
            onChange={(e) => setFile(e.target.files)}
          />
          <div className="invalid-feedback">{error.pdfFile}</div>
        </div>
      </ModalBox>
    </div>
  );
}

export default AddPdf;

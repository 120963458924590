import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import useButtonLoader from "../../Hooks/UseButtonLoader";

function Form() {
  const [error, setError] = useState("");
  const [inputData, setInputData] = useState({});
  const [department, setDepartment] = useState("");
  const [designation, setDesignation] = useState("");
  const [buttonref, loading] = useButtonLoader("Submit");
  const navigator = useNavigate();
  const [image, setImage] = useState("");
  const [imageUlr, setImageUrl] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  // console.log("input",inputData)

  let errorMessage = {
    employeeId: "",
    email: "",
    previousExperienceInYear: "",
    previousExperienceInMonth: "",
    panNumber: "",
    adress: "",
    startDate: "",
    name: "",
    phoneNumber: "",
    department: "",
    adharNumber: "",
    designation: "",
    profileimage: "",
    status: true,
  };
  let validation = () => {
    const emailregex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    const numberregex = /^\d{10}$/;
    const panRegex = /^([A-Z]{5})(\d{4})([A-Z]{1})$/;
    const aadhaarRegex = /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/;
    const alphNumeric = /^[a-zA-Z0-9]+$/;
    const imageExtensions = /\.(png|jpe?g)$/i;

    if (!inputData.employeeId) {
      errorMessage["employeeId"] = "Please Enter employee Id";
      errorMessage.status = false;
    } else if (!alphNumeric.test(inputData.employeeId)) {
      errorMessage["employeeId"] = "Please Enter Valid employeeId";
      errorMessage.status = false;
    }

    if (!inputData.email) {
      errorMessage["email"] = "Please Enter email";
      errorMessage.status = false;
    } else if (!emailregex.test(inputData.email)) {
      errorMessage["email"] = "Please Enter Valid Email";
      errorMessage.status = false;
    }

    if (!inputData.previousExperienceInYear) {
      errorMessage["previousExperienceInYear"] =
        "Please Enter previous experience Year";
      errorMessage.status = false;
    }
    if (!inputData.previousExperienceInMonth) {
      errorMessage["previousExperienceInMonth"] =
        "Please Enter previous experience month";
      errorMessage.status = false;
    }

    if (!inputData.panNumber) {
      errorMessage["panNumber"] = "Please Enter pan number";
      errorMessage.status = false;
    } else if (!panRegex.test(inputData.panNumber)) {
      errorMessage["panNumber"] = "Please Enter valid Pan Number";
      errorMessage.status = false;
    }

    if (!inputData.adress) {
      errorMessage["adress"] = "Please Enter address";
      errorMessage.status = false;
    }

    if (!inputData.startDate) {
      errorMessage["startDate"] = "Please Enter start Date";
      errorMessage.status = false;
    }

    if (!inputData.name) {
      errorMessage["name"] = "Please Enter your name";
      errorMessage.status = false;
    }

    if (!inputData.phoneNumber) {
      errorMessage["phoneNumber"] = "Please Enter mobile Number";
      errorMessage.status = false;
    } else if (!numberregex.test(inputData.phoneNumber)) {
      errorMessage["phoneNumber"] = "Please Enter 10 digit Number";
      errorMessage.status = false;
    }

    if (!inputData.department) {
      errorMessage["department"] = "Please select department";
      errorMessage.status = false;
    }

    if (!inputData.adharNumber) {
      errorMessage["adharNumber"] = "Please Enter aadhar number";
      errorMessage.status = false;
    } else if (!aadhaarRegex.test(inputData.adharNumber)) {
      errorMessage["adharNumber"] = "Please Enter valid aadhar No";
      errorMessage.status = false;
    }

    if (!inputData.designation) {
      errorMessage["designation"] = "Please select designation";
      errorMessage.status = false;
    }

    // if (!image) {
    //   errorMessage["profileimage"] = "Please choose your profile image";
    //   errorMessage.status = false;
    // }
    if (image) {
      if (!imageExtensions.test(image.name)) {
        errorMessage["profileimage"] = "Invalid image format. Please use JPG, JPEG, PNG, SVG.";
        errorMessage.status = false;
      }
    }
    setError(errorMessage);
    return errorMessage.status;
  };
  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    setInputData({ ...inputData, [name]: value });
  };

  const onChangeCheckbox = () => {
    setIsChecked(!isChecked)
  }
  //----------------department-API--------------------------
  const fetchDepartment = async () => {
    try {
      const token = localStorage.getItem("demo-token");
      const response = await axios.get(
        `${process.env.REACT_APP_SECRET_KEY}/master-data-department`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.status === 200) {
        setDepartment(response.data.data.departments);
      } else {
        toast.error(response.data.message);
      }
    } catch (err) {
      console.log("form", err);
    }
  };

  //----------------designation-API--------------------------
  const fetchdesignation = async () => {
    try {
      const token = localStorage.getItem("demo-token");
      const response = await axios.get(
        `${process.env.REACT_APP_SECRET_KEY}/master-data-designation`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.status === 200) {
        setDesignation(response.data.data.designations);
      } else {
        toast.error(response.data.message);
      }
    } catch (err) {
      console.log("form", err);
    }
  };
  //-----------------Add-emloyees-API----------------------

  const setEmployee = async (e) => {
    e.preventDefault();
    let status = validation();
    if (status) {
      try {
        loading(true);
        const token = localStorage.getItem("demo-token");
        const formdata = new FormData();
        formdata.append("employeeId", inputData.employeeId);
        formdata.append("email", inputData.email);
        formdata.append(
          "previousExperienceInYear",
          inputData.previousExperienceInYear
        );
        formdata.append(
          "previousExperienceInMonth",
          inputData.previousExperienceInMonth
        );
        formdata.append("panNumber", inputData.panNumber);
        formdata.append("adress", inputData.adress);
        formdata.append("startDate", inputData.startDate);
        formdata.append("name", inputData.name);
        formdata.append("phoneNumber", inputData.phoneNumber);
        formdata.append("mobileNumber", inputData.mobileNumber ? inputData.mobileNumber : "");
        formdata.append("department", inputData.department);
        formdata.append("adharNumber", inputData.adharNumber);
        formdata.append("designation", inputData.designation);
        formdata.append("sendEmailStatus", isChecked);
        formdata.append("profileimage", image ? image : "");
        const response = await axios.post(
          `${process.env.REACT_APP_SECRET_KEY}/employee-register`,
          formdata,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "content-type": "multipart/form-data",
            },
          }
        );
        if (response.data.status === 200) {
          loading(false);
          toast.success(response.data.message);
          navigator("/employee-Management");
        } else {
          loading(false);
          toast.error(response.data.message);
        }
      } catch (err) {
        loading(false);
        console.log(err);
      }
    }
  };
  const allowedTypes = [
    "image/jpg",
    "image/jpeg",
    "image/png",
    "image/webp",
    "image/svg",
    "image/tif",
    "image/tiff",
    "image/bmp",
    "image/gif",
  ];

  function imageHandler(file) {
    setImage(file);
    setImageUrl(file !== "" && URL.createObjectURL(file));
  }

  useEffect(() => {
    fetchDepartment();
    fetchdesignation();
  }, []);

  return (
    <>
      <div className="WrapperArea">
        <div className="WrapperBox">
          <div className="Small-Wrapper borderRadius">
            <h4 className="Title">
              <span>
                <Link to="/employee-Management">
                  <i className="fa fa-arrow-left" aria-hidden="true"></i>
                </Link>
              </span>
              Add New Information
            </h4>
            <div className="container mt-4">
              <form className="needs-validation">
                <div className="row">
                  <div className="col-lg-6 col-md-6">
                    <label htmlFor="EmployeeId" className="col-form-label">
                      Employee Id
                    </label>
                    <br />
                    <input
                      type="text"
                      className={`form-control ${!inputData.employeeId
                          ? error.employeeId && "is-invalid"
                          : error.employeeId && "is-invalid"
                        }`}
                      placeholder="Enter your Id here"
                      name="employeeId"
                      id="EmployeeId"
                      onChange={onChangeHandler}
                      maxLength={8}
                    />
                    <div className="invalid-feedback">{error.employeeId}</div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <label htmlFor="name" className="col-form-label">
                      Name
                    </label>
                    <br />
                    <input
                      type="text"
                      placeholder="Enter your name"
                      className={`form-control ${!inputData.name && error.name && "is-invalid"
                        }`}
                      name="name"
                      id="name"
                      onChange={onChangeHandler}
                      maxLength={22}
                    />
                    <div className="invalid-feedback">{error.name}</div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <label htmlFor="email" className="col-form-label">
                      Email
                    </label>
                    <br />
                    <input
                      type="text"
                      className={`form-control ${!inputData.email
                          ? error.email && "is-invalid"
                          : error.email && "is-invalid"
                        }`}
                      placeholder="Enter your email here "
                      name="email"
                      id="email"
                      onChange={onChangeHandler}
                      maxLength={45}
                    />
                    <div className="invalid-feedback">{error.email}</div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <label htmlFor="phone" className="col-form-label">
                      Mobile No
                    </label>
                    <br />
                    <input
                      type="text"
                      placeholder="Enter your mobile no here"
                      className={`form-control ${!inputData.phoneNumber
                          ? error.phoneNumber && "is-invalid"
                          : error.phoneNumber && "is-invalid"
                        }`}
                      name="phoneNumber"
                      id="phone"
                      // onChange={onChangeHandler}
                      onChange={(e) => {
                        const inputText = e.target.value;
                        const sanitizedText = inputText.replace(/[^0-9]/g, ""); // Remove non-digit characters
                        const maxLength = 10;
                        const truncatedText = sanitizedText.slice(0, maxLength);
                        e.target.value = truncatedText;
                        onChangeHandler(e);
                      }}
                    />
                    <div className="invalid-feedback">{error.phoneNumber}</div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <label htmlFor="Experiance" className="col-form-label">
                      Previous Experience
                    </label>
                    <br />
                    <div className="d-flex justify-content-between">
                      <div className="previousExperiance_input">
                        <select
                          className={`custm-form-control ${!inputData.previousExperienceInYear &&
                            error.previousExperienceInYear &&
                            "is-invalid"
                            }`}
                          name="previousExperienceInYear"
                          onChange={onChangeHandler}
                          id="Experiance"
                        >
                          <option value=""> select years </option>
                          {Array.from({ length: 16 }, (_, index) => (
                            <option key={index} value={index}>
                              {index} year
                            </option>
                          ))}
                        </select>
                        <div className="invalid-feedback">
                          {error.previousExperienceInYear}
                        </div>
                      </div>

                      <div className="previousExperiance_input">
                        <select
                          className={`custm-form-control ${!inputData.previousExperienceInMonth &&
                            error.previousExperienceInMonth &&
                            "is-invalid"
                            }`}
                          placeholder="Enter experiance in month"
                          name="previousExperienceInMonth"
                          id="Experiance"
                          onChange={onChangeHandler}
                        >
                          <option value=""> select month </option>
                          {Array.from({ length: 13 }, (_, index) => (
                            <option key={index} value={index}>
                              {index} month
                            </option>
                          ))}
                        </select>
                        <div className="invalid-feedback">
                          {error.previousExperienceInMonth}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <label htmlFor="otherNo" className="col-form-label">
                      Other Number
                    </label>
                    <br />
                    <input
                      type="text"
                      placeholder="Enter your other no here"
                      className="form-control"
                      name="mobileNumber"
                      id="otherNo"
                      // onChange={onChangeHandler}
                      onChange={(e) => {
                        const inputText = e.target.value;
                        const sanitizedText = inputText.replace(/[^0-9]/g, ""); // Remove non-digit characters
                        const maxLength = 15;
                        const truncatedText = sanitizedText.slice(0, maxLength);
                        e.target.value = truncatedText;
                        onChangeHandler(e);
                      }}
                    />
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <label htmlFor="pan" className="col-form-label">
                      PAN
                    </label>
                    <br />
                    <input
                      type="text"
                      className={`form-control ${!inputData.panNumber
                          ? error.panNumber && "is-invalid"
                          : error.panNumber && "is-invalid"
                        }`}
                      placeholder="Enter your PAN number here"
                      name="panNumber"
                      id="pan"
                      maxLength={10}
                      // onChange={onChangeHandler}
                      onChange={(e) => {
                        const inputText = e.target.value;
                        const sanitizedText = inputText.toUpperCase(); // Convert to uppercase
                        const maxLength = 10;
                        const truncatedText = sanitizedText.slice(0, maxLength);
                        e.target.value = truncatedText;
                        onChangeHandler(e);
                      }}
                    />
                    <div className="invalid-feedback">{error.panNumber}</div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <label htmlFor="Aadhar" className="col-form-label">
                      Aadhar No
                    </label>
                    <br />
                    <input
                      type="text"
                      placeholder="enter your aadhar no here"
                      className={`form-control ${!inputData.adharNumber
                          ? error.adharNumber && "is-invalid"
                          : error.adharNumber && "is-invalid"
                        }`}
                      name="adharNumber"
                      id="Aadhar"
                      // onChange={onChangeHandler}
                      onChange={(e) => {
                        const inputText = e.target.value;
                        const sanitizedText = inputText.replace(/[^0-9]/g, ""); // Remove non-digit characters
                        const maxLength = 12;
                        const truncatedText = sanitizedText.slice(0, maxLength);
                        e.target.value = truncatedText;
                        onChangeHandler(e);
                      }}
                    />
                    <div className="invalid-feedback">{error.adharNumber}</div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <label htmlFor="address" className="col-form-label">
                      Address
                    </label>
                    <br />
                    <textarea
                      type="text"
                      className={`form-control ${!inputData.adress && error.adress && "is-invalid"
                        }`}
                      placeholder="Enter your address here... "
                      name="adress"
                      id="address"
                      onChange={onChangeHandler}
                      maxLength={220}
                    />
                    <div className="invalid-feedback">{error.adress}</div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <label htmlFor="Department" className="col-form-label">
                      Department
                    </label>
                    <br />
                    <select
                      className={`custm-form-control ${!inputData.department &&
                        error.department &&
                        "is-invalid"
                        }`}
                      name="department"
                      id="Department"
                      onChange={onChangeHandler}
                    >
                      <option value="">select one</option>
                      {department &&
                        department.map(
                          ({ departmentName, _id, status }, idindex) => {
                            return (
                              <React.Fragment key={idindex}>
                                <option value={_id}>{departmentName}</option>
                              </React.Fragment>
                            );
                          }
                        )}
                    </select>
                    <div className="invalid-feedback">{error.department}</div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <label htmlFor="date" className="col-form-label">
                      Start Date
                    </label>
                    <br />
                    <input
                      type="date"
                      className={`form-control ${!inputData.startDate && error.startDate && "is-invalid"
                        }`}
                      name="startDate"
                      id="date"
                      onChange={onChangeHandler}
                    />
                    <div className="invalid-feedback">{error.startDate}</div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <label htmlFor="Designation" className="col-form-label">
                      Designation
                    </label>
                    <br />
                    <select
                      className={`custm-form-control ${!inputData.designation &&
                        error.designation &&
                        "is-invalid"
                        }`}
                      name="designation"
                      id="Designation"
                      onChange={onChangeHandler}
                    >
                      <option value="">select one</option>
                      {designation &&
                        designation.map(({ designationName, _id }, idindex) => {
                          return (
                            <React.Fragment key={idindex}>
                              <option value={_id}>{designationName}</option>
                            </React.Fragment>
                          );
                        })}
                    </select>
                    <div className="invalid-feedback">{error.designation}</div>
                  </div>

                  <div className="col-lg-6 col-md-6 mt-3">
                    <div>
                      <label className="mt-4">
                        Send Email Notification :
                        <input
                          className="radioButton radioIcon mx-2"
                          type="checkbox"
                          name="sendEmailStatus"
                          checked={isChecked}
                          value={!isChecked}
                          onChange={onChangeCheckbox}
                        />
                      </label>
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6">
                    <div>
                      <label htmlFor="profileimage" className="col-form-label">
                        Profile
                      </label>
                      <br />
                      <input
                        type="file"
                        className={`form-control ${image && error.profileimage && "is-invalid" }`}
                        name="profileimage"
                        id="profileimage"
                        onChange={(e) =>
                          imageHandler(
                            e.target.files[0] ? e.target.files[0] : ""
                          )
                        }
                        accept="image/jpg,image/jpeg,image/png,image/svg,"
                      />
                      <div className="invalid-feedback"> {error.profileimage}</div>
                      {imageUlr && (
                        <img className="profileImage" alt="" src={imageUlr} />
                      )}
                    </div>
                  </div>

                  <div className="width_btn_cstm">
                    <button
                      className="butonColor my-3"
                      type="submit"
                      onClick={setEmployee}
                      ref={buttonref}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Form;

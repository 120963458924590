import moment from "moment";
import React from "react";

function ReleaseMember({ members }) {
  const releaseData =
    members.length > 0 &&
    members.filter(({ isRelease }) => {
      return isRelease === true;
    });

  return (
    <>
      <div className="">
        <div className=" mt-5">
          <h4 className="Title"> Release Member </h4>
          <div className="TableList">
            <table>
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Department</th>
                  <th>Name</th>
                  <th>Allocation type</th>
                  <th>Start date</th>
                  <th>End date</th>
                  <th>Relsase date</th>
                </tr>
              </thead>
              <tbody>
                {releaseData.length > 0 ? (
                  releaseData.map(
                    (
                      {
                        _id,
                        departments,
                        employee,
                        workAssignedFor,
                        isRelease,
                        startDate,
                        endDate,
                        releaseDate,
                      },
                      index
                    ) => {
                      return (
                        <React.Fragment key={_id}>
                          <tr>
                            <td>{++index}</td>
                            <td>{departments.departmentName}</td>
                            <td>{employee.name}</td>
                            <td>{workAssignedFor}</td>
                            <td>{startDate?moment(startDate).format("DD-MM-YYYY"):`---`}</td>
                            <td>{endDate?moment(endDate).format("DD-MM-YYYY"):`---`}</td>
                            <td>{releaseDate?moment(releaseDate).format("DD-MM-YYYY"):`---`}</td>
                          </tr>
                        </React.Fragment>
                      );
                    }
                  )
                ) : (
                  <tr>
                    <td colSpan={7}>Not Found</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default ReleaseMember;

import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ModalBox from "../Modals/ModalBox";
import { toast } from "react-toastify";
import { FaBars} from "react-icons/fa";

function Header() {
  const [LogoutModel, setLogoutModel] = useState(false);
  const token = localStorage.getItem("demo-token");
  const userName = localStorage.getItem("userName");
  const navigator = useNavigate();

  //toggle class
  const [sideBar, setSidebar] = useState(false);
  useEffect(() => {
    document.body.classList.toggle("menu_open", sideBar);
  }, [sideBar]);
  //end toggle class

  const logoutHandler = () => {
    localStorage.removeItem("demo-token");
    navigator("/");
    toast.success("Logout successfully");
  };


  useEffect(() => {
    if (!token) {
      navigator("/");
      // toast.error("Please login");
    }
  }, [token, navigator]);
  return (
    <>
      <div className="Header bgColor">
        <div className="Navigation">
        <span className="button_bar" onClick={() => setSidebar(!sideBar)}> <FaBars/> </span>
          <div className="Avater">
            <a>
              {/* javascript:void(012); */}
              <figure>
                <img alt="" src="./images/user.png" />
              </figure>
              {userName || "Amdin"}
            </a>
            <ul className="mt-2">
              <li>
                <figure>
                  <img alt="" className="" src="./images/user.png" />
                </figure>
                <h4>
                  Admin <span>Administrator</span> 
                </h4>
              </li>
              <li>
                <Link to="/change-password">
                  <span>
                    <i className="fa fa-cog"></i>
                  </span>
                  Change Settings
                </Link>
              </li>
              <li>
                <Link to="#" onClick={() => setLogoutModel(true)}>
                  <span>
                    <i className="fa fa-sign-out"></i>
                  </span>{" "}
                  Logout
                </Link>
              </li>
            </ul>
          </div>
          {/* <div className="clear"></div> */}
        </div>
      </div>
      <ModalBox
        show={LogoutModel}
        onClose={() => setLogoutModel(false)}
        heading={"Logout"}
        description={"are you sure ? you want to logout"}
        action={logoutHandler}
      />
    </>
  );
}

export default Header;

import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import usePasswordToggle from "../../Hooks/UsePasswordToggle";
import useButtonLoader from "../../Hooks/UseButtonLoader";
import { Link, useNavigate } from "react-router-dom";

function Login() {
  const [buttonRef, buttonLoading] = useButtonLoader("Log In");
  const [passwordref, type, iconElement] = usePasswordToggle();
  const [error, setError] = useState({});
  const navigator = useNavigate();

  const [login, setLogin] = useState({
    email: "",
    password: "",
  });

  const formValidation = () => {
    const errorMessage = {};
    let status = true;

    if (!login.email) {
      errorMessage.email = "Please Enter Your Email";
      status = false;
    }
    if (!login.password) {
      errorMessage.password = "Please Enter Your Password";
      status = false;
    }
    setError(errorMessage);
    return status;
  };



  const onChangeHandler = (event) => {
    const { name, value } = event.target;
    setLogin({ ...login, [name]: value });
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    const status = formValidation();
    if (status) {
      try {
        buttonLoading(true);
        const response = await axios.post(
          `${process.env.REACT_APP_SECRET_KEY}/login`,
          login
        );
        if (response.data.status === 200) {
          // console.log("logingpage",response.data.data.Admin.name)
          localStorage.setItem("demo-token", response.data.data.token);
          localStorage.setItem("userName", response.data.data.Admin.name)
          buttonLoading(false);
          toast.success(response.data.message);
          navigator("/dashboard");
        } else {
          toast.error(response.data.message);
          buttonLoading(false);
        }
      } catch (error) {
        console.log("error", error);
        toast.error(error);

        buttonLoading(false);
      }
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("demo-token")
    if (token) {
      navigator("/dashboard")
    }
  })

  return (
    <>
      <div className="LoginArea">
        <div className="LoginBox">
          <figure>
            <img alt="" src="./images/ripenApps.png" />
          </figure>
          <h5>To keep connected with us please</h5>
          <h5>
            login
            <br /> wit your personal info..
          </h5>
          <form>
            <div className="form-group">
              <label>User ID</label>
              <input
                type="email"
                placeholder="Enter User ID"
                autoComplete="on"
                className={`form-control ${!login.email && error.email && "is-invalid"}`}
                name="email"
                value={login.email}
                onChange={onChangeHandler}
              />
              <span className="Icon">
                <i className="fa fa-user"></i>
              </span>
              <div className="invalid-feedback">{error.email}</div>
            </div>

            <div className="form-group">
              <label>Password</label>
              <input
                ref={passwordref}
                type={type}
                placeholder="Enter Password"
                className={`form-control ${!login.password && error.password && "is-invalid"
                  }`}
                name="password"
                autoComplete="on"
                value={login.password}
                onChange={onChangeHandler}
              />

              <span className="Icon">
                <i className="fa fa-unlock-alt"></i>
              </span>
              {iconElement()}
              <div className="invalid-feedback">{error.password}</div>
            </div>

            <button onClick={onSubmit} ref={buttonRef}>
              Log In <i className="fa fa-sign-in"></i>
            </button>
            <Link to="/forgot-password">
              <button>
                Forgot Password <i className="fa fa-sign-in"></i>
              </button>
            </Link>
          </form>
        </div>
      </div>
    </>
  );
}

export default Login;

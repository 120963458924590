import moment from "moment";
import React, { useState } from "react";
import ModalBox from "../../../Modals/ModalBox";
import useButtonLoader from "../../../Hooks/UseButtonLoader";
import axios from "axios";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function ActiveMember({ members, listing, projectDeatils }) {
  const [isRelease, setIsRelease] = useState("");
  const [modal, setModal] = useState("");
  const [memberId, setMemberId] = useState("");
  const [projectMember, setProjectMember] = useState("");
  const [buttonRef, loading] = useButtonLoader("Yes");
  const [date, setDate] = useState("");
  const [error, setError] = useState({});

  const releseValue =
    members.length > 0 &&
    members.filter(({ isRelease ,accept}) => {
      return isRelease === false ,accept === true;
    });

  const validation = () => {
    let errorMessage = {
      date: "",
    };
    let status = true;
    if (!date) {
      errorMessage["date"] = "This field is required ";
      status = false;
    }
    setError(errorMessage);
    return status;
  };

  const releaseMember = async (e) => {
    e.preventDefault();
    let status = validation();
    const dateFormat = date && moment(date).format("YYYY-MM-DD");
    if (status) {
      try {
        loading(true);
        const token = localStorage.getItem("demo-token");
        const response = await axios.put(
          `${process.env.REACT_APP_SECRET_KEY}/release-member/${memberId}`,
          {
            isRelease: !isRelease,
            releaseDate: dateFormat,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.data.status === 200) {
          loading(false);
          setModal(false);
          listing();
          setDate("");
          toast.success(response.data.message);
        } else {
          loading(false);
          // toast.error(response.data.message);
        }
      } catch (error) {
        loading(false);
        console.log(error);
      }
    }
  };

  const statusHandler = (_id, isRelease, employee) => {
    setIsRelease(isRelease);
    setMemberId(_id);
    setModal(true);
    setProjectMember(employee);
  };
  return (
    <>
      <div className="">
        <div className=" mt-5">
          <h4 className="Title"> Active Member </h4>
          <div className="TableList">
            <table>
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Department</th>
                  <th>Name</th>
                  <th>Allocation type</th>
                  <th>Start date</th>
                  <th>End date</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {releseValue.length > 0 ? (
                  releseValue.map(
                    (
                      {
                        _id,
                        departments,
                        employee,
                        workAssignedFor,
                        isRelease,
                        startDate,
                        endDate,
                      },
                      index
                    ) => {
                      return isRelease === false ? (
                        <React.Fragment key={index}>
                          <tr>
                            <td>{++index}</td>
                            <td>
                              {departments?.departmentName
                                ? departments.departmentName
                                : `---`}
                            </td>
                            <td>{employee?.name ? employee.name : `---`}</td>
                            <td>{workAssignedFor ? workAssignedFor : `---`}</td>
                            <td>
                              {startDate
                                ? moment(startDate).format("DD-MM-YYYY")
                                : `---`}
                            </td>
                            <td>
                              {endDate
                                ? moment(endDate).format("DD-MM-YYYY")
                                : `---`}
                            </td>
                            <td>
                              <button
                                className="releseButton"
                                onClick={() =>
                                  statusHandler(_id, isRelease, employee)
                                }
                              >
                                Relese
                              </button>
                            </td>
                          </tr>
                        </React.Fragment>
                      ) : (
                        ""
                      );
                    }
                  )
                ) : (
                  <tr>
                    <td colSpan={7}>Not Found</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        <ModalBox
          show={modal}
          onClose={() => {
            setModal(false);
            setDate("");
            setError("");
          }}
          heading={"Release Member"}
          action={releaseMember}
          buttonRef={buttonRef}
        >
          <div className="modalBoxSize">
            Do you want to release
            
            <strong> {projectMember.name} </strong> from
            <strong> {projectDeatils.projectName}</strong> project?
          </div>
          <div className="">
            <label htmlFor="date" className="releseDtae mt-2">
              Select Date
            </label>
            <br />
            <DatePicker
              placeholderText="Select relese Date"
              className={`form-control deliveryDatepicker ${
                !date && error.date && "is-invalid"
              }`}
              selected={date}
              value={date}
              name="date"
              id="date"
              showYearDropdown
              yearDropdownItemNumber={100}
              scrollableYearDropdown
              showMonthDropdown
              useShortMonthInDropdown
              onChange={(date) => setDate(date)}
              dateFormat="yyyy-MM-dd"
              // maxDate={new Date()}
            />
            {!date && error.date && (
              <p className="datePicker_validation_large">{error.date}</p>
            )}
          </div>
        </ModalBox>
      </div>
    </>
  );
}

export default ActiveMember;

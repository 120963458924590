import React, { useState } from "react";
import useButtonLoader from "../../../Hooks/UseButtonLoader";
import axios from "axios";
import ModalBox from "../../../Modals/ModalBox";
import { toast } from "react-toastify";

function UpdateDepartment({
  updateId,
  departmentName,
  setDeparrtmentName,
  updateModal,
  modalClose,
  listing,
}) {
  const [buttonRef, loding] = useButtonLoader("Submit");
  const [error, setError] = useState({ departmentName: "",});

  const validation = () => {
    let errorMessage = {
      name: "",
    };
    let status = true;

    if (!departmentName) {
      errorMessage["name"] = "Please Enter Department Name";
      status = false;
    }
    setError(errorMessage);
    return status;
  };

  const updateDepartment = async () => {
    const status = validation();
    if (status) {
      try {
        loding(true);
        const token = localStorage.getItem("demo-token");
        const response = await axios.put(
          `${process.env.REACT_APP_SECRET_KEY}/department-update/${updateId}`,
          { departmentName: departmentName },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        // console.log("updateDepartment", response);
        if (response.data.status === 200) {
          loding(false);
          modalClose();
          listing();
          toast.success(response.data.message);
        } else {
          loding(false);
          toast.error(response.data.message);
        }
      } catch (error) {
        loding(false);
        console.log(error);
      }
    }
  };

  return (
    <div>
      <ModalBox
        show={updateModal}
        onClose={modalClose}
        heading={"Update Department"}
        customizedFooter={
          <div>
            <button className="btn btn-secondary modalButtonClose mx-2" onClick={modalClose}>
              close
            </button>
            <button
              className="modalButtonYes btn btn-primary modalButtonSubmit"
              onClick={updateDepartment}
              ref={buttonRef}
            >
              Submit
            </button>
          </div>
        }
      >
        <div className="row">
          <label htmlFor="inputName" className="fs-6 my-2">
            Update Department
          </label>
          <div className="rounded fs-6">
            <input
              type="text"
              className={`form-control ${
                !departmentName && error.name && "is-invalid"
              }`}
              name="departmentName"
              id="inputName"
              placeholder="Please enter department Name"
              value={departmentName}
              onChange={(e) => setDeparrtmentName(e.target.value)}
              maxLength={100}
            />
            <div className="invalid-feedback">{error.name}</div>
          </div>
        </div>
      </ModalBox>
    </div>
  );
}

export default UpdateDepartment;

import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-toastify";
import useButtonLoader from "../../Hooks/UseButtonLoader";
import { useNavigate, useParams } from "react-router-dom";
import usePasswordToggle from "../../Hooks/UsePasswordToggle";

function ResetPassword() {
  const [inputPassword, setInputPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [buttonRef, loader] = useButtonLoader("Update");
  const [passwordref, type, iconElement] = usePasswordToggle();
  const [passwordref2, type2, iconElement2] = usePasswordToggle();
  const { token } = useParams();
  const navigator = useNavigate();
  
  const validation = () => {
    let errorMessage = {};
    let status = true;

    if (!inputPassword) {
      errorMessage["newPassword"] = "Please enter new password";
      status = false;
    }
    if (!confirmPassword) {
      errorMessage["confirnPassword"] = "Please enter confirn password";
      status = false;
    } else if (inputPassword !== confirmPassword) {
      errorMessage["confirnPassword"] = "Missmatch new password";
      status = false;
    }
    setError(errorMessage);
    return status;
  };

  const resetPassword = async (e) => {
    e.preventDefault();
    let status = validation();
    if (status) {
      try {
        loader(true);
        const response = await axios.post(
          `${process.env.REACT_APP_SECRET_KEY}/resetPassword`,
          {
            newPassword: inputPassword,
            token: token,
          }
        );
        if (response.data.status === 200) {
          loader(false);
          navigator("/");
          toast.success(response.data.message);
        } else {
          loader(false);
          toast.error(response.data.message);
        }
      } catch (err) {
        loader(false);
        console.log(err);
      }
    }
  };
  return (
    <>
      <div className="LoginArea">
        <div className="LoginBox">
          <figure>
            <img alt="" src="/images/ripenApps.png" />
          </figure>
          <h5>Reset Password</h5>
          <p className="text-center">Please enter your new password and confirm password</p>
          <form>
            <div className="form-group">
              <label htmlFor="newPassword">New Password</label>
              <input
                ref={passwordref}
                type={type}
                className={`form-control ${
                  !inputPassword && error && "is-invalid"
                }`}
                placeholder="Enter new password "
                name="newPassword"
                autoComplete="on"
                id="newPassword"
                onChange={(e) => setInputPassword(e.target.value)}
              />
              <span className="Icon">
                <i className="fa fa-unlock-alt"></i>
              </span>
              {iconElement()}
              <div className="invalid-feedback">{error.newPassword}</div>
            </div>

            <div className="form-group">
              <label htmlFor="confirmPassword">Confirm Password</label>
              <input
                ref={passwordref2}
                type={type2}
                className={`form-control ${
                  !confirmPassword
                    ? error.confirnPassword && "is-invalid"
                    : error.confirnPassword && "is-invalid"
                }`}
                placeholder="Enter confirm password "
                name="confirnPassword"
                autoComplete="on"
                id="confirmPassword"
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              <span className="Icon">
                <i className="fa fa-unlock-alt"></i>
              </span>
              {iconElement2()}
              <div className="invalid-feedback">{error.confirnPassword}</div>
            </div>

            <button onClick={resetPassword} ref={buttonRef}>
              Update
            </button>
          </form>
        </div>
      </div>
    </>
  );
}
export default ResetPassword;

import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { BiSolidAddToQueue } from "react-icons/bi";
import { TiDelete } from "react-icons/ti";
import useButtonLoader from "../../Hooks/UseButtonLoader";
import { toast } from "react-toastify";
import axios from "axios";

function AddCategory() {
  const [inputFields, setInputFields] = useState([
    { id: Date.now(), name: "", inputType: "inputbox" },
  ]);
  const [categoryName, setCategoryName] = useState("");
  const [buttonRef, loding] = useButtonLoader("Submit");
  const navigator = useNavigate();
  const [error, setError] = useState({});

  const validation = () => {
    let errorMessage = {};
    let status = true;

    if (!categoryName) {
      errorMessage["categoryName"] = "Category name is required";
      status = false;
    }

    inputFields.forEach((field, index) => {
      if (!field.name) {
        errorMessage[`attribute-${index}`] = "This field is required";
        status = false;
      }
    });

    setError(errorMessage);
    return status;
  };

  // Function to handle adding a new input field
  const handleAddField = () => {
    const newInput = { id: Date.now(), name: "", inputType: "inputbox" };
    setInputFields([...inputFields, newInput]);
  };
  const handleRemoveField = (id) => {
    const updatedFields = inputFields.filter((field) => field.id !== id);
    setInputFields(updatedFields);
  };

  // Function to handle input field change
  const handleInputChange = (index, event) => {
    const values = [...inputFields];
    values[index].name = event.target.value;
    setInputFields(values);

    setError((prevError) => {
      const updatedError = { ...prevError };
      updatedError[`attribute-${index}`] = "";
      return updatedError;
    });
  };
  const handleInputTypeChange = (index, event) => {
    const values = [...inputFields];
    values[index].inputType = event.target.value;
    setInputFields(values);
  };

  // ---------Add-category-label----------------
  const addCategory = async () => {
    const status = validation();
    if (status) {
      try {
        loding(true);
        const token = localStorage.getItem("demo-token");
        const attributeValues = inputFields.map(({ name, inputType }) => {
          return {
            name: name,
            inputType: inputType,
          };
        });
        const response = await axios.post(
          `${process.env.REACT_APP_SECRET_KEY}/category-create`,
          {
            categoryName: categoryName,
            attributes: attributeValues,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.data.status === 200) {
          loding(false);
          navigator("/category-Management");
          // modalClose();
          // listing();
          toast.success(response.data.message);
        } else {
          loding(false);
          toast.error(response.data.message);
        }
      } catch (error) {
        loding(false);
        console.log(error);
      }
    }
  };
  return (
    <>
      <div className="WrapperArea">
        <div className="WrapperBox">
          <div className="Small-Wrapper">
            <h4 className="Title">
              <span>
                <Link to="/category-Management">
                  <i className="fa fa-arrow-left" aria-hidden="true"></i>
                </Link>
              </span>
              Add New Category
            </h4>

            <div>
              <label htmlFor="categoryName">Category Name</label>
              <input
                type="text"
                className={`form-control w-50 mt-1 ${
                  !categoryName && error.categoryName && "is-invalid"
                }`}
                placeholder="Enter your category name"
                name="categoryName"
                id="categoryName"
                onChange={(e) => setCategoryName(e.target.value)}
                maxLength={18}
              />
              {!categoryName && (
                <div className="invalid-feedback">{error.categoryName}</div>
              )}
            </div>
            <div>
              <label htmlFor="" className="mt-2">
                Attributes
              </label>
            </div>
            <div className="Cstm_add_remove">
              {inputFields.map((input, index) => (
                <div className="cstm_width_place mb-1" key={index}>
                  <div className="form_field_cstm_error">
                    <input
                      type="text"
                      className={`form-control ${
                        error[`attribute-${index}`] && "is-invalid"
                      }`}
                      placeholder=""
                      name={`attribute-${index}`}
                      id={`attribute-${index}`}
                      value={input.value}
                      onChange={(e) => handleInputChange(index, e)} // Handle input change
                      maxLength={18}
                    />
                    <div className="col-lg-2 col-md-2 mx-1">
                      <select
                        className="input_style "
                        name={`input-type-${index}`}
                        id={`input-type-${index}`}
                        value={inputFields[index].inputType}
                        onChange={(e) => handleInputTypeChange(index, e)}
                      >
                        <option value="inputbox">inputbox</option>
                        <option value="dropdown">dropdown</option>
                      </select>
                    </div>
                    {index !== 0 ? (
                      <div className="milestonRemoveButton_cstm">
                        <TiDelete
                          className="fs-3 color"
                          onClick={() => handleRemoveField(input.id)}
                        />
                      </div>
                    ) : (
                      <div className="milestonButton_cstm">
                        <BiSolidAddToQueue
                          className="fs-4 color"
                          onClick={handleAddField}
                        />
                      </div>
                    )}
                  </div>
                  {error[`attribute-${index}`] && (
                    <p className="error">{error[`attribute-${index}`]}</p>
                  )}
                </div>
              ))}
            </div>

            <div className="mt-3">
              <button
                className="butonColor"
                ref={buttonRef}
                onClick={addCategory}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddCategory;

import React, { useState } from "react";
import ModalBox from "../../Modals/ModalBox";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import useButtonLoader from "../../Hooks/UseButtonLoader";
import axios from "axios";
import { toast } from "react-toastify";
import moment from "moment";

function AddSingOffDate({ editId, editModal, modalClose, listing }) {
  const [date, setDate] = useState("");
  const [error, setError] = useState({});
  const [buttonRef, loading] = useButtonLoader("Submit");
  const [selectAction, setSelectAction] = useState("");

  // console.log("editId", editId);
  // console.log("selectAction", selectAction);
  // console.log("date", date);

  const validation = () => {
    let errorMessage = {
      selectType: "",
      date: "",
    };
    let status = true;

    if (selectAction === "") {
      errorMessage["selectType"] = "Please select value ";
      status = false;
    }
    if (!date) {
      errorMessage["date"] = "Please select date ";
      status = false;
    }
    setError(errorMessage);
    return status;
  };

  const setSignOffValue = async (e) => {
    e.preventDefault();
    let status = validation();
    if (status) {
      try {
        loading(true);
        const token = localStorage.getItem("demo-token");
        const selectDate = moment(date).format("YYYY-MM-DD");
        const matchObject = {};
        matchObject[selectAction] = selectDate;
        const response = await axios.post(
          `${process.env.REACT_APP_SECRET_KEY}/action-type/${editId}`,
          matchObject,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        // console.log("delete",response)
        if (response.data.status === 200) {
          loading(false);
          modalClose();
          setDate("");
          listing();
          toast.success(response.data.message);
        } else {
          loading(false);
          toast.error(response.data.message);
        }
      } catch (error) {
        // loading(false)
        console.log(error);
      }
    }
  };

  return (
    <>
      <ModalBox
        show={editModal}
        onClose={() => {
          modalClose();
          setSelectAction("");
          setDate("");
          setError("");
        }}
        heading={"Action"}
        customizedFooter={
          <div className="d-flex ">
            <div className="mx-2 ">
              <button
                className="modalButtonClose btn btn-secondary"
                onClick={() => {
                  modalClose();
                  setSelectAction("");
                  setDate("");
                  setError("");
                }}
              >
                Cancel
              </button>
            </div>
            <div className="mx-2">
              <button
                className="modalButtonSubmit modalButtonYes btn btn-primary"
                ref={buttonRef}
                onClick={setSignOffValue}
              >
                Submit
              </button>
            </div>
          </div>
        }
      >
        <div>
          <label className="fs-6">Select Type</label>
          <select
            className={`custm-form-control ${
              !selectAction && error.selectType && "is-invalid"
            }`}
            onChange={(e) => setSelectAction(e.target.value)}
            name="selectType"
          >
            <option value="">Select Type</option>
            <option value="signOff">sign Off</option>
            <option value="proposedDate">Proposed date</option>
          </select>
          <div className="invalid-feedback fs-6">{error.selectType}</div>
        </div>
        <div className="">
          <label htmlFor="" className=" fs-6 mt-2">
            Select Date
          </label>
          <br />
          <DatePicker
            placeholderText="select Date"
            className={`form-control deliveryDatepicker ${
              !date && error.date && "is-invalid"
            }`}
            selected={date}
            value={date}
            name="date"
            showYearDropdown
            yearDropdownItemNumber={100}
            scrollableYearDropdown
            showMonthDropdown
            useShortMonthInDropdown
            onChange={(date) => setDate(date)}
            dateFormat="yyyy-MM-dd"
            // maxDate={new Date()}
          />
          {!date && error.date && (
            <p className="datePicker_validation_small fs-6">{error.date}</p>
          )}
        </div>
      </ModalBox>
    </>
  );
}

export default AddSingOffDate;

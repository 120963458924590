import React, { useRef, useState } from "react";

const usePasswordToggle = () => {
  const passwordref = useRef();
  const [toggleValue, setToggleValue] = useState(false);

  const toggle = () => {
    setToggleValue(!toggleValue);
  };
  const iconElement = () => {
    return (
      <>
        <span onClick={toggle} className="Iconn">
          {toggleValue ? (
            <i className="fa fa-eye Icon1" aria-hidden="true"></i>
          ) : (
            <i className="fa fa-eye-slash Icon1" aria-hidden="true"></i>
          )}
        </span>
      </>
    );
  };

  return [passwordref, toggleValue ? "text" : "password", iconElement];
};
export default usePasswordToggle;

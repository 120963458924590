import React, { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import useSearchBox from "../../Hooks/UseSearchBox";
import usePageLoader from "../../Hooks/UsePageLoader";
import axios from "axios";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import moment from "moment";
import DeleteAccess from "./DeleteAccess";

function AccessManagement() {
  const [permissionListing, setPermissionListing] = useState("");

  // const [statusId, setStatusId] = useState("");
  // const [status, setStatus] = useState("");
  // const [statusModal, setStatusModal] = useState(false);
  // const [updateId, setsetUpdateId] = useState("");
  // const [departmentName, setDeparrtmentName] = useState("");
  // const [updateModal, setUpdateModal] = useState(false);
  // const [aadDepartmentModal, setAddDepartmentModal] = useState(false);
  // const [total, setTotal] = useState(1);

  const [deleteId, setDeleteId] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const searchValue = useSearchBox(searchTerm, 650);
  const [loding, show, hide] = usePageLoader();
  const [activePage, setActivePage] = useState(1);
  const [total, setTotal] = useState(1);

  // console.log("permissionListing",permissionListing)

  //----------------department-API--------------------------
  const fetchAccessManagement = async () => {
    try {
      show();
      const token = localStorage.getItem("demo-token");
      const response = await axios.get(
        `${process.env.REACT_APP_SECRET_KEY}/get-permission?search=${searchValue}&page=${activePage}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log("permission", response.data.data?.permission?.total);
      if (response.data.status === 200) {
        hide();
        setPermissionListing(response.data.data.permission.listing);
        setTotal(response?.data?.data?.permission?.total);
      } else {
        hide();
        toast.error(response.data.message);
      }
    } catch (err) {
      console.log("form", err);
    }
  };

  // function addDepartmentHandler() {
  //   setAddDepartmentModal(true);
  // }

  // function statusHandler(_id, status) {
  //   setStatusId(_id);
  //   setStatus(status);
  //   setStatusModal(true);
  // }
  // function updateHandler(_id, departmentName) {
  //   setsetUpdateId(_id);
  //   setDeparrtmentName(departmentName);
  //   setUpdateModal(true);
  // }
  const deleteHandler = (_id) => {
    setDeleteId(_id);
    setDeleteModal(true);
  };
  const searchHandler = (event) => {
    setSearchTerm(event.target.value);
    // console.log(searchTerm)
  };

  useEffect(() => {
    if (searchValue) {
      setActivePage(1)
    } else {
      setActivePage(activePage)
    }
    fetchAccessManagement();
  }, [searchValue, activePage]);

  return (
    <>
      {loding}
      <div className="WrapperArea wrapper_area_cstm_employee">
        <div className="WrapperBox">
          <div className="Small-Wrapper">
            <div className="wrapper_employee_flex d-flex">
              <h4 className="Title">Access Management</h4>
              <div className="Small-Wrapper cstm_wraper_btn borderNone">
                <div className="cstmSearchBox">
                  <Link to="/Access-management/Add">
                    <i className="fa fa-upload mr-2" aria-hidden="true"></i>
                    Grant Permission
                  </Link>
                </div>
              </div>
            </div>
            <div className="Small-Wrapper cstm-small_wrapper_employee">
              <div className="row">
                <div className="SearchBox">
                  <form action="">
                    <input
                      type="text"
                      className="input_style"
                      placeholder="Search by employee name..."
                      onChange={searchHandler}
                    />
                  </form>
                </div>
              </div>
            </div>
            <div className="TableList">
              <table>
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Emp Name</th>
                    <th>Created On</th>
                    <th>Action</th>
                  </tr>
                </thead>

                <tbody>
                  {permissionListing.length > 0 ? (
                    permissionListing.map(
                      ({ _id, employee, status, createdAt }, index) => {
                        return (
                          <React.Fragment key={_id}>
                            <tr>
                              <td>{(activePage - 1) * 10 + ++index}</td>
                              <td>{employee?.name || "---"}</td>
                              <td>
                                {createdAt
                                  ? moment(createdAt).format("DD/MM/YYYY")
                                  : "---"}
                              </td>
                              <td>
                                <div className="dropdown wrap_cstm_dropdown_menu">
                                  <button
                                    className="btn btn-secondary cstm_btn_sceon_background"
                                    type="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <i className="fa fa-ellipsis-v"></i>
                                  </button>
                                  <ul className="dropdown-menu cstm_dropdown_edit">
                                    <li>
                                      <Link
                                        className="dropdown-item"
                                        // onClick={() =>
                                        //   updateHandler(_id, departmentName)
                                        // }
                                        to={`/Access-management/update/${_id}`}
                                      >
                                        <i className="fa fa-pencil-square-o mx-3"></i>
                                        Edit
                                      </Link>
                                    </li>
                                    <li>
                                      <Link
                                        className="dropdown-item"
                                        onClick={() => deleteHandler(_id)}
                                        to="#"
                                      >
                                        <i
                                          className="fa fa-trash-o mx-3"
                                          aria-hidden="true"
                                        ></i>
                                        Delete
                                      </Link>
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                          </React.Fragment>
                        );
                      }
                    )
                  ) : (
                    <tr>
                      <td colSpan={6}>Not Found</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            {total >= 10 ? (
              <nav
                aria-label="Page navigation example"
                className="mt-3 cstm_pagination"
              >
                <Pagination
                  previousLabel={"prev"}
                  nextLabel={"next"}
                  // breakLabel={'...'}
                  pageCount={activePage}
                  pageRangeDisplayed={5}
                  itemsCountPerPage={10}
                  totalItemsCount={total}
                  itemClass={"page-item"}
                  linkClass={"page-link"}
                  activeLinkClass={"active"}
                  activePage={activePage}
                  onChange={(page) => setActivePage(page)}
                />
              </nav>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>

      <DeleteAccess
        deleteId={deleteId}
        deleteModal={deleteModal}
        modalClose={() => setDeleteModal(false)}
        listing={fetchAccessManagement}
      />
      {/* <StatusChange
        statusId={statusId}
        status={status}
        statusModal={statusModal}
        modalClose={() => setStatusModal(false)}
        listing={fetchDepartment}
      /> */}

      {/* <AddDepartment
        modal={aadDepartmentModal}
        modalClose={() => setAddDepartmentModal(false)}
        listing={fetchDepartment}
      /> */}

      {/* <UpdateDepartment
        updateId={updateId}
        departmentName={departmentName}
        setDeparrtmentName={setDeparrtmentName}
        updateModal={updateModal}
        modalClose={() => setUpdateModal(false)}
        listing={fetchDepartment}
      /> */}
    </>
  );
}

export default AccessManagement;

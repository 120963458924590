import React from "react";
import ModalBox from "../../Modals/ModalBox";
import useButtonLoader from "../../Hooks/UseButtonLoader";
import axios from "axios";
import { toast } from "react-toastify";

function SendEmail({
  emailId,
  sendEmailStatus,
  emailModal,
  modalClose,
  employeeName,
  listing,
}) {
  const [buttonRef, loading] = useButtonLoader("Yes");
  //----------------Send-Email-API--------------------------

  const SendEmailEmployee = async () => {
    try {
      loading(true);
      const token = localStorage.getItem("demo-token");
      const response = await axios.put(
        `${process.env.REACT_APP_SECRET_KEY}/employee-mail`,
        {
          employeeId: emailId,
          sendEmailStatus: !sendEmailStatus,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.status === 200) {
        loading(false);
        modalClose()
        listing();
        toast.success(response.data.message);
      } else {
        loading(false);
        toast.error(response.data.message);
      }
    } catch (error) {
      loading(false);
      console.log(error);
    }
  };
  return (
    <>
    
      <ModalBox
        show={emailModal}
        onClose={modalClose}
        heading={"Send Email"}
        description= {<p>Are you sure you want to send an email to <strong>{employeeName}</strong>?</p>}
        action={SendEmailEmployee}
        buttonRef={buttonRef}
      />
    </>
  );
}

export default SendEmail;

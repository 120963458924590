import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import useButtonLoader from "../../Hooks/UseButtonLoader";
import axios from "axios";
import moment from "moment";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import usePageLoader from "../../Hooks/UsePageLoader";

function UpdateForm() {
  const { userId } = useParams();
  const [error, setError] = useState("");
  const [startDate, setStartDate] = useState("");
  const [relievingDate, setRelievingDate] = useState("");
  const [profile, setProfile] = useState("");

  const [department, setDepartment] = useState("");
  const [designation, setDesignation] = useState("");
  const [buttonref, loading] = useButtonLoader("Submit");
  const [loader, show, hide] = usePageLoader();
  const navigator = useNavigate();
  const [image, setImage] = useState("");
  const [imageUlr, setImageUrl] = useState("");
  const [employeeValue, setEmployeeValue] = useState({
    employeeId: "",
    email: "",
    previousExperienceInYear: "",
    previousExperienceInMonth: "",
    panNumber: "",
    adress: "",
    // startDate: "",
    // relievingDate: "",
    name: "",
    phoneNumber: "",
    mobileNumber: "",
    department: "",
    adharNumber: "",
    designation: "",
    profileimage: "",
  });

  let errorMessage = {
    employeeId: "",
    email: "",
    previousExperienceInYear: "",
    previousExperienceInMonth: "",
    panNumber: "",
    adress: "",
    startDate: "",
    relievingDate: "",
    name: "",
    phoneNumber: "",
    department: "",
    adharNumber: "",
    designation: "",
    profileimage: "",
    status: true,
  };

  let validation = () => {
    // let status = true;

    const emailregex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    const numberregex = /^\d{10}$/;
    const panRegex = /^([A-Z]{5})(\d{4})([A-Z]{1})$/;
    const aadhaarRegex = /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/;
    const alphNumeric = /^[a-zA-Z0-9]+$/;
    const imageExtensions = /\.(png|jpe?g)$/i;

    if (!employeeValue.employeeId) {
      errorMessage["employeeId"] = "Please Enter employee Id";
      errorMessage.status = false;
    } else if (!alphNumeric.test(employeeValue.employeeId)) {
      errorMessage["employeeId"] = "Please Enter Valid employeeId";
      errorMessage.status = false;
    }

    if (!employeeValue.email) {
      errorMessage["email"] = "Please Enter email";
      errorMessage.status = false;
    } else if (!emailregex.test(employeeValue.email)) {
      errorMessage["email"] = "Please Enter Valid Email";
      errorMessage.status = false;
    }

    if (employeeValue.previousExperienceInYear === "") {
      errorMessage["previousExperienceInYear"] =
        "Please Enter previous experiance Year";
      errorMessage.status = false;
    }
    if (employeeValue.previousExperienceInMonth ==="") {
      errorMessage["previousExperienceInMonth"] =
        "Please Enter previous experiance month";
      errorMessage.status = false;
    }

    if (!employeeValue.panNumber) {
      errorMessage["panNumber"] = "Please Enter pan number";
      errorMessage.status = false;
    } else if (!panRegex.test(employeeValue.panNumber)) {
      errorMessage["panNumber"] = "Please Enter valid Pan Number";
      errorMessage.status = false;
    }

    if (!employeeValue.adress) {
      errorMessage["adress"] = "Please Enter address";
      errorMessage.status = false;
    }

    if (!startDate) {
      errorMessage["startDate"] = "Please Enter start Date";
      errorMessage.status = false;
    }
    // if (!relievingDate) {
    //   errorMessage["relievingDate"] = "Please Enter Relieving Date";
    //   errorMessage.status = false;
    // }

    if (!employeeValue.name) {
      errorMessage["name"] = "Please Enter your name";
      errorMessage.status = false;
    }

    if (!employeeValue.phoneNumber) {
      errorMessage["phoneNumber"] = "Please Enter mobile Number";
      errorMessage.status = false;
    } else if (!numberregex.test(employeeValue.phoneNumber)) {
      errorMessage["phoneNumber"] = "Please Enter 10 digit Number";
      errorMessage.status = false;
    }

    if (!employeeValue.department) {
      errorMessage["department"] = "Please select department";
      errorMessage.status = false;
    }

    if (!employeeValue.adharNumber) {
      errorMessage["adharNumber"] = "Please Enter aadhar number";
      errorMessage.status = false;
    } else if (!aadhaarRegex.test(employeeValue.adharNumber)) {
      errorMessage["adharNumber"] = "Please Enter valid aadhar No";
      errorMessage.status = false;
    }

    if (!employeeValue.designation) {
      errorMessage["designation"] = "Please select designation";
      errorMessage.status = false;
    }

    // if (!image) {
    //   errorMessage["profileimage"] = "Please choose your profileimage";
    //   status = false;
    // }
    if (image) {
      if (!imageExtensions.test(image.name)) {
        errorMessage["profileimage"] = "Invalid image format. Please use JPG, JPEG, PNG, SVG."
        errorMessage.status = false;
      }
    }
    setError(errorMessage);
    return errorMessage.status;
  };
  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    setEmployeeValue({ ...employeeValue, [name]: value });
  };
  //----------------department-API--------------------------
  const fetchDepartment = async () => {
    try {
      const token = localStorage.getItem("demo-token");
      const response = await axios.get(
        `${process.env.REACT_APP_SECRET_KEY}/master-data-department`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.status === 200) {
        setDepartment(response.data.data.departments);
      } else {
        toast.error(response.data.message);
      }
    } catch (err) {
      console.log("form", err);
    }
  };

  //----------------designation-API--------------------------
  const fetchdesignation = async () => {
    try {
      const token = localStorage.getItem("demo-token");
      const response = await axios.get(
        `${process.env.REACT_APP_SECRET_KEY}/master-data-designation`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.status === 200) {
        setDesignation(response.data.data.designations);
      } else {
        toast.error(response.data.message);
      }
    } catch (err) {
      console.log("form", err);
    }
  };
  //-----------------Add-emloyees-API----------------------

  const setEmployee = async (e) => {
    e.preventDefault();
    let status = validation();
    if (status) {
      try {
        loading(true);
        const token = localStorage.getItem("demo-token");
        const Sdate = moment(startDate).format("YYYY-MM-DD");
        const Rdate = relievingDate
          ? moment(relievingDate).format("YYYY-MM-DD")
          : "";
        const formdata = new FormData();
        formdata.append("employeeId", employeeValue.employeeId);
        formdata.append("email", employeeValue.email);
        formdata.append(
          "previousExperienceInYear",
          employeeValue.previousExperienceInYear
        );
        formdata.append(
          "previousExperienceInMonth",
          employeeValue.previousExperienceInMonth
        );
        formdata.append("panNumber", employeeValue.panNumber);
        formdata.append("adress", employeeValue.adress);
        formdata.append("startDate", Sdate);
        formdata.append("name", employeeValue.name);
        formdata.append("phoneNumber", employeeValue.phoneNumber);
        formdata.append("mobileNumber", employeeValue.mobileNumber ? employeeValue.mobileNumber : "");
        formdata.append("department", employeeValue.department);
        formdata.append("adharNumber", employeeValue.adharNumber);
        formdata.append("designation", employeeValue.designation);
        formdata.append("profileimage", image ? image : "");
        formdata.append("relievingDate", Rdate ? Rdate : "");

        const response = await axios.put(
          `${process.env.REACT_APP_SECRET_KEY}/employee-update/${userId}`,
          formdata,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "content-type": "multipart/form-data",
            },
          }
        );
        if (response.data.status === 200) {
          loading(false);
          toast.success(response.data.message);
          navigator("/employee-Management");
        } else {
          loading(false);
          toast.error(response.data.message);
        }
      } catch (err) {
        loading(false);
        console.log(err);
      }
    }
  };

  //-----------------get-emloyees-API----------------------
  const getEmployee = async () => {
    try {
      show();
      const token = localStorage.getItem("demo-token");
      const response = await axios.get(
        `${process.env.REACT_APP_SECRET_KEY}/employee-detailes/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.status === 200) {
        hide();
        setEmployeeValue({
          ...response.data.data.employeeDetailes,
          department: response.data.data.employeeDetailes.department?._id,
          designation: response.data.data.employeeDetailes.designation?._id,
        });
        setStartDate(new Date(response.data.data.employeeDetailes.startDate));
        setRelievingDate(
          response.data.data.employeeDetailes.relievingDate
            ? new Date(response.data.data.employeeDetailes.relievingDate)
            : ""
        );
        setProfile(
          response.data.data.profileImageUrl
            ? response.data.data.profileImageUrl
            : ""
        );
      } else {
        toast.error(response.data.message);
        hide();
      }
    } catch (err) {
      console.log("form", err);
    }
  };

  const allowedTypes = [
    "image/jpg",
    "image/jpeg",
    "image/png",
    "image/webp",
    "image/svg",
    "image/tif",
    "image/tiff",
    "image/bmp",
    "image/gif",
  ];

  function imageHandler(file) {
    setImage(file);
    setImageUrl(file !== "" && URL.createObjectURL(file));
  }

  useEffect(() => {
    fetchDepartment();
    fetchdesignation();
    getEmployee();
  }, []);

  return (
    <>
      {loader}
      <div className="WrapperArea">
        <div className="WrapperBox">
          <div className="Small-Wrapper borderRadius">
            <h4 className="Title">
              <span>
                <Link to="/employee-Management">
                  <i className="fa fa-arrow-left" aria-hidden="true"></i>
                </Link>
              </span>
              Update Information
            </h4>
            <div className="container mt-4">
              <form className="needs-validation">
                <div className="row">
                  <div className="col-lg-6 col-md-6">
                    <label htmlFor="EmployeeId" className="col-form-label">
                      Employee Id
                    </label>
                    <br />
                    <input
                      type="text"
                      className={`form-control ${!employeeValue.employeeId
                          ? error.employeeId && "is-invalid"
                          : error.employeeId && "is-invalid"
                        }`}
                      placeholder="enter your Id here"
                      name="employeeId"
                      id="EmployeeId"
                      onChange={onChangeHandler}
                      value={employeeValue.employeeId}
                      maxLength={8}
                    />
                    <div className="invalid-feedback">{error.employeeId}</div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <label htmlFor="name" className="col-form-label">
                      Name
                    </label>
                    <br />
                    <input
                      type="text"
                      placeholder="enter your name Name"
                      className={`form-control ${!employeeValue.name && error.name && "is-invalid"
                        }`}
                      name="name"
                      id="name"
                      onChange={onChangeHandler}
                      value={employeeValue.name}
                      maxLength={22}
                    />
                    <div className="invalid-feedback">{error.name}</div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <label htmlFor="email" className="col-form-label">
                      Email
                    </label>
                    <br />
                    <input
                      type="text"
                      className={`form-control ${!employeeValue.email
                          ? error.email && "is-invalid"
                          : error.email && "is-invalid"
                        }`}
                      placeholder="enter your email here "
                      name="email"
                      id="email"
                      onChange={onChangeHandler}
                      value={employeeValue.email}
                      maxLength={45}
                    />
                    <div className="invalid-feedback">{error.email}</div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <label htmlFor="phone" className="col-form-label">
                      Mobile No
                    </label>
                    <br />
                    <input
                      type="texts"
                      placeholder="enter your mobile no here"
                      className={`form-control ${!employeeValue.phoneNumber
                          ? error.phoneNumber && "is-invalid"
                          : error.phoneNumber && "is-invalid"
                        }`}
                      name="phoneNumber"
                      id="phone"
                      // onChange={onChangeHandler}
                      onChange={(e) => {
                        const inputText = e.target.value;
                        const sanitizedText = inputText.replace(/[^0-9]/g, ""); // Remove non-digit characters
                        const maxLength = 10;
                        const truncatedText = sanitizedText.slice(0, maxLength);
                        e.target.value = truncatedText;
                        onChangeHandler(e);
                      }}
                      value={employeeValue.phoneNumber}
                    />
                    <div className="invalid-feedback">{error.phoneNumber}</div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <label htmlFor="Experiance" className="col-form-label">
                      Previous Experience
                    </label>
                    <div className="d-flex justify-content-between">
                      <div className="previousExperiance_input">
                        <select
                          className={`custm-form-control ${!employeeValue.previousExperienceInYear &&
                            error.previousExperienceInYear &&
                            "is-invalid"
                            }`}
                          name="previousExperienceInYear"
                          onChange={onChangeHandler}
                          value={employeeValue.previousExperienceInYear}
                        >
                          <option value="">Choose year</option>
                          {Array.from({ length: 16 }, (_, index) => (
                            <option key={index} value={index}>
                              {index} years
                            </option>
                          ))}
                        </select>

                        <div className="invalid-feedback">
                          {error.previousExperienceInYear}
                        </div>
                      </div>
                      <div className="previousExperiance_input">
                        <select
                          className={`custm-form-control ${!employeeValue.previousExperienceInMonth &&
                            error.previousExperienceInMonth &&
                            "is-invalid"
                            }`}
                          placeholder="enter experiance in month"
                          name="previousExperienceInMonth"
                          id="Experiance"
                          onChange={onChangeHandler}
                          value={employeeValue.previousExperienceInMonth}
                        >
                          <option value="">Choose month</option>
                          {Array.from({ length: 13 }, (_, index) => (
                            <option key={index} value={index}>
                              {index} month
                            </option>
                          ))}
                        </select>
                        <div className="invalid-feedback">
                          {error.previousExperienceInMonth}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <label htmlFor="otherNo" className="col-form-label">
                      Other Number
                    </label>
                    <br />
                    <input
                      type="text"
                      placeholder="Enter your other no here"
                      className="form-control"
                      name="mobileNumber"
                      id="otherNo"
                      value={employeeValue?.mobileNumber || ""}
                      onChange={(e) => {
                        const inputText = e.target.value;
                        const sanitizedText = inputText.replace(/[^0-9]/g, ""); // Remove non-digit characters
                        const maxLength = 15;
                        const truncatedText = sanitizedText.slice(0, maxLength);
                        e.target.value = truncatedText;
                        onChangeHandler(e);
                      }}
                    />
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <label htmlFor="pan" className="col-form-label">
                      Pan No
                    </label>
                    <br />
                    <input
                      type="text"
                      className={`form-control ${!employeeValue.panNumber
                          ? error.panNumber && "is-invalid"
                          : error.panNumber && "is-invalid"
                        }`}
                      placeholder="enter your PAN number here"
                      name="panNumber"
                      id="pan"
                      // onChange={onChangeHandler}
                      value={employeeValue?.panNumber || ""}
                      onChange={(e) => {
                        const inputText = e.target.value;
                        const sanitizedText = inputText.toUpperCase(); // Convert to uppercase
                        const maxLength = 10;
                        const truncatedText = sanitizedText.slice(0, maxLength);
                        e.target.value = truncatedText;
                        onChangeHandler(e);
                      }}
                    />
                    <div className="invalid-feedback">{error.panNumber}</div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <label htmlFor="Aadhar" className="col-form-label">
                      Aadhar No
                    </label>
                    <br />
                    <input
                      type="text"
                      placeholder="enter your aadhar no here"
                      className={`form-control ${!employeeValue.adharNumber
                          ? error.adharNumber && "is-invalid"
                          : error.adharNumber && "is-invalid"
                        }`}
                      name="adharNumber"
                      id="Aadhar"
                      // onChange={onChangeHandler}
                      onChange={(e) => {
                        const inputText = e.target.value;
                        const sanitizedText = inputText.replace(/[^0-9]/g, ""); // Remove non-digit characters
                        const maxLength = 12;
                        const truncatedText = sanitizedText.slice(0, maxLength);
                        e.target.value = truncatedText;
                        onChangeHandler(e);
                      }}
                      value={employeeValue?.adharNumber || ""}
                    />
                    <div className="invalid-feedback">{error.adharNumber}</div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <label htmlFor="address" className="col-form-label">
                      Address
                    </label>
                    <br />
                    <textarea
                      type="text"
                      className={`form-control ${!employeeValue.adress && error.adress && "is-invalid"
                        }`}
                      placeholder="enter your address here "
                      name="adress"
                      id="address"
                      onChange={onChangeHandler}
                      value={employeeValue?.adress || ""}
                      maxLength={250}
                    />
                    <div className="invalid-feedback">{error.adress}</div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <label htmlFor="Department" className="col-form-label">
                      Department
                    </label>
                    <select
                      className={`custm-form-control ${!employeeValue.department &&
                        error.department &&
                        "is-invalid"
                        }`}
                      name="department"
                      id="Department"
                      onChange={onChangeHandler}
                      value={employeeValue?.department || ""}
                    >
                      <option value="">Select type</option>
                      {department &&
                        department.map(({ departmentName, _id }, idindex) => {
                          return (
                            <React.Fragment key={idindex}>
                              <option value={_id}>{departmentName}</option>
                            </React.Fragment>
                          );
                        })}
                    </select>
                    <div className="invalid-feedback">{error.department}</div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="row cstm_datepicker_row">
                      <div className="col-lg-6 col-md-6">
                        <label htmlFor="date" className="col-form-label">
                          Start Date
                        </label>
                        <DatePicker
                          placeholderText="Start Date"
                          className={`inputArea form-control ${!startDate && error.startDate && "is-invalid"
                            }`}
                          selected={startDate}
                          // value={startDate}
                          showYearDropdown
                          yearDropdownItemNumber={100}
                          scrollableYearDropdown
                          showMonthDropdown
                          useShortMonthInDropdown
                          onChange={(date) => setStartDate(date)}
                          dateFormat="yyyy-MM-dd"
                          maxDate={new Date(relievingDate)}
                        />
                        {!startDate && error.startDate && (
                          <p className="datePicker_validation_small">
                            {error.startDate}
                          </p>
                        )}
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <label
                          htmlFor="relievingDate"
                          className="col-form-label"
                        >
                          Relieving Date
                        </label>
                        <DatePicker
                          placeholderText="End Date"
                          className="inputArea form-control"
                          // className={`inputArea form-control ${!relievingDate &&
                          //   error.relievingDate &&
                          //   "is-invalid"
                          //   }`}
                          selected={relievingDate ? relievingDate : ""}
                          // value={ relievingDate? relievingDate: "" }
                          showYearDropdown
                          yearDropdownItemNumber={100}
                          scrollableYearDropdown
                          showMonthDropdown
                          useShortMonthInDropdown
                          onChange={(date) => setRelievingDate(date)}
                          dateFormat="yyyy-MM-dd"
                          minDate={new Date(startDate)}
                        />
                        {/* {!relievingDate && error.relievingDate && (
                          <p className="datePicker_validation_small">
                            {error.relievingDate}{" "}
                          </p>
                        )} */}

                        <div className="invalid-feedback">
                          {error.relievingDate}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <label htmlFor="Designation" className="col-form-label">
                      Designation
                    </label>
                    <br />
                    <select
                      className={`custm-form-control ${!employeeValue.designation &&
                        error.designation &&
                        "is-invalid"
                        }`}
                      name="designation"
                      id="Designation"
                      onChange={onChangeHandler}
                      value={employeeValue.designation}
                    >
                      <option value="">Select type</option>
                      {designation &&
                        designation.map(({ designationName, _id }, idindex) => {
                          return (
                            <React.Fragment key={idindex}>
                              <option value={_id}>{designationName}</option>
                            </React.Fragment>
                          );
                        })}
                    </select>
                    <div className="invalid-feedback">{error.designation}</div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <label htmlFor="profileimage" className="col-form-label">
                      profile image
                    </label>
                    <br />
                    {/* <div onClick={()=>document.getElementById('profileimage').click()}>Click</div> */}
                    <input
                      type="file"
                      className={`form-control ${image && error.profileimage && "is-invalid"}`}
                      name="profileimage"
                      id="profileimage"
                      onChange={(e) =>
                        imageHandler(e.target.files[0] ? e.target.files[0] : "")
                      }
                      // value={employeeValue.profileimage}
                      accept="image/jpg ,image/jpeg,image/png "
                    // hidden
                    />
                    <div className="invalid-feedback">{error.profileimage}</div>

                    {profile && (
                      <img className="profileImage" alt="" src={profile} />
                    )}
                    {/* {
                        image &&  <img className="profileImage" alt="" src={imageUlr} /> 
                      } */}
                  </div>
                </div>
                <div className="width_btn_cstm">
                  <button
                    className="butonColor my-3"
                    type="submit"
                    onClick={setEmployee}
                    ref={buttonref}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UpdateForm;

import moment from "moment";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import ModalBox from "../../../Modals/ModalBox";
import useButtonLoader from "../../../Hooks/UseButtonLoader";
import { toast } from "react-toastify";
import axios from "axios";

function RequestMember({ requestMember,listing }) {
  const [acceptId, setAcceptId] = useState("");
  const [acceptStatus, setAcceptStatus] = useState("");
  const [acceptModal, setAcceptModal] = useState(false);
  const [rejectId, setRejectId] = useState("");
  const [rejectModal, setRejectModal] = useState(false);
  const [buttonRef, loading] = useButtonLoader("Yes");
  const [buttonRefReject, loadingReject] = useButtonLoader("Yes")

  // console.log("acceptId",acceptId)
  // console.log("rejectId",rejectId)
  // console.log("requestMember",requestMember)
  
  const projectMemberFilter =
    requestMember.length > 0 &&
    requestMember.filter(({ accept }) => {
      return accept === false;
    });

  const acceptHandler = (_id,accept) => {
    setAcceptId(_id);
    setAcceptStatus(accept);
    setAcceptModal(true);
  };
  const rejectHandler = (_id) => {
    setRejectId(_id);
    setRejectModal(true);
  };

  //----------------accept-Member-Api-------------------

  const acceptMember = async () => {
    // console.log("checkStatus",status)
    try {
      loading(true);
      const token = localStorage.getItem("demo-token");
      const response = await axios.put(
        `${process.env.REACT_APP_SECRET_KEY}/accept-for-member/${acceptId}`,
        {
          accept:!acceptStatus
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    //   console.log("rsp-userstatus", response);
      if (response.data.status === 200) {
        loading(false);
        setAcceptModal(false);
        listing();
        toast.success(response.data.message);
      } else {
        loading(false);
        toast.error(response.data.message);
      }
    } catch (error) {
      loading(false);
      console.log(error);
    }
  };

   //----------------accept-Member-Api-------------------
  const rejectMember = async () => {
    try {
      loadingReject(true);
      const token = localStorage.getItem("demo-token");
      const response = await axios.delete(
        `${process.env.REACT_APP_SECRET_KEY}/reject-Member-req/${rejectId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log("delete",response)
      if (response.data.status === 200) {
        loadingReject(false);
        setRejectModal(false);
        listing();
        toast.success(response.data.message);
      } else {
        loadingReject(false);
        toast.error(response.data.message);
      }
    } catch (error) {
      loadingReject(false)
      console.log(error);
    }
  };
  return (
    <>
      <div className="">
        <div className=" ">
          <h4 className="Title"> Request Member </h4>
          <div className="TableList">
            <table>
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Department</th>
                  <th>Employee</th>
                  <th>Work Assigned For</th>
                  <th>Start date</th>
                  <th>End date</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {projectMemberFilter.length > 0 ? (
                  projectMemberFilter.map(
                    (
                      {
                        accept,
                        createdAt,
                        departments,
                        employee,
                        endDate,
                        isRelease,
                        pId,
                        projectId,
                        startDate,
                        updatedAt,
                        workAssignedFor,
                        __v,
                        _id,
                      },
                      index
                    ) => {
                      return (
                        <React.Fragment key={_id}>
                          <tr>
                            <td>{++index}</td>
                            <td>{departments || "---"}</td>
                            <td>{employee || "---"}</td>
                            <td>{workAssignedFor || "---"}</td>
                            <td>
                              {startDate
                                ? moment(startDate).format("DD-MM-YYYY")
                                : `---`}
                            </td>
                            <td>
                              {endDate
                                ? moment(endDate).format("DD-MM-YYYY")
                                : `---`}
                            </td>
                            <td>
                              <div className="dropdown wrap_cstm_dropdown_menu">
                                <button
                                  className="btn btn-secondary cstm_btn_sceon_background"
                                  type="button"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <i className="fa fa-ellipsis-v"></i>
                                </button>
                                <ul className="dropdown-menu cstm_dropdown_edit">
                                  <li>
                                    <Link
                                      className="dropdown-item"
                                      onClick={() =>
                                        acceptHandler(_id, accept)
                                      }
                                      to="#"
                                    >
                                      <i className="fa fa-check mx-3"></i>
                                      Accept
                                    </Link>
                                  </li>
                                  <li>
                                    <Link
                                      className="dropdown-item"
                                      onClick={() =>
                                        rejectHandler(_id)
                                      }
                                      to="#"
                                    >
                                      <i className="fa fa-times mx-3"></i>
                                      Reject
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </td>
                          </tr>
                        </React.Fragment>
                      );
                    }
                  )
                ) : (
                  <tr>
                    <td colSpan={7}>No any request</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <ModalBox
        show={acceptModal}
        onClose={()=>setAcceptModal(false)}
        heading={"Accept Member"}
        description={"Are you sure want to accept this member?"}
        action={acceptMember}
        buttonRef={buttonRef}
      />
       <ModalBox
        show={rejectModal}
        onClose={()=>setRejectModal(false)}
        heading={"Reject Member"}
        description={"Are you sure want to Reject this member?"}
        action={rejectMember}
        buttonRef={buttonRefReject}
      />
    </>
  );
}

export default RequestMember;

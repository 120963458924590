import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import React from 'react'


const ModalBox=({show,onClose,heading,description,action,customizedFooter,children,buttonRef,size})=> {
  return (
   <>
   <div>
   <Modal className={`ModalBox-${size}`} show={show} onHide={onClose}>
        <Modal.Header closeButton>
          <Modal.Title className="fs-5">{heading}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='fs-5'>{description ? description : children}</Modal.Body>
        <Modal.Footer >
          {customizedFooter ? customizedFooter : <>
          <Button variant="secondary" onClick={onClose}>
            No
          </Button>
          <Button className="modalButtonYes" onClick={action} ref={buttonRef}>
            Yes
          </Button></>}
        </Modal.Footer>
      </Modal>
      </div>
   </>
   
  )
}

export default ModalBox

import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export const AxiosInterceptor = ({ children }) => {
  const [isSet, setIsSet] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const resInterceptor = (response) => {
      if (response?.data?.status === 401 || response?.data?.status === 402) {
        localStorage.removeItem('demo-token')
        navigate("/");
      }
      return response;
    };

    const errInterceptor = (error) => {
      return error;
    };

    const interceptor = axios.interceptors.response.use(
      resInterceptor,
      errInterceptor
    );
    setIsSet(true);

    return () => axios.interceptors.response.eject(interceptor);
    // eslint-disable-next-line
  }, []);

  return isSet && children;
};

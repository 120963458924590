import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import usePageLoader from "../../Hooks/UsePageLoader";
import ChangeStatusSendEmail from "./ChangeStatusSendEmail";
import SendEmail from "./SendEmail";
import CheckBox from "../../Modals/CheckBox";
import ModalBox from "../../Modals/ModalBox";

function ViewForm() {
  const [employeeValue, setEmployeeValue] = useState("");
  const [loader, show, hide] = usePageLoader();
  const [profile, setProfile] = useState("");
  const { userId } = useParams();
  const [statusModal, setStatusModal] = useState(false);
  const [sendEmailId, setSendEmailId] = useState("");
  const [emailStatus, setEmailStatus] = useState("");
  const [emailModal, setEmailModal] = useState(false);
  const [employeeName, setEmployeeName] = useState("");
  const [showImageModal, setShowImageModal] = useState(false);

  //-------send-email-onchange------------
  const onChangeCheckbox = (userId, sendEmailStatus, name) => {
    setSendEmailId(userId);
    setEmailStatus(sendEmailStatus);
    setEmployeeName(name);
    setEmailModal(true);
  };
  //-------send-email-status-toggle-change-----------
  function statusHandler(userId, sendEmailStatus) {
    setSendEmailId(userId);
    setEmailStatus(sendEmailStatus);
    setStatusModal(true);
  }

  //-----------------get-emloyees-API----------------------
  const getEmployee = async () => {
    try {
      show();
      const token = localStorage.getItem("demo-token");
      const response = await axios.get(
        `${process.env.REACT_APP_SECRET_KEY}/employee-detailes/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log("viewEmp",response.data.data)
      if (response.data.status === 200) {
        hide();
        setEmployeeValue({
          ...response.data.data.employeeDetailes,

          department: response.data.data.employeeDetailes.department
            ?.departmentName
            ? response.data.data.employeeDetailes.department.departmentName
            : "",

          designation: response.data.data.employeeDetailes.designation
            ?.designationName
            ? response.data.data.employeeDetailes.designation.designationName
            : "",

          startDate: moment(
            response.data.data.employeeDetailes.startDate
          ).format("YYYY-MM-DD"),

          relievingDate: response.data.data.employeeDetailes.relievingDate
            ? moment(response.data.data.employeeDetailes.relievingDate).format(
              "YYYY-MM-DD"
            )
            : "",
        });
        setProfile(
          response.data.data.profileImageUrl
            ? response.data.data.profileImageUrl
            : ""
        );
      } else {
        toast.error(response.data.message);
        hide();
      }
    } catch (err) {
      console.log("form", err);
    }
  };

  useEffect(() => {
    getEmployee();
  }, []);

  return (
    <>
      {loader}
      <div className="WrapperArea">
        <div className="WrapperBox">
          <div className="Small-Wrapper">
            <h4 className="Title">
              <span>
                <Link to="/employee-Management">
                  <i className="fa fa-arrow-left" aria-hidden="true"></i>
                </Link>
              </span>
              Employee Details
            </h4>
            <div className="OrderArea">
              <ul>
                <li>
                  {profile ? (
                    <>
                      <img
                        className="viewEmpProfile"
                        src={profile}
                        alt={profile}
                        onClick={() => setShowImageModal(true)} />
                    </>
                  ) : (
                    <>
                      <img
                        className="viewEmpProfile"
                        src="./images/user.png"
                        alt=""
                      />
                    </>
                  )}
                </li>
                <li>
                  <span>Employee Id</span>
                  {employeeValue.employeeId || `---`}
                </li>
                <li>
                  <span>Employee Name</span>
                  {employeeValue.name || `---`}
                </li>
                <li>
                  <span>Email</span>
                  {employeeValue.email || `---`}
                </li>
                <li>
                  <span>Mobile number </span>
                  {employeeValue.phoneNumber || `---`}
                </li>
                <li>
                  <span>Other number </span>
                  {employeeValue?.mobileNumber === undefined ? "----" : employeeValue?.mobileNumber || `---`}
                </li>
                <li>
                  <span>Previous Experiance Year</span>
                  {employeeValue.previousExperienceInYear ||
                    employeeValue.previousExperienceInYear === 0
                    ? `${employeeValue.previousExperienceInYear} Year`
                    : `---`}
                </li>
                <li>
                  <span>Previous Experiance Month</span>
                  {employeeValue.previousExperienceInMonth || 
                  employeeValue.previousExperienceInMonth === 0
                    ? `${employeeValue.previousExperienceInMonth} Month`
                    : `---`}
                </li>
                <li>
                  <span>Designation</span>
                  {employeeValue?.designation || `---`}
                </li>
                <li>
                  <span>Department </span>
                  {employeeValue?.department || `---`}
                </li>
                <li>
                  <span>Start Date</span>
                  {employeeValue.startDate || `---`}
                </li>
                <li>
                  <span>Relieving Date</span>
                  {employeeValue?.relievingDate || `---`}
                </li>
                <li>
                  <span>Pan Number</span>
                  {employeeValue?.panNumber || `---`}
                </li>
                <li>
                  <span>Addhar Number</span>
                  {employeeValue?.adharNumber || `---`}
                </li>
                <li className="cstm-li_fleX">
                  <span>Address</span>
                  <p>{employeeValue?.adress || `---`}</p>
                </li>
                <li>
                  <span>Verifed By</span>
                  {employeeValue?.verifyBy || `---`}
                </li>
                <li className="d-flex">
                  <span>Email status</span>
                  <CheckBox
                    status={employeeValue.sendEmailStatus || false}
                    onChange={() =>
                      statusHandler(
                        employeeValue._id,
                        employeeValue.sendEmailStatus
                      )
                    }
                  />
                </li>
                {employeeValue.sendEmailStatus === false ? (
                  <li>
                    <span>Access</span>
                    <button
                      className="sendEmail"
                      onClick={() =>
                        onChangeCheckbox(
                          employeeValue._id,
                          employeeValue.sendEmailStatus,
                          employeeValue.name
                        )
                      }
                    >
                      send
                    </button>
                  </li>
                ) : (
                  <li>
                    <span>Access</span>
                    <button className="sentEmail">sent</button>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* ----------show-image-in-modal--------------- */}
      <ModalBox
        show={showImageModal}
        onClose={() => setShowImageModal(false)}
        customizedFooter={
          <button
            className="btn btn-secondary"
            onClick={() => setShowImageModal(false)}
          >Close</button>
        }
      >
        <img
          className="preview-image"
          src={profile}
          alt={profile}
          onClick={() => setShowImageModal(false)}
        />
      </ModalBox>

      <ChangeStatusSendEmail
        emailId={sendEmailId}
        sendEmailStatus={emailStatus}
        emailModal={statusModal}
        modalClose={() => setStatusModal(false)}
        listing={getEmployee}
      />

      <SendEmail
        emailId={sendEmailId}
        sendEmailStatus={emailStatus}
        emailModal={emailModal}
        modalClose={() => setEmailModal(false)}
        employeeName={employeeName}
        listing={getEmployee}
      />
    </>
  );
}

export default ViewForm;

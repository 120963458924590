import React, { useState } from "react";
import ModalBox from "../../Modals/ModalBox";
import useButtonLoader from "../../Hooks/UseButtonLoader";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { toast } from "react-toastify";
import moment from "moment";

function ReturnInventory({ returnModal, modalClose, listing ,employeeId,inventoryId}) { 
  const [date, setDate] = useState(new Date());
  const [error, setError] = useState({});
  const [buttonRef, loading] = useButtonLoader("Submit");

  const validation = () => {
    let errorMessage = {
      date: "",
    };
    let status = true;

    if (!date) {
      errorMessage["date"] = "Please select date ";
      status = false;
    }
    setError(errorMessage);
    return status;
  };

  const returnDate = async () => {
    const status = validation();
    if (status) {
      try {
        loading(true);
        const token = localStorage.getItem("demo-token");
        const formatDate = moment(date).format("YYYY-MM-DD")
        const response = await axios.post(
          `${process.env.REACT_APP_SECRET_KEY}/return-inventory`,
          {
            employeeId:employeeId,
            inventoryId:inventoryId,
            returnDate:formatDate,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log("AddCatogry", response);
        if (response.data.status === 200) {
          loading(false);
          modalClose();
          listing();
          toast.success(response.data.message);
        } else {
          loading(false);
          toast.error(response.data.message);
        }
      } catch (error) {
        loading(false);
        console.log(error);
      }
    }
  };


  return (
    <>
      <ModalBox
        show={returnModal}
        onClose={() => {
          modalClose();
          setDate(new Date());
          //   setSelectAction("");
          //   setError("");
        }}
        heading={"Return Inventory"}
        customizedFooter={
          <div className="d-flex ">
            <div className="mx-2 ">
              <button
                className="modalButtonClose btn btn-secondary"
                onClick={() => {
                  modalClose();
                  setDate(new Date());
                  //   setSelectAction("");
                  //   setError("");
                }}
              >
                Cancel
              </button>
            </div>
            <div className="mx-2">
              <button
                className="modalButtonSubmit modalButtonYes btn btn-primary"
                ref={buttonRef}
                onClick={returnDate}
              >
                Submit
              </button>
            </div>
          </div>
        }
      >
        <div className="">
          <label htmlFor="" className=" fs-6 mt-2">
            Select Date
          </label>
          <br />
          <DatePicker
            placeholderText="select Date"
            className={`form-control deliveryDatepicker ${
              !date && error.date && "is-invalid"
            }`}
            selected={date}
            value={date}
            name="date"
            showYearDropdown
            yearDropdownItemNumber={100}
            scrollableYearDropdown
            showMonthDropdown
            useShortMonthInDropdown
            onChange={(date) => setDate(date)}
            dateFormat="yyyy-MM-dd"
            // maxDate={new Date()}
          />
          {!date && error.date && (
            <p className="datePicker_validation_large fs-6">{error.date}</p>
          )}
        </div>
      </ModalBox>
    </>
  );
}

export default ReturnInventory;

import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import Pagination from "react-js-pagination";
import usePageLoader from "../../Hooks/UsePageLoader";
import useSearchBox from "../../Hooks/UseSearchBox";
import DeleteInventory from "./DeleteInventory";
import useButtonLoader from "../../Hooks/UseButtonLoader";
import AssignPerson from "./AssignPerson";
import { IoMdRefresh } from "react-icons/io";
import DownloadExcel from "./DownloadExcel";

function InventoryManagement() {
  const [searchTerm, setSearchTerm] = useState("");
  const searchValue = useSearchBox(searchTerm, 650);
  const [loding, show, hide] = usePageLoader();
  const [activePage, setActivePage] = useState(1);
  const [total, setTotal] = useState(1);
  const [inventoryList, setInventoryList] = useState("");
  // const [attribuit, setAttribuit] = useState([]);
  const [deleteId, setDeleteId] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [assignModal, setAssignModal] = useState(false);
  const [assignId, setAssignId] = useState(false);
  const [category, setCategory] = useState([]);
  const [categorySearch, setCategorySearch] = useState("");
  const [workStatusSearch, setWorkStatusSearch] = useState("");
  const [employeName, setEmployeName] = useState("");
  const [downloadExcelModal, setDownloadExcelModal] = useState(false);
  const [mobileId, setMobileId] = useState("");

  // console.log("inventoryList",inventoryList)

  const deleteHandler = (_id) => {
    setDeleteId(_id);
    setDeleteModal(true);
  };
  const assignPersonHandler = (_id, assignedTo) => {
    setAssignId(_id);
    // setEmployeName(assignedTo);
    setAssignModal(true);
  };

  const refreshHandler = () => {
    setSearchTerm("");
    setWorkStatusSearch("");
    setCategorySearch(mobileId);
    fetchInventory();
  };

  //----------------Inventory-API--------------------------
  const fetchInventory = async () => {
    try {
      show();
      const token = localStorage.getItem("demo-token");
      const response = await axios.get(
        `${process.env.REACT_APP_SECRET_KEY}/inventory-list?search=${searchValue}&page=${activePage}&categoryId=${categorySearch}&workingStatus=${workStatusSearch}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.status === 200) {
        hide();
        const value = response.data.data.listing.listing;
        setInventoryList(value);
        // setAttribuit(extractedValues)
        setTotal(response.data.data.listing.total);
      } else {
        hide();
        toast.error(response.data.message);
      }
    } catch (err) {
      hide();
      console.log("form", err);
    }
  };

  //----------------category-search-API--------------------------
  const fetchCategory = async () => {
    try {
      const token = localStorage.getItem("demo-token");
      const response = await axios.get(
        `${process.env.REACT_APP_SECRET_KEY}/master-data-category`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.status === 200) {
        setCategory(response.data.data.categorys);
      }
    } catch (err) {
      console.log("form", err);
    }
  };

  useEffect(() => {
    // Find the category with name "mobile" and get its ID
    const mobileCategory = category.find(
      (category) => category.categoryName === "Mobile"
    );
    if (mobileCategory) {
      setCategorySearch(mobileCategory._id);
      setMobileId(mobileCategory._id);
    }
  }, [category]);

  useEffect(() => {
    fetchCategory();
  }, []);

  useEffect(() => {
    if (searchValue) {
      setActivePage(1)
    } else {
      setActivePage(activePage)
    }
    fetchInventory();
  }, [searchValue, activePage, workStatusSearch, categorySearch]);

  return (
    <>
      {loding}
      <div className="WrapperArea wrapper_area_cstm_employee">
        <div className="WrapperBox">
          <div className="Small-Wrapper ">
            <div className="wrapper_employee_flex d-flex">
              <h4 className="Title">Inventory Management</h4>
              <div className="Small-Wrapper cstm_wraper_btn borderNone">
                <div className="cstmSearchBox">
                  <Link to="/inventory-management/add-inventory">
                    <i className="fa fa-plus mr-2" aria-hidden="true"></i>
                    Add Inventory
                  </Link>
                </div>

                <div className="cstmSearchBox">
                  <Link
                    to="#"
                    onClick={() => setDownloadExcelModal(true)}
                  // href={`${process.env.REACT_APP_SECRET_KEY}/download-inventory-data`}
                  >
                    <i className="fa fa-download mr-2" aria-hidden="true"></i>
                    Download Excel
                  </Link>
                </div>
              </div>
            </div>
            <div className="Small-Wrapper cstm-small_wrapper_employee">
              <div className="row">
                <div className="col-lg-3 col-md-3">
                  <form action="">
                    <input
                      className="input_style"
                      type="text"
                      placeholder="Search by device name or Id or Person Name..."
                      onChange={(e) => setSearchTerm(e.target.value)}
                      value={searchTerm}
                    />
                  </form>
                </div>
                <div className="col-lg-2 col-md-2">
                  <select
                    className="input_style"
                    onChange={(e) => setCategorySearch(e.target.value)}
                    value={categorySearch}
                  >
                    <option value="">All Cagetory</option>
                    {category &&
                      category.map(({ categoryName, _id }, idindex) => {
                        return (
                          <React.Fragment key={idindex}>
                            <option value={_id}>{categoryName}</option>
                          </React.Fragment>
                        );
                      })}
                  </select>
                </div>
                <div className="col-lg-2 col-md-2">
                  <select
                    className="input_style"
                    onChange={(e) => setWorkStatusSearch(e.target.value)}
                    value={workStatusSearch}
                  >
                    <option value="">All Work Status</option>
                    <option value="Working"> Working </option>
                    <option value="Not Working"> Not Working </option>
                    <option value="Physical Damage">Physical Damage</option>
                  </select>
                </div>
                <div className="col-lg-1 col-md-1">
                  <IoMdRefresh
                    className="fs-4 input_style_refresh employeeRefress"
                    role="button"
                    onClick={refreshHandler}
                  />
                </div>
              </div>
            </div>
            <div className="TableList">
              <table>
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Device Name</th>
                    <th>Device Id</th>
                    <th>Allocated Person name</th>
                    <th>Working Status</th>
                    <th>Purchase Date</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {inventoryList && inventoryList.length > 0 ? (
                    inventoryList.map(
                      (
                        {
                          _id,
                          category,
                          createdAt,
                          status,
                          assignedTo,
                          deviceId,
                          purchaseDate,
                          workingStatus,
                        },
                        index
                      ) => {
                        return (
                          <React.Fragment key={index}>
                            <tr>
                              <td>{(activePage - 1) * 50 + ++index}</td>
                              <td>
                                {category?.categoryName
                                  ? category.categoryName
                                  : `---`}
                              </td>
                              <td>{deviceId ? deviceId : "---"}</td>
                              <td>
                                {assignedTo?.name ? (
                                  <span className="Cstm_definecolor">
                                    {assignedTo.name}
                                  </span>
                                ) : (
                                  <span className="allocateColor">
                                    Un allocated
                                  </span>
                                )}
                              </td>

                              {/* <td>{workingStatus || "---"}</td> */}
                              <td>
                                {workingStatus === "Not Working" ? (
                                  <span className="allocateColorRed">
                                    Not Working
                                  </span>
                                ) : (
                                  <span className="Cstm_definecolor">
                                    {workingStatus || "----"}
                                  </span>
                                )}
                              </td>
                              <td>
                                {purchaseDate
                                  ? moment(purchaseDate).format("DD-MM-YYYY")
                                  : `---`}
                              </td>
                              <td>
                                <div className="dropdown wrap_cstm_dropdown_menu">
                                  <button
                                    className="btn btn-secondary cstm_btn_sceon_background"
                                    type="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <i className="fa fa-ellipsis-v"></i>
                                  </button>
                                  <ul className="dropdown-menu cstm_dropdown_edit">
                                    <li>
                                      <Link
                                        className="dropdown-item"
                                        to="#"
                                        onClick={() =>
                                          assignPersonHandler(_id, assignedTo)
                                        }
                                      >
                                        <i className="fa fa-plus mx-3"></i>
                                        Assign Person
                                      </Link>
                                    </li>
                                    <li>
                                      <Link
                                        className="dropdown-item"
                                        to={`/inventory-management/update-inventory/${_id}`}
                                      >
                                        <i className="fa fa-pencil-square-o mx-3"></i>
                                        Edit
                                      </Link>
                                    </li>

                                    <li>
                                      <Link
                                        className="dropdown-item"
                                        to={`/inventory-management/view-inventory/${_id}`}
                                      >
                                        <i className="fa fa-eye mx-3"></i>View
                                      </Link>
                                    </li>
                                    <li>
                                      <Link
                                        className="dropdown-item"
                                        onClick={() => deleteHandler(_id)}
                                        to="#"
                                      >
                                        <i
                                          className="fa fa-trash-o mx-3"
                                          aria-hidden="true"
                                        ></i>
                                        Delete
                                      </Link>
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                          </React.Fragment>
                        );
                      }
                    )
                  ) : (
                    <tr>
                      <td colSpan={8}>Not Found</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            {total >= 50 ? (
              <nav
                aria-label="Page navigation example"
                className="mt-3 cstm_pagination"
              >
                <Pagination
                  previousLabel={"prev"}
                  nextLabel={"next"}
                  // breakLabel={'...'}
                  pageCount={activePage}
                  pageRangeDisplayed={5}
                  itemsCountPerPage={50}
                  totalItemsCount={total}
                  itemClass={"page-item"}
                  linkClass={"page-link"}
                  activeLinkClass={"active"}
                  activePage={activePage}
                  onChange={(page) => setActivePage(page)}
                />
              </nav>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <DeleteInventory
        deleteId={deleteId}
        deleteModal={deleteModal}
        modalClose={() => setDeleteModal(false)}
        listing={fetchInventory}
      />

      <AssignPerson
        assignId={assignId}
        assignModal={assignModal}
        modalClose={() => setAssignModal(false)}
        listing={fetchInventory}
        employeName={employeName}
      />
      <DownloadExcel
        downloadExcelModal={downloadExcelModal}
        modalClose={() => setDownloadExcelModal(false)}
        category={category}
        listing={fetchInventory}
      />
    </>
  );
}

export default InventoryManagement;

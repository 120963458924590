import React, { useState } from "react";
import useButtonLoader from "../../Hooks/UseButtonLoader";
import axios from "axios";
import { toast } from "react-toastify";

function ChangePassword() {
  const [inputData, setInputData] = useState({
    newPassword: "",
    oldPassword: "",
    confirmPassword: "",
  });
  const [buttonref, loding] = useButtonLoader("Update");
  const [error, setError] = useState("");

  let validation = () => {
    let errorMessage = {};
    let status = true;

    if (!inputData.oldPassword) {
      errorMessage["oldPassword"] = "Please Enter oldPassword";
      status = false;
    }

    if (!inputData.newPassword) {
      errorMessage["newPassword"] = "Please Enter newPassword";
      status = false;
    }

    if (!inputData.confirmPassword) {
      errorMessage["confirmPassword"] = "Please Enter confirmPassword";
      status = false;
    } else if (inputData.confirmPassword !== inputData.newPassword) {
      errorMessage["confirmPassword"] = "Mismatched new password";
      status = false;
    }
    setError(errorMessage);
    return status;
  };

  const fetchChangePasword = async (e) => {
    e.preventDefault();
    let status = validation();
    if (status) {
      try {
        loding(true);
        const token = localStorage.getItem("demo-token");
        const response = await axios.post(
          `${process.env.REACT_APP_SECRET_KEY}/changePassword`,
          {
            oldPassword: inputData.oldPassword,
            newPassword: inputData.newPassword,
            confirmPassword: inputData.confirmPassword,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.data.status === 200) {
          loding(false);
          toast.success(response.data.message);
          setInputData({
            newPassword: "",
            oldPassword: "",
            confirmPassword: "",
          });
        } else {
          loding(false);
          toast.error(response.data.message);
        }
      } catch (error) {
        loding(false);
        console.log(error);
      }
    }
  };

  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    setInputData({ ...inputData, [name]: value });
  };

  return (
    <>
      <div className="WrapperArea">
        <div className="WrapperBox">
          <div className="Small-Wrapper cstm_change_password">
            <h4 className="Title">Change Password</h4>
            <div className="container mt-4">
              <form className="needs-validation">
                <div className="row">
                  <div className="col-12">
                    <div>
                      <label htmlFor="oldPassword" className="col-form-label">
                        Enter old password
                      </label>
                      <br />
                      <input
                        type="text"
                        className={`form-control ${
                          !inputData.oldPassword &&
                          error.oldPassword &&
                          "is-invalid"
                        }`}
                        placeholder="XXXXXXXXXXXXXX"
                        name="oldPassword"
                        id="oldPassword"
                        onChange={onChangeHandler}
                        value={inputData.oldPassword}
                        maxLength={14}
                      />
                      <div className="invalid-feedback">
                        {error.oldPassword}
                      </div>
                    </div>

                    <div>
                      <label htmlFor="newPassword" className="col-form-label">
                        New password
                      </label>
                      <br />
                      <input
                        type="text"
                        className={`form-control ${
                          !inputData.newPassword &&
                          error.newPassword &&
                          "is-invalid"
                        }`}
                        placeholder="XXXXXXXXXXXXXX"
                        name="newPassword"
                        id="newPassword"
                        onChange={onChangeHandler}
                        value={inputData.newPassword}
                        maxLength={14}
                      />
                      <div className="invalid-feedback">
                        {error.newPassword}
                      </div>
                    </div>

                    <div>
                      <label
                        htmlFor="confirmPassword"
                        className="col-form-label"
                      >
                        confirmPassword
                      </label>
                      <br />
                      <input
                        type="password"
                        autoComplete="on"
                        className={`form-control ${
                          !inputData.confirmPassword
                            ? error.confirmPassword && "is-invalid"
                            : error.confirmPassword && "is-invalid"
                        }`}
                        placeholder="XXXXXXXXXXXXXX"
                        name="confirmPassword"
                        id="confirmPassword"
                        onChange={onChangeHandler}
                        value={inputData.confirmPassword}
                        maxLength={14}
                      />
                      <div className="invalid-feedback">
                        {error.confirmPassword}
                      </div>
                    </div>
                    <div className="text-center">
                      <button
                        className="butonColor mt-3"
                        type="submit"
                        onClick={fetchChangePasword}
                        ref={buttonref}
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ChangePassword;

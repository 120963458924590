import React, { useState } from 'react'
import ModalBox from '../../../Modals/ModalBox';
import { toast } from 'react-toastify';
import axios from 'axios';
import useButtonLoader from '../../../Hooks/UseButtonLoader';

function UpdateTechnology({updateModal,updateId,technologyName,setTechnologyName,modalClose,listing}) {
  const [buttonRef, loding] = useButtonLoader("Update");
  const [error, setError] = useState("");

  const validation = () => {
    let errorMessage = {
      name: "",
    };
    let status = true;

    if (!technologyName) {
      errorMessage["name"] = "Please Enter technology name ";
      status = false;
    }
    setError(errorMessage);
    return status;
  };

  const UpdateTechnologyApi = async () => {
    const status = validation();
    if (status) {
      try {
        loding(true);
        const token = localStorage.getItem("demo-token");
        const response = await axios.put(
          `${process.env.REACT_APP_SECRET_KEY}/technology-update/${updateId}`,
          { technologyName: technologyName },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        // console.log("updateDepartment", response);
        if (response.data.status === 200) {
          loding(false);
          modalClose();
          listing();
          toast.success(response.data.message);
        } else {
          loding(false);
          toast.error(response.data.message);
        }
      } catch (error) {
        loding(false);
        console.log(error);
      }
    }
  };

  return (
    <div>
      <ModalBox
        show={updateModal}
        onClose={modalClose}
        heading={"Update Technology"}
        customizedFooter={
          <div>
            <button className="btn btn-secondary modalButtonClose mx-2" onClick={modalClose}>
              close
            </button>
            <button
              className="modalButtonYes btn btn-primary modalButtonSubmit"
              onClick={UpdateTechnologyApi}
              ref={buttonRef}
            >
              Update
            </button>
          </div>
        }
      >
        <div className="row">
          <label htmlFor="inputName" className="fs-6 my-2">
            Update technology Name
          </label>
          <div className="rounded fs-6">
            <input
              type="text"
              className={`form-control ${
                !technologyName && error.name && "is-invalid"
              }`}
              name="departmentName"
              id="inputName"
              placeholder="Please enter technology Name"
              value={technologyName}
              onChange={(e) => setTechnologyName(e.target.value)}
              maxLength={18}
            />
            <div className="invalid-feedback">{error.name}</div>
          </div>
        </div>
      </ModalBox>
    </div>
  );
}

export default UpdateTechnology

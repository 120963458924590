import moment from "moment";
import React from "react";

function MilestoneView({ milestones ,projectDeatils}) {
  let projectType = projectDeatils.projectType

  return (
    <>
      <div className="">
        <div className="mt-5">
          <h4 className="Title">Milestone</h4>
          <div className="TableList">
            {projectType==="Fixed Cost"?(<>
            <table>
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Type</th>
                  <th>Phase</th>
                  <th>Name</th>
                  <th>Start date</th>
                  <th>End date</th>
                  <th>Testing date</th>
                  <th>Delivery date</th>
                  <th>Sign off date</th>
                  <th>Proposed date</th>
                  <th>Delay status</th>
                </tr>
              </thead>
              <tbody>
                {milestones.length > 0 ? (
                  milestones.map(({
                        _id,
                        delayInDays,
                        startDate,
                        endDate,
                        testingDate,
                        deliveryDate,
                        isDelayed,
                        milestoneName,
                        milestonePhase,
                        milestoneType,
                        projectId,
                        updatedAt,
                        createdAt,
                        signOff,
                        proposedDate
                      },index) => {
                      // const textColor = delayInDays < 0 ? "#ea2d2d" : "green";
                      return (
                        <React.Fragment key={_id}>
                          <tr>
                            <td>{++index}</td>
                            <td>{milestoneType}</td>
                            <td>{milestonePhase}</td>
                            <td>{milestoneName}</td>
                            <td>{moment(startDate).format("DD-MM-YYYY")}</td>
                            <td>{moment(endDate).format("DD-MM-YYYY")}</td>
                            <td>{moment(testingDate).format("DD-MM-YYYY")}</td>
                            <td>{moment(deliveryDate).format("DD-MM-YYYY")}</td>
                            <td>{signOff?moment(signOff).format("DD-MM-YYYY"):`---`}</td>
                            <td>{proposedDate?moment(proposedDate).format("DD-MM-YYYY"):`---`}</td>
                            <td>{delayInDays > 0 ? <span className="" style={{  backgroundColor: "#de2727" }}>{`${delayInDays} days`}</span> : <span className="" style={{ backgroundColor: "#43a047"}}>On-Time</span>}</td>
                          </tr>
                        </React.Fragment>
                      );
                    }
                  )
                ) : (
                  <tr>
                    <td colSpan={8}>Not Found</td>
                  </tr>
                )}
              </tbody>
            </table>
            </>):(<>
              <table>
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Start date</th>
                  <th>End date</th>
                  <th>Testing date</th>
                  <th>Delivery date</th>
                  <th>Sign off date</th>
                  <th>Proposed date</th>
                  <th>Delay status</th>
                </tr>
              </thead>
              <tbody>
                {milestones.length > 0 ? (
                  milestones.map(({
                        _id,
                        delayInDays,
                        startDate,
                        endDate,
                        testingDate,
                        deliveryDate,
                        signOff,
                        proposedDate
                      },index) => {
                      return (
                        <React.Fragment key={_id}>
                          <tr>
                            <td>{++index}</td>
                            <td>{moment(startDate).format("DD-MM-YYYY")}</td>
                            <td>{moment(endDate).format("DD-MM-YYYY")}</td>
                            <td>{moment(testingDate).format("DD-MM-YYYY")}</td>
                            <td>{moment(deliveryDate).format("DD-MM-YYYY")}</td>
                            <td>{signOff?moment(signOff).format("DD-MM-YYYY"):`---`}</td>
                            <td>{proposedDate?moment(proposedDate).format("DD-MM-YYYY"):`---`}</td>
                            <td>{delayInDays > 0 ? <span className="" style={{ backgroundColor: "#de2727" }}>{`${delayInDays} days`}</span> : <span className="" style={{ backgroundColor: "#43a047" }}>On-Time</span>}</td>
                          </tr>
                        </React.Fragment>
                      );
                    }
                  )
                ) : (
                  <tr>
                    <td colSpan={8}>Not Found</td>
                  </tr>
                )}
              </tbody>
            </table>
            </>)}
          </div>
        </div>
      </div>
    </>
  );
}

export default MilestoneView;

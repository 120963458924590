import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DeleteEmploye from "./DeleteEmploye";
import axios from "axios";
import usePageLoader from "../../Hooks/UsePageLoader";
import useSearchBox from "../../Hooks/UseSearchBox";
import Pagination from "react-js-pagination";
import { IoMdRefresh } from "react-icons/io";
import moment from "moment";
import ModalBox from "../../Modals/ModalBox";
import useButtonLoader from "../../Hooks/UseButtonLoader";
import { toast } from "react-toastify";
import CheckBox from "../../Modals/CheckBox";
import SendEmail from "./SendEmail";
import ChangeStatusSendEmail from "./ChangeStatusSendEmail";
import ChangeStatusEmployee from "./ChangeStatusEmployee";
import VerifyEmployee from "./VerifyEmployee";

function EmpolyeeManagement() {
  const [deleteId, setDeleteId] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [employee, setEmployee] = useState("");
  const [loding, show, hide] = usePageLoader();
  const [searchTerm, setSearchTerm] = useState("");
  const searchValue = useSearchBox(searchTerm, 650);
  const [department, setDepartment] = useState([]);
  const [departmentSearch, setDepartmentSearch] = useState("");
  const [designation, setDesignation] = useState([]);
  const [designationSearch, setDesignationSearch] = useState("");
  const [activePage, setActivePage] = useState(1);
  const [total, setTotal] = useState(1);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [buttonRef, loder] = useButtonLoader("Upload");
  const [importModal, setImportModal] = useState(false);
  const [file, setFile] = useState("");
  const [error, setError] = useState({});
  const [sendEmailId, setSendEmailId] = useState("");
  const [emailStatus, setEmailStatus] = useState("");
  const [emailModal, setEmailModal] = useState(false);
  const [empStatusId, setEmpStatusId] = useState("");
  const [empStatus, setEmpStatus] = useState("");
  const [empStatusModal, setEmpStatusModal] = useState(false);
  const [employeeName, setEmployeeName] = useState("");
  const [verifyId, setVerifyId] = useState("");
  const [verifyStatus, setVerifyStatus] = useState("");
  const [verifyEmp, setVerifyEmp] = useState("");
  const [verifyModal, setVerifiyModal] = useState(false);
  const [statusModal, setStatusModal] = useState(false);
  const ALLOWED_EXTENSIONS = /\.(xls[xm]?|csv)$/i;
  const [sortByNameAsc, setSortByNameAsc] = useState(true);
  const [sortByEmpIdAsc, setSortByEmpIdAsc] = useState(true);
  const [sortByEmailAsc, setSortByEmailAsc] = useState(true);
  const [sortByMobileNoAsc, setSortByMobileNoAsc] = useState(true);
  const [activeEmpSearch, setActiveEmpSearch] = useState("true");
  const [verifySearch, setVerifySearch] = useState("");
  const [type, setType] = useState("");
  const [serchType, setSearchType] = useState("");


  const validation = () => {
    let errorMessage = {};
    let status = true;
    if (!file[0]) {
      errorMessage["excelFile"] = "This fiel is required";
      status = false;
    } else if (!ALLOWED_EXTENSIONS.test(file[0].name)) {
      errorMessage["excelFile"] = "Invalid file format";
      status = false;
    }
    setError(errorMessage);
    return status;
  };

  //-------send-email-onchange------------
  // const onChangeCheckbox = (_id, sendEmailStatus, name) => {
  //   setSendEmailId(_id);
  //   setEmailStatus(sendEmailStatus);
  //   setEmployeeName(name);
  //   setEmailModal(true);
  // };
  //-------send-email-status-toggle-change-----------
  // function statusHandler(_id, sendEmailStatus) {
  //   setSendEmailId(_id);
  //   setEmailStatus(sendEmailStatus);
  //   setStatusModal(true);
  // }
  //--------status-change-employee----------
  function changeStatusEmp(_id, status) {
    setEmpStatusId(_id);
    setEmpStatus(status);
    setEmpStatusModal(true);
  }
  //-------verify-emp-status-change-employee----------
  function verifyHandler(_id, verifyStatus, employeeName) {
    setVerifyId(_id);
    setVerifyStatus(verifyStatus);
    setVerifyEmp(employeeName);
    setVerifiyModal(true);
  }
  //-------Function-to-handle-sorting-by-name-----------
  const sortByNameHandler = () => {
    const newSortOrder = !sortByNameAsc;
    setSortByNameAsc(newSortOrder);
    setType("name");
    setSearchType(sortByNameAsc);
  };
  //-------Render-the-column-header-with-sorting-arrows-------
  const renderNameColumnHeader = () => (
    <th
      className="cstm_th_style"
      onClick={sortByNameHandler}
      style={{ cursor: "pointer" }}
    >
      EMP Name
      {sortByNameAsc ? (
        // <i className="fa fa-arrow-down" aria-hidden="true"></i>
        <i className="fa fa-angle-down cstm-arrow-drop" aria-hidden="true"></i> //ASC
      ) : (
        <i className="fa fa-angle-up cstm-arrow-drop" aria-hidden="true"></i> //DESC
      )}
    </th>
  );
  //-------Function-to-handle-sorting-by-Emp-Id-----------
  const sortByEmpIdHandler = () => {
    const newSortOrder = !sortByEmpIdAsc;
    setSortByEmpIdAsc(newSortOrder);
    setType("employeeId");
    setSearchType(sortByEmpIdAsc); /*  */
  };
  //-------Render-the-column-header-with-sorting-arrows------------
  const renderEmpIdColumnHeader = () => (
    <th
      className="cstm_th_style cstm_width_style"
      onClick={sortByEmpIdHandler}
      style={{ cursor: "pointer" }}
    >
      EMP Id
      {sortByEmpIdAsc ? (
        <i className="fa fa-angle-down cstm-arrow-drop" aria-hidden="true"></i> //ASC
      ) : (
        <i className="fa fa-angle-up cstm-arrow-drop" aria-hidden="true"></i> //DESC
      )}
    </th>
  );
  //-------Function-to-handle-sorting-by-Emp-Id-----------
  // const sortByEmailHandler = () => {
  //   const newSortOrder = !sortByEmailAsc;
  //   setSortByEmailAsc(newSortOrder);
  //   setType("email")
  //   setSearchType(sortByEmailAsc)
  // };
  // //-------Render-the-column-header-with-sorting-arrows-------
  // const renderEmailColumnHeader = () => (
  //   <th onClick={sortByEmailHandler} style={{ cursor: "pointer" }}>
  //     Email
  //     {sortByEmailAsc ? (
  //       <i className="fa fa-arrow-up" aria-hidden="true"></i> //ASC
  //     ) : (
  //       <i className="fa fa-arrow-down" aria-hidden="true"></i> //DESC
  //     )}
  //   </th>
  // );
  //-------Function-to-handle-sorting-by-Emp-Id-----------
  // const sortByMobileNoHandler = () => {
  //   const newSortOrder = !sortByMobileNoAsc;
  //   setSortByMobileNoAsc(newSortOrder);
  //   setType("phoneNumber")
  //   setSearchType(sortByMobileNoAsc)
  // };
  // //-------Render-the-column-header-with-sorting-arrows-------
  // const renderMobileNoColumnHeader = () => (
  //   <th onClick={sortByMobileNoHandler} style={{ cursor: "pointer" }}>
  //     Mobile
  //     {sortByMobileNoAsc ? (
  //       <i className="fa fa-arrow-down" aria-hidden="true"></i> //ASC
  //     ) : (
  //       <i className="fa fa-arrow-up" aria-hidden="true"></i> //DESC
  //     )}
  //   </th>
  // );
  //---------------fetch-employee-API--------------------------

  const fetchEmployees = async () => {
    try {
      show();
      const token = localStorage.getItem("demo-token");
      let searchStartDate =
        startDate && startDate !== ""
          ? moment(startDate).format("YYYY-MM-DD")
          : "";
      let searchEndDate =
        endDate && endDate !== "" ? moment(endDate).format("YYYY-MM-DD") : "";

      const response = await axios.get(
        `${process.env.REACT_APP_SECRET_KEY
        }/employee-listing?search=${searchValue}&fromDate=${searchStartDate}&toDate=${searchEndDate}&department=${departmentSearch}&designation=${designationSearch}&status=${activeEmpSearch}&verifyStatus=${verifySearch}&page=${activePage}&sort=${type}&sortDirection=${serchType ? "asc" : "DESC"
        }`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log("employeList", response.data.data.employees);
      if (response.data.status === 200) {
        setEmployee(response.data.data.employees);
        setTotal(response.data.data.totalemployees);
        hide();
      } else {
        hide();
      }
    } catch (err) {
      console.log("form", err);
    }
  };

  //----------------department-API--------------------------
  const fetchDepartment = async () => {
    try {
      const token = localStorage.getItem("demo-token");
      const response = await axios.get(
        `${process.env.REACT_APP_SECRET_KEY}/department-list`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.status === 200) {
        setDepartment(response.data.data.departments);
      }
    } catch (err) {
      console.log("form", err);
    }
  };
  //----------------designation-API--------------------------
  const fetchdesignation = async () => {
    try {
      const token = localStorage.getItem("demo-token");
      const response = await axios.get(
        `${process.env.REACT_APP_SECRET_KEY}/designation-list`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.status === 200) {
        setDesignation(response.data.data.designations);
      }
    } catch (err) {
      console.log("form", err);
    }
  };
  //---------------import-excel-file-API-----------------
  const importExcel = async () => {
    const status = validation();
    if (status) {
      try {
        loder(true);
        const token = localStorage.getItem("demo-token");
        const formdata = new FormData();
        formdata.append("file", file[0]);
        const response = await axios.post(
          `${process.env.REACT_APP_SECRET_KEY}/upload`,
          formdata,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.data.status === 200) {
          loder(false);
          setImportModal(false);
          fetchEmployees();
          toast.success(response.data.message);
        } else {
          loder(false);
          setImportModal(false);
          toast.error(response.data.message);
        }
      } catch (error) {
        loder(false);
        console.log(error);
      }
    }
  };

  const deleteHandler = (_id) => {
    setDeleteId(_id);
    setDeleteModal(true);
  };
  const searchHandler = (event) => {
    setSearchTerm(event.target.value);
  };
  const handleStartDate = (date) => {
    setStartDate(date);
  };

  const handleEndDate = (date) => {
    setEndDate(date);
  };

  const refreshHandler = () => {
    setDepartmentSearch("");
    setDesignationSearch("");
    setSearchTerm("");
    setStartDate("");
    setEndDate("");
    setActiveEmpSearch("");
    setVerifySearch("");
    fetchEmployees();
  };

  useEffect(() => {
    fetchEmployees();
    fetchDepartment();
    fetchdesignation();
  }, [departmentSearch, designationSearch]);

  useEffect(() => {
    if (searchValue) {
      setActivePage(1)
    } else {
      setActivePage(activePage)
    }
    fetchEmployees();
  }, [
    searchValue,
    activePage,
    startDate,
    endDate,
    verifySearch,
    sortByNameAsc,
    sortByEmpIdAsc,
    sortByEmailAsc,
    sortByMobileNoAsc,
  ]);

  // useEffect(() => {
  //   if (startDate && endDate) {
  //     fetchEmployees();
  //   }

  useEffect(() => {
    fetchEmployees()
  }, [activeEmpSearch])
  return (
    <>
      {loding}
      <div className="WrapperArea wrapper_area_cstm_employee">
        <div className="WrapperBox">
          <div className="Small-Wrapper ">
            <div className="wrapper_employee_flex d-flex">
              <h4 className="Title ">Employee Management</h4>
              <div className="Small-Wrapper cstm_wraper_btn borderNone">
                <div className="cstmSearchBox">
                  <Link
                    to="/employee-Management/form"
                    className="butonColor addButtonPM"
                  >
                    <i className="fa fa-plus mr-2" aria-hidden="true"></i>
                    Add Employee
                  </Link>
                </div>
                <div className="cstmSearchBox">
                  <a
                    className="butonColor addButtonPM downloadExcel"
                    href={`${process.env.REACT_APP_SECRET_KEY}/download`}
                  >
                    <i className="fa fa-download mr-2" aria-hidden="true"></i>
                    Download Excel
                  </a>
                </div>
                <div className="cstmSearchBox">
                  <Link
                    to="#"
                    onClick={() => setImportModal(true)}
                    className="butonColor addButtonPM"
                  >
                    <i className="fa fa-upload mr-2" aria-hidden="true"></i>
                    Import Excel
                  </Link>
                </div>
              </div>
            </div>

            <div className="Small-Wrapper cstm-small_wrapper_employee">
              <div className="row">
                <div className="col-lg-3 col-md-3">
                  <form action="">
                    <input
                      className="input_style cstm_input_font_place"
                      type="text"
                      placeholder="Search by employee name or Id... "
                      onChange={searchHandler}
                      value={searchTerm}
                    />
                  </form>
                </div>
                <div className="col-lg-1 col-md-1">
                  <DatePicker
                    placeholderText="Start Date"
                    className="input_style dataPickerWidth fontSize"
                    selected={startDate}
                    value={startDate}
                    id="cstmstartdate"
                    showYearDropdown
                    yearDropdownItemNumber={100}
                    scrollableYearDropdown
                    showMonthDropdown
                    useShortMonthInDropdown
                    onChange={handleStartDate}
                    dateFormat="yyyy-MM-dd"
                    maxDate={endDate}
                  />
                </div>
                <div className="col-lg-1 col-md-1">
                  <div className="form-field date-picker ">
                    <DatePicker
                      placeholderText="End Date"
                      className="input_style dataPickerWidth fontSize"
                      selected={endDate}
                      value={endDate}
                      showYearDropdown
                      yearDropdownItemNumber={100}
                      scrollableYearDropdown
                      showMonthDropdown
                      useShortMonthInDropdown
                      onChange={handleEndDate}
                      dateFormat="yyyy-MM-dd"
                      minDate={startDate}
                    />
                  </div>
                </div>
                {/* <div className="col-lg-1 col-md-1">
                  <select
                    className="input_style fontSize"
                    onChange={(e) => setVerifySearch(e.target.value)}
                    value={verifySearch}
                  >
                    <option value="">Verify Emp</option>
                    <option value={true}> Yes </option>
                    <option value={false}> No </option>
                  </select>
                </div> */}
                <div className="col-lg-2 col-md-2">
                  <select
                    className="input_style"
                    onChange={(e) => setActiveEmpSearch(e.target.value)}
                    value={activeEmpSearch}
                  >
                    <option value={true}>Active</option>
                    <option value="relievingDate">Relieving</option>
                    <option value="">All</option>
                  </select>
                </div>
                <div className="col-lg-2 col-md-2">
                  <select
                    className="input_style"
                    onChange={(e) => setDepartmentSearch(e.target.value)}
                    value={departmentSearch}
                  >
                    <option value="">All Department</option>
                    {department &&
                      department.map(({ departmentName, _id }, idindex) => {
                        return (
                          <React.Fragment key={idindex}>
                            <option value={_id}>{departmentName}</option>
                          </React.Fragment>
                        );
                      })}
                  </select>
                </div>
                <div className="col-lg-2 col-md-2">
                  <select
                    className="input_style"
                    onChange={(e) => setDesignationSearch(e.target.value)}
                    value={designationSearch}
                  >
                    <option value=""> All Designation</option>
                    {designation &&
                      designation.map(({ designationName, _id }, idindex) => {
                        return (
                          <React.Fragment key={idindex}>
                            <option value={_id}>{designationName}</option>
                          </React.Fragment>
                        );
                      })}
                  </select>
                </div>
                <div className="col-lg-1 col-md-1">
                  <IoMdRefresh
                    className="fs-4 input_style_refresh employeeRefress"
                    role="button"
                    onClick={refreshHandler}
                  />
                </div>
              </div>
            </div>

            <div className="TableList">
              <table>
                <thead>
                  <tr>
                    <th>S.No</th>
                    {renderNameColumnHeader()}
                    {renderEmpIdColumnHeader()}
                    <th>Email Id</th>
                    {/* <th>Verified By</th> */}
                    <th>VerifyEmp</th>
                    <th>Status</th>
                    {/* <th>Mobile</th> */}
                    <th>Department</th>
                    <th>Degination</th>
                    {/* <th>EmailStatus</th>
                    <th>Email</th> */}
                    <th>action</th>
                  </tr>
                </thead>
                <tbody>
                  {employee.length > 0 ? (
                    employee.map(
                      (
                        {
                          _id,
                          email,
                          employeeId,
                          name,
                          department,
                          designation,
                          status,
                          sendEmailStatus,
                          verifyStatus,
                          verifyBy,
                        },
                        index
                      ) => {
                        return (
                          <React.Fragment key={_id}>
                            <tr>
                              <td>{(activePage - 1) * 50 + ++index}</td>
                              <td>{name || "---"}</td>
                              <td>{employeeId || "---"}</td>
                              <td>{email || "---"}</td>
                              {/* <td>{verifyBy || "---"}</td> */}
                              {verifyStatus === true ? (
                                <td>
                                  <span className="verifyYes"></span>
                                </td>
                              ) : (
                                <td>
                                  <span className="verifyNo"></span>
                                </td>
                              )}
                              <td>
                                <CheckBox
                                  status={status}
                                  onChange={() => changeStatusEmp(_id, status)}
                                />
                              </td>
                              {/* <td>{phoneNumber || "---"}</td> */}
                              <td>
                                {department ? department.departmentName : "---"}
                              </td>
                              <td>
                                {designation
                                  ? designation?.designationName
                                  : "---"}
                              </td>
                              {/* <td>
                                <CheckBox
                                  status={sendEmailStatus}
                                  onChange={() =>
                                    statusHandler(_id, sendEmailStatus)
                                  }
                                />
                              </td>
                              {sendEmailStatus === false ? (
                                <td>
                                  <button
                                    className="sendEmail"
                                    onClick={() =>
                                      onChangeCheckbox(
                                        _id,
                                        sendEmailStatus,
                                        name
                                      )
                                    }
                                  >
                                    send
                                  </button>
                                </td>
                              ) : (
                                <td>
                                  <button className="sentEmail">sent</button>
                                </td>
                              )} */}
                              <td>
                                <div className="dropdown wrap_cstm_dropdown_menu">
                                  <button
                                    className="btn btn-secondary cstm_btn_sceon_background"
                                    type="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <i className="fa fa-ellipsis-v"></i>
                                  </button>
                                  <ul className="dropdown-menu cstm_dropdown_edit">
                                    <li>
                                      <Link
                                        className="dropdown-item"
                                        to={`/employee-Management/update-form/${_id}`}
                                      >
                                        <i className="fa fa-pencil-square-o mx-3"></i>
                                        Edit
                                      </Link>
                                    </li>
                                    <li>
                                      <Link
                                        className="dropdown-item"
                                        to={`/employee-Management/view-form/${_id}`}
                                      >
                                        <i className="fa fa-eye mx-3"></i>View
                                      </Link>
                                    </li>
                                    <li>
                                      <Link
                                        className="dropdown-item"
                                        onClick={() =>
                                          verifyHandler(_id, verifyStatus, name)
                                        }
                                        to="#"
                                      >
                                        <i
                                          className="fa fa-check-square-o mx-3"
                                          aria-hidden="true"
                                        ></i>
                                        Verify
                                      </Link>
                                    </li>
                                    <li>
                                      <Link
                                        className="dropdown-item"
                                        onClick={() => deleteHandler(_id)}
                                        to="#"
                                      >
                                        <i
                                          className="fa fa-trash-o mx-3"
                                          aria-hidden="true"
                                        ></i>
                                        Delete
                                      </Link>
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                          </React.Fragment>
                        );
                      }
                    )
                  ) : (
                    <tr>
                      <td colSpan={11}>Not Found</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            {total >= 50 ? (
              <nav
                aria-label="Page navigation example"
                className="mt-3 cstm_pagination"
              >
                <Pagination
                  previousLabel={"prev"}
                  nextLabel={"next"}
                  // breakLabel={'...'}
                  pageCount={activePage}
                  pageRangeDisplayed={5}
                  itemsCountPerPage={50}
                  totalItemsCount={total}
                  itemClass={"page-item"}
                  linkClass={"page-link"}
                  activeLinkClass={"active"}
                  activePage={activePage}
                  onChange={(page) => setActivePage(page)}
                />
              </nav>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>

      <DeleteEmploye
        deleteId={deleteId}
        deleteModal={deleteModal}
        modalClose={() => {
          setDeleteModal(false);
        }}
        listing={fetchEmployees}
      />
      <ChangeStatusSendEmail
        emailId={sendEmailId}
        sendEmailStatus={emailStatus}
        emailModal={statusModal}
        modalClose={() => setStatusModal(false)}
        listing={fetchEmployees}
      />

      <SendEmail
        emailId={sendEmailId}
        sendEmailStatus={emailStatus}
        emailModal={emailModal}
        modalClose={() => setEmailModal(false)}
        employeeName={employeeName}
        listing={fetchEmployees}
      />

      <ChangeStatusEmployee
        empStatusId={empStatusId}
        empStatus={empStatus}
        empStatusModal={empStatusModal}
        modalClose={() => setEmpStatusModal(false)}
        listing={fetchEmployees}
      />

      <VerifyEmployee
        verifyId={verifyId}
        verifyStatus={verifyStatus}
        employeeName={verifyEmp}
        verifyModal={verifyModal}
        modalClose={() => setVerifiyModal(false)}
        listing={fetchEmployees}
      />

      {/* import Excel modal */}
      <ModalBox
        show={importModal}
        onClose={() => {
          setImportModal(false);
          setFile("");
          setError("");
        }}
        heading={"Import File"}
        customizedFooter={
          <div className="d-flex ">
            <div className="mx-2 ">
              <button
                className="modalButtonClose btn btn-secondary"
                onClick={() => {
                  setImportModal(false);
                  setFile("");
                  setError("");
                }}
              >
                Cancel
              </button>
            </div>
            <div className="mx-2">
              <button
                className="modalButtonSubmit modalButtonYes btn btn-primary"
                ref={buttonRef}
                onClick={importExcel}
              >
                Upload
              </button>
            </div>
          </div>
        }
      >
        <div>
          <label htmlFor="chooseFile">Select File</label>
          <input
            type="file"
            className={`form-control ${!file[0]
              ? error.excelFile && "is-invalid"
              : error.excelFile && "is-invalid"
              }`}
            name="excelFile"
            id="chooseFile"
            accept=".csv, .xls, .xlsx, .xlsm"
            onChange={(e) => setFile(e.target.files)}
          />
          <div className="invalid-feedback fs-6">{error.excelFile}</div>
          <div className="sample_excel">
            <a
              href={`${process.env.REACT_APP_DOWNLOAD_SAMPLE}/profile/sample.xls`}
              // href={`${process.env.REACT_APP_SECRET_KEY}/download-inventory/${selectedCategoryId}`}
              // target="__blank"
              rel="noopener noreferrer"
            >
              Sample file
            </a>
          </div>
        </div>
      </ModalBox>
    </>
  );
}

export default EmpolyeeManagement;
